import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import { Button, Listbox, SquareProgress } from '../../../../../../design-lib';
import React from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath } from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import { useListUsersQuery } from '../../../../../../hooks/queries/user';
import { useListDevicesQuery } from '../../../../../../hooks/queries/device';
import { NextModuleButton } from './NextModuleButton';
import { NavArrowUp } from 'iconoir-react';

export const RingDeviceModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const onSelect = (id: string) => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }

    setAtPath(callflow, `${modifyPath}.data.data.id`, id);
    setCallflow({ ...callflow });
  };

  const { data: devicesResp, isLoading: devicesIsLoading } =
    useListDevicesQuery({
      mask: 'id,doc(name,owner_id)', // mask
      quick: true, // "quick" ie "docOnly"
    });
  // @ts-ignore
  const { devices = [] } = devicesResp?.devices ?? {};

  // console.log('user resp', usersResp);

  // console.log('ring user:', user);

  if (devicesIsLoading)
    return (
      <div className={`w-full h-full grid place-items-center`}>
        <div className={`text-center`}>
          <SquareProgress />
          <span>Loading...</span>
        </div>
      </div>
    );

  const device = devices.find(
    device => device.id === moduleItem?.data?.data?.id,
  );

  return (
    <>
      <Listbox
        label={'Select Device'}
        emptyLabel={'No Devices'}
        // size={'md'}
        onChange={onSelect}
        placeholder="Select a Device"
        className={`w-full mt-1 self-center`}
        // onOpenChanged={setDisableScroll}
        options={devices.map(({ id, doc: { name } }) => ({
          value: id,
          label: name,
        }))}
        value={moduleItem?.data.data.id}
      />

      <MenuButton
        type={'ring'}
        current={moduleItem?.data?.ring_type}
        data={selectedElement.data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          'replace',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <NextModuleButton />
    </>
  );
};
