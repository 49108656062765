import { Callflow } from '../../../../../../types/callflow';
import { Button, IconButton } from '../../../../../design-lib';
import {
  Cancel,
  Clock,
  Community,
  Group,
  NavArrowLeft,
  NavArrowRight,
  Phone,
  PhoneIncome,
  Plus,
  QuestionMark,
  TimerOff,
  User,
  Voice,
} from 'iconoir-react';
import React, { ReactNode, useState } from 'react';
import { getAtPath, useToggleReducer } from '../../../../../utilities';
import { useCallRouteEditor } from './CallflowContext';
import { getElementProps } from './utils/getElementProps';
import { useBuilderContext } from '../../../../../components/IvrBuilder/IvrBuilder';
import { ChooseDialog } from '../../../../../components/IvrBuilder/Flow/nodes/common/ChooseDialog';
import { ModuleButton, SidebarButton } from './ModuleButton';
import { AddModuleButton } from './AddModuleButton';
import {
  Class,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Filter9,
  FilterNone,
} from '@mui/icons-material';
import { ScheduleOptionButton } from './ScheduleOptionButton';
import { SidebarSeparator } from './SidebarSeparator';
import { Divider } from '../../../../../design-lib/mobile-components';
import { MiniMap } from './MiniMap';

export const MenuKeyMap = new Map([
  ['0', <FilterNone fontSize={'small'} />],
  ['1', <Filter1 fontSize={'small'} />],
  ['2', <Filter2 fontSize={'small'} />],
  ['3', <Filter3 fontSize={'small'} />],
  ['4', <Filter4 fontSize={'small'} />],
  ['5', <Filter5 fontSize={'small'} />],
  ['6', <Filter6 fontSize={'small'} />],
  ['7', <Filter7 fontSize={'small'} />],
  ['8', <Filter8 fontSize={'small'} />],
  ['9', <Filter9 fontSize={'small'} />],
]);

export const CallflowSidebar = ({ edgeTypes, nodeTypes, scroll }) => {
  const [expand, toggleExpand] = useToggleReducer();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const routes = (selectedElementKey?.split('.modules') ?? ['.strategy.data'])
    .filter(route => !!route && route.includes('data'))
    .map(route => `${route}.modules`);
  const { callflow, elements } = useCallRouteEditor();

  return (
    <div
      className={`border-r flex flex-col h-full pl-1 -ml-1 pr-2 overflow-hidden`}
    >
      <div
        className={`flex flex-1 flex-col py-3 overflow-y-auto items-center space-y-1.5`}
      >
        {expand ? (
          <Button
            startIcon={<PhoneIncome fr={undefined} />}
            size={'md'}
            onClick={() => setSelectedElementKey(null)}
            variant={!selectedElementKey ? 'fill' : 'outline'}
            color={!selectedElementKey ? 'accent' : 'neutral'}
            className={`w-full`}
          >
            {'Incoming Call'}
          </Button>
        ) : (
          <IconButton
            size={'md'}
            onClick={() => setSelectedElementKey(null)}
            variant={!selectedElementKey ? 'fill' : 'outline'}
            color={!selectedElementKey ? 'accent' : 'neutral'}
          >
            <PhoneIncome fr={undefined} />
          </IconButton>
        )}
        {/*<SidebarSeparator />*/}
        {routes.map((route, index) => {
          const rootPath = routes.slice(0, index).join('');
          const path = `${rootPath}${route}`;
          const modules = getAtPath(callflow, path);

          let targetButton: ReactNode = null;

          if (route.includes('targets')) {
            targetButton = (
              <MenuOptionButton
                expand={expand}
                rootPath={rootPath}
                route={route}
                modules={modules}
              />
            );
          } else if (route.includes('routes')) {
            targetButton = (
              <ScheduleOptionButton
                expand={expand}
                rootPath={rootPath}
                route={route}
                modules={modules}
              />
            );
          }

          return (
            <React.Fragment key={index}>
              {targetButton}
              <ModulesDisplay
                path={path}
                key={index}
                modules={modules}
                expand={expand}
              />
            </React.Fragment>
          );
        })}
        {callflow.strategy.data?.modules.length ? null : (
          <>
            <SidebarSeparator />
            <AddModuleButton
              data={elements.find(elem => elem.type === 'InsertNode')?.data}
              expanded={expand}
            />
          </>
        )}

        {/*<IconButton size={'md'} variant={'fill'} color={'attention'}>*/}
        {/*  <Plus fr={undefined} />*/}
        {/*</IconButton>*/}
        {/*<div className={`flex-1`}></div>*/}
        {/*<IconButton size={'md'} variant={'ghost'} color={'neutral'}>*/}
        {/*  <NavArrowRight fr={undefined} />*/}
        {/*</IconButton>*/}
        {/*  <IconButton size={'md'} variant={'ghost'} color={'neutral'}>*/}
        {/*    <Dialpad fr={undefined} />*/}
        {/*  </IconButton>*/}
        {/*  <IconButtonDropdown*/}
        {/*    size={'md'}*/}
        {/*    menuItems={[1, 2, 3, 4, 5, 6, 7, 8, 9].map(val => ({*/}
        {/*      label: (*/}
        {/*        <>*/}
        {/*          <span className={`text-neutral-60`}>Key press: </span>*/}
        {/*          {`${val}`}*/}
        {/*        </>*/}
        {/*      ),*/}
        {/*      onClick: () => {},*/}
        {/*    }))}*/}
        {/*    variant={'ghost'}*/}
        {/*    color={'neutral'}*/}
        {/*  >*/}
        {/*    <Number1Square fr={undefined} />*/}
        {/*  </IconButtonDropdown>*/}
        {/*  <div*/}
        {/*    className={`flex flex-col p-2 bg-neutral-10 rounded-md border space-y-4`}*/}
        {/*  >*/}
        {/*    <IconButton size={'md'} variant={'fill'} color={'accent'}>*/}
        {/*      <Community fr={undefined} />*/}
        {/*    </IconButton>*/}
        {/*    <IconButton size={'md'} variant={'ghost'} color={'neutral'}>*/}
        {/*      <Voice fr={undefined} />*/}
        {/*    </IconButton>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className={`flex flex-col pb-2 space-y-1.5`}>
        <Divider />
        <MiniMap
          elements={elements}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          scroll={scroll}
          expand={expand}
        />
        {expand ? (
          <Button
            startIcon={<NavArrowLeft fr={undefined} />}
            onClick={toggleExpand}
            size={'md'}
            variant={'outline'}
            color={'accent'}
            className={`w-full `}
          >
            Collapse
          </Button>
        ) : (
          <IconButton
            onClick={toggleExpand}
            size={'md'}
            variant={'outline'}
            color={'accent'}
          >
            <NavArrowRight fr={undefined} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export const ModulesDisplay = ({ modules, expand, path }) => {
  const { elements } = useCallRouteEditor();

  return (
    <>
      {
        modules?.length
          ? modules.map((module, index) => {
              return (
                <React.Fragment key={index}>
                  {/*<SidebarSeparator />*/}
                  <ModuleButton expand={expand} module={module} />
                </React.Fragment>
              );
            })
          : null
        /*<AddModuleButton
          data={elements.find(elem => elem.type === 'InsertNode')?.data}
          expanded={expand}
        />*/
      }
      {/*{nestedPath.length > 2 ? (
        <NestedModulesPathDisplay
          path={path}
          expand={expand}
          nestedPath={nestedPath}
        />
      ) : null}*/}
    </>
  );
};

export const MenuOptionButton = ({ route, expand, rootPath, modules }) => {
  const { selectedElementKey, setSelectedElementKey, invalidModules } =
    useBuilderContext();
  const { selectedElement, elements, callflow } = useCallRouteEditor();
  const segments = route.split('.');
  const targetIndex = segments.findIndex(val => val === 'targets') + 1;
  const target = segments[targetIndex];
  const targetPath =
    rootPath + segments.slice(0, targetIndex).join('.') + '.' + target;
  const targetModule = getAtPath(callflow, targetPath);

  let name, icon;

  switch (target) {
    case '_':
      icon = <Cancel fr={undefined} />;
      name = 'Invalid Entry';
      break;
    case 'timeout':
      icon = <TimerOff fr={undefined} />;
      name = 'Menu Timeout';
      break;
    default:
      icon = MenuKeyMap.get(target);
      name = `Dial ${target}`;
      break;
  }

  const targetModulesPath = targetPath + '.callflow.strategy.data.modules';

  const nextElement = modules?.length
    ? null
    : elements.find(elem => elem.data?.modifyPath === targetPath + '.callflow');

  return (
    <>
      <SidebarButton
        expand={expand}
        onClick={() => {
          // console.log('target path', targetModulesPath);
          setSelectedElementKey(targetModulesPath);
        }}
        isActive={targetModulesPath === selectedElementKey}
        icon={icon}
        name={targetModule?.name || name}
      />
      {!nextElement ? null : expand ? (
        <>
          {nextElement?.type === 'InsertNode' ? (
            <>
              <SidebarSeparator />
              <AddModuleButton expanded data={nextElement.data} />
            </>
          ) : null}
        </>
      ) : (
        <>
          {nextElement?.type === 'InsertNode' ? (
            <>
              <SidebarSeparator />
              <AddModuleButton data={nextElement.data} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};
