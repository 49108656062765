import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import {
  Alert,
  Button,
  IconButton,
  Listbox,
  SquareProgress,
} from '../../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import {
  getAtPath,
  setAtPath,
  useToggleReducer,
} from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import {
  useListVmboxesQuery,
  useVmboxQuery,
} from '../../../../../../hooks/queries/vmbox';
import { useUpdateVmboxPartial } from '../../../../../../hooks/mutations/vmbox';
import { useHoverDirty } from 'react-use';
import { AudioPlayer } from '../../../../../../components/AudioPlayer';
import {
  Cancel,
  DeleteCircle,
  EditPencil,
  NavArrowDown,
  NavArrowUp,
  Plus,
  Trash,
  WarningTriangle,
} from 'iconoir-react';
import { GreetingVmbox } from '../../../../../admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { EditLineGreeting } from '../../../PipeView/components/Voicemail/Voicemail';
import { SettingsPanelLoader } from '../../../../../../design-lib/components/SettingsPanelLoader.tsx';
import { MenuButton } from '../../../../../../components/MessageRoutingVisualBuilder/Flow/nodes/common/MenuButton';
import AudioEditor from '../../../../../../components/AudioEditor/AudioEditor';
import { useBuilderContext } from '../../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { INLINE_BLANK_CALLFLOW } from '../../../../../../components/IvrBuilder/Flow/nodes/InsertMenuOption/InsertMenuOption';
import { TIMEZONE_LIST } from '../../../../../../components/TimezoneDialogAccount/TimezoneDialogAccount';
import { toast } from 'react-toastify';
import { PreviousModuleButton } from './PreviousModuleButton';

export const ScheduleModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const [edit, toggleEdit] = useToggleReducer();

  // const insertScheduleOptionElement = elements.find(
  //   elem =>
  //     elem.type === 'InsertScheduleOptionNode' &&
  //     elem.data.sc.moduleItem.id === moduleItem.id,
  // );

  // console.log('selected elem path', insertScheduleOptionElement);

  if (!selectedElement?.data?.moduleItem?.data?.routes) return null;
  const schedule_id = selectedElement?.data?.moduleItem?.data?.schedule_id;
  const schedule = callflow?.schedules?.[schedule_id];
  const options = selectedElement?.data?.moduleItem?.data?.routes;

  if (!schedule) return null;

  const handleTimezoneChange = timezone => {
    setAtPath(
      callflow,
      `.schedules.${moduleItem.data.schedule_id}.timezone`,
      timezone,
    );
    setCallflow({ ...callflow });
  };

  const handleEdit = optionId => {
    const path =
      modifyPath +
      `.data.routes.${optionId}.callflow.message_strategy.data.module`;
    const test = getAtPath(callflow, path);

    setSelectedElementKey(
      modifyPath +
        `.data.routes.${optionId}.callflow.message_strategy.data.modules`,
    );
  };

  const hasFallbackModules =
    !!options['fallback'].callflow.message_strategy.data.modules.length;

  const insertScheduleOptionElement = elements.find(
    elem =>
      elem.type === 'InsertScheduleOptionNode' &&
      elem.data.scheduleData.moduleItem.id === moduleItem.id,
  );

  const handleAdd = () => {
    const scheduleData = insertScheduleOptionElement.data.scheduleData;

    setAtPath(scheduleData.callflow, `schedules.${schedule_id}.categories`, [
      ...scheduleData.callflow.schedules[schedule_id].categories,
      {
        id: (Date.now() + 100).toString(),
        name: 'New Route',
        values: [],
      },
    ]);
    scheduleData.setCallflow(
      { ...scheduleData.callflow },
      { name: 'Add Schedule Flow' },
    );
  };

  return (
    <>
      <Listbox
        label={'Timezone'}
        // onOpenChanged={setDisableScroll}
        size={'md'}
        className={`w-full self-center`}
        options={TIMEZONE_LIST}
        value={callflow?.schedules?.[moduleItem?.data?.schedule_id]?.timezone}
        onChange={handleTimezoneChange}
      />
      <span className={`text-md mt-8 font-medium`}>Schedule Options</span>
      <p className={`text-neutral-60 text-sm my-2`}>
        Specify your business hour to direct callers based on when they call.
      </p>
      <div
        className={
          'flex-1 divide-y divide-neutral-20 items-center flex flex-col'
        }
      >
        {schedule?.categories?.map((category, _index) => {
          const hasValues = category.values.length;
          const modulesPath = `${modifyPath}.data.routes.${category.id}.callflow.message_strategy.data.modules`;
          const categoryModules = getAtPath(callflow, modulesPath);
          const hasModules = !!categoryModules?.length;

          const handleClear = () => {
            if (hasModules) {
              setAtPath(callflow, modulesPath, []);
            } else {
              const updatedCategories = callflow.schedules[
                schedule_id
              ]?.categories.filter(cat => cat.id !== category.id);
              setAtPath(
                callflow,
                `schedules.${schedule_id}.categories`,
                updatedCategories,
              );
            }

            setCallflow({ ...callflow });
          };

          const index = schedule?.categories?.findIndex(
            cat => cat.id === category.id,
          );
          const showIncrease = index !== 0 && schedule?.categories?.length > 1;
          const showDecrease = index < schedule?.categories?.length - 1;

          const onIncreasePriority = () => {
            const updatedCategories = schedule.categories.filter(
              cat => cat.id !== category.id,
            );

            updatedCategories.splice(index - 1, 0, category);

            setAtPath(
              callflow,
              `schedules.${schedule_id}.categories`,
              updatedCategories,
            );
            setCallflow({ ...callflow });
          };
          const onDecreasePriority = () => {
            const updatedCategories = schedule.categories.filter(
              cat => cat.id !== category.id,
            );

            updatedCategories.splice(index + 1, 0, category);

            setAtPath(
              callflow,
              `schedules.${schedule_id}.categories`,
              updatedCategories,
            );
            setCallflow({ ...callflow });
          };

          return (
            <div
              key={category.id + `${_index}`}
              className={`w-full ${
                hasValues && hasModules ? '' : 'bg-neutral-10'
              } flex px-4  items-center justify-between`}
            >
              <div
                onClick={() => {
                  handleEdit(category.id);
                }}
                // onTouchStart={e => {
                //   e.preventDefault();
                //   handleEdit(category.id);
                // }}
                className={`cursor-pointer py-4 items-center flex-1 flex space-x-1 h-full`}
              >
                <div className={`flex-1 flex flex-col `}>
                  <span>{category.name}</span>
                  {hasModules && hasValues ? null : (
                    <span className={`text-negative-60 text-sm`}>
                      {hasValues ? 'No Modules' : 'No Rules'}
                    </span>
                  )}
                </div>
              </div>
              <div className={`flex space-x-2`}>
                <IconButton
                  size={'md'}
                  onClick={() => handleEdit(category.id)}
                  variant={'outline'}
                  color={'accent'}
                >
                  <EditPencil fr={undefined} />
                </IconButton>
                <IconButton
                  size={'md'}
                  onClick={handleClear}
                  variant={'outline'}
                  color={'negative'}
                  title={hasModules ? 'Clear Modules' : 'Delete'}
                >
                  {hasModules ? (
                    <DeleteCircle fr={undefined} />
                  ) : (
                    <Trash fr={undefined} />
                  )}
                </IconButton>
              </div>
              <div className={`flex flex-col ml-4 space-y-2`}>
                <IconButton
                  size={'sm'}
                  onClick={onIncreasePriority}
                  variant={'ghost'}
                  color={'accent'}
                  disabled={!showIncrease}
                >
                  <NavArrowUp fr={undefined} />
                </IconButton>
                <IconButton
                  disabled={!showDecrease}
                  size={'sm'}
                  onClick={onDecreasePriority}
                  variant={'ghost'}
                  color={'accent'}
                >
                  <NavArrowDown fr={undefined} />
                </IconButton>
              </div>
            </div>
          );
        })}
        <div
          className={` w-full flex px-4 ${
            hasFallbackModules ? '' : 'bg-attention-10'
          }  items-center justify-between `}
        >
          <div
            onClick={() => {
              handleEdit('fallback');
            }}
            // onTouchStart={e => {
            //   e.preventDefault();
            //   handleEdit('fallback');
            // }}
            className={`cursor-pointer py-4 items-center flex-1 flex space-x-1 h-full`}
          >
            {hasFallbackModules ? null : (
              <WarningTriangle
                fr={undefined}
                className={`text-attention-60 mr-2`}
              />
            )}
            <div className={`flex-1 flex flex-col`}>
              <div className={``}>{schedule?.fallback.name}</div>
              {hasFallbackModules ? null : (
                <span className={`text-sm text-negative-60`}>No Modules</span>
              )}
            </div>
          </div>
          <IconButton
            size={'md'}
            onClick={() => handleEdit('fallback')}
            variant={'outline'}
            color={'accent'}
          >
            <EditPencil fr={undefined} />
          </IconButton>
        </div>
        <div className={` w-full flex px-4  items-center justify-between `}>
          <div
            onClick={() => {
              handleAdd();
            }}
            // onTouchStart={e => {
            //   e.preventDefault();
            //   handleAdd();
            // }}
            className={`cursor-pointer py-4 flex-1`}
          >
            Add Route
          </div>
          <Button
            size={'md'}
            onClick={handleAdd}
            variant={'outline'}
            color={'accent'}
            startIcon={<Plus fr={undefined} />}
          >
            Add Route
          </Button>
        </div>
      </div>

      <MenuButton
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <div
        className={
          'flex space-x-4 border-t pt-4 border-neutral-20 justify-between'
        }
      >
        <PreviousModuleButton />
      </div>
    </>
  );
};
