import DevicesIcon from '@mui/icons-material/Devices';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { BuyPhoneNumberDialog } from 'app/components/BuyPhoneNumberDialog';
import { CreatePhonesBulkv2 } from 'app/components/CreatePhonesBulk';
import { DeviceListOptions } from 'app/components/ListDevices/components/DeviceListOptions';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { DeviceType } from 'app/components/ListDevices/components/DeviceType';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { AddSharedLineDialog } from 'app/pages/v2/settings/Pipes/components/AddSharedLineDialog';
import { Plus as PlusIcon } from 'iconoir-react';
import { DeviceDisableSettings } from './View/components/DeviceDisableSettings';
import { OwnerTableCell } from 'app/components/OwnerTableCell';
import {
  Alert,
  Button,
  Dialog,
  Setting,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';

import { useListDevicesQuery } from 'app/hooks/queries/device';

import useToggleReducer from 'app/utilities/useToggleReducer';
import * as React from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import { useMediaQuery } from '@mui/material';

export const formatMacAddress = (address: string) => {
  return `${address.substring(0, 2)}:${address.substring(
    2,
    4,
  )}:${address.substring(4, 6)}:${address.substring(6, 8)}:${address.substring(
    8,
    10,
  )}:${address.substring(10, 12)}`;
};

function Devices() {
  // useRouteMatch() // no argument, returns current match? (but that is the PARENT Route, not the Setting Route...)

  const {
    data: devicesPage,
    isLoading: devicesLoading,
    refetch,
  } = useListDevicesQuery({
    take: 500,
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['device_type', '!=', 'appphone'],
              // ['subtype', 'exists'],
              // ['subtype', '!=', 'webphone'],
            ],
          },
          or: [
            {
              variables: {
                docFilters: [
                  // ['device_type', '!=', 'appphone'],
                  // ['subtype', 'exists'],
                  ['subtype', '!=', 'webphone'],
                ],
              },
            },
            {
              variables: {
                docFilters: [
                  // ['device_type', '!=', 'appphone'],
                  ['subtype', 'not_exists'],
                ],
              },
            },
          ],
        },
      ],
    },
    options: {},
  });
  const devices = devicesPage?.devices ?? [];
  // console.log('devices:', devices);
  const [openCreateDialog, toggleOpenCreateDialog] = useToggleReducer(false);

  const isSmallMobile = useMediaQuery('(max-width:500px)', { noSsr: true });

  return (
    <>
      <AddDevicesDialog
        open={openCreateDialog}
        // pipe={pipe}
        onCancel={() => {
          toggleOpenCreateDialog();
          refetch();
        }}
        // onSend={toggleOpenSendDialog}
      />
      <SettingsPageWrapper
        isLoading={devicesLoading}
        loadingLabel={'Loading devices...'}
        label={'Devices'}
        subLabel={'Manage external devices and third party apps'}
        buttonProps={{
          children: 'Add device',
          onClick: toggleOpenCreateDialog,
          startIcon: <PlusIcon fr={undefined} />,
        }}
      >
        {devices.length ? (
          <Table>
            {devices.map((device, index) => {
              // let onlineCount = 0;

              // Object.keys(device.doc.endpoints).forEach(member => {
              //   if (onlineUsers?.[member]) onlineCount++;
              // });
              //
              // const color = !device.doc.type
              //   ? 'negative'
              //   : onlineCount > 0
              //   ? 'positive'
              //   : 'attention';

              return (
                <TableRow
                  showBorder={devices.length > 1 && index < devices.length - 1}
                  key={device.id}
                >
                  <TableCell className={`font-medium flex flex-col`} flex>
                    <Link to={`/settings/devices/${device.id}`} className="">
                      {device.doc.name?.length ? device.doc.name : 'Unnamed'}
                    </Link>
                    {device.doc.device_type === 'sip_device' &&
                    device.doc.mac_address ? (
                      <span className={`text-sm text-neutral-60`}>
                        {formatMacAddress(device.doc.mac_address)}
                      </span>
                    ) : null}
                  </TableCell>
                  <TableCell className={`hidden sm:flex`} flex>
                    <DeviceType device={device} />
                  </TableCell>
                  <TableCell className={'justify-end flex'} flex>
                    {isSmallMobile && device.Owner ? (
                      <UserAvatar
                        key={device.Owner?.id}
                        user={device.Owner}
                        size={'sm'}
                      />
                    ) : (
                      <DeviceOwnerCell device={device} owner={device.Owner} />
                    )}
                    {/*<UsersAndDevicesCell device={device} />*/}
                  </TableCell>
                  <TableCell>
                    {/* {device.doc.enabled ? null : (
                              <div className="w-min select-none bg-background-negative-secondary whitespace-nowrap text-content-negative text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                                DISABLED
                              </div>
                            )} */}
                    <DeviceDisableSettings deviceDoc={device.doc} />
                    {/* <div className="h-full whitespace-nowrap flex items-center">
                              <button
                                // @ts-ignore
                                onClick={e => editDeviceSet(device)}
                                type="button"
                                className={`cursor-pointer
                                bg-background-${color}-secondary
                                text-content-${color}
                                border-none
                                text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
                              >
                                {!device.doc.type
                                  ? 'REQUIRES SET UP'
                                  : `${onlineCount} MEMBER${
                                      onlineCount > 1 || onlineCount === 0
                                        ? 'S'
                                        : ''
                                    } ONLINE`}*/}
                    {/*</button>*/}
                    {/*</div>*/}
                  </TableCell>
                  <TableCell>
                    <div className="h-full flex items-center">
                      {/* <button>Actions</button> */}
                      <DeviceListOptions
                        onDelete={() => {
                          refetch();
                        }}
                        device={device}
                        onComplete={() => {
                          refetch();
                        }}
                        toggleRowSelected={() => {}}
                        // vertical={true}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <Alert
            reverse
            label={'No devices'}
            body={'No devices could be found associated with your account'}
          />
        )}
      </SettingsPageWrapper>
    </>
  );

  // return (
  //   <>
  //     <div
  //       className={`max-w-[650px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //     >
  //       <AddDevicesDialog
  //         // pipe={pipe}
  //         onCancel={() => {
  //           toggleOpenCreateDialog();
  //           refetch();
  //         }}
  //         // onSend={toggleOpenSendDialog}
  //       />
  //       <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
  //         <SimpleBar style={{ height: '100%' }}>
  //           <div className={'px-8 py-3 h-full'}>
  //             <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
  //               <div className={'flex  justify-between items-start space-x-2'}>
  //                 <div
  //                   className={
  //                     'btn-accent-outline mt-[5px] text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
  //                   }
  //                 >
  //                   <DevicesIcon
  //                     className={'fill-content-accent'}
  //                     sx={{ fontSize: 'inherit' }}
  //                   />
  //                 </div>
  //                 <div>
  //                   <div className="font-bold text-xl">Phones and Devices</div>
  //                   <div className="text-base text-neutral-500">
  //                     In addition to making calls using our iOS app or Browser
  //                     Phone, you can bring your own desk phones, softphones, or
  //                     apps such as Bria or Zoiper
  //                   </div>
  //                 </div>
  //               </div>
  //               <Button
  //                 color={'accent'}
  //                 size={'sm'}
  //                 variant={'outline'}
  //                 className={'h-min self-end mb-1'}
  //                 onClick={toggleOpenCreateDialog}
  //                 startIcon={<PersonAddIcon />}
  //               >
  //                 Add Device
  //               </Button>
  //             </div>
  //             <div className="p-3 h-full">
  //               <table className="w-full table-fixed border-separate border-spacing-y-3">
  //                 <tbody className="space-y-4">
  //                   {devices.map(device => {
  //                     // let onlineCount = 0;
  //
  //                     // Object.keys(device.doc.endpoints).forEach(member => {
  //                     //   if (onlineUsers?.[member]) onlineCount++;
  //                     // });
  //                     //
  //                     // const color = !device.doc.type
  //                     //   ? 'negative'
  //                     //   : onlineCount > 0
  //                     //   ? 'positive'
  //                     //   : 'attention';
  //
  //                     return (
  //                       <tr
  //                         key={device.id}
  //                         className="flex  items-center justify-between border-solid border-0 border-b pb-4 border-border-neutral/50"
  //                       >
  //                         <td className={'w-1/5'}>
  //                           <div className="flex items-center space-x-6">
  //                             <div className="">
  //                               <Link
  //                                 to={`/v2/settings/devices/${device.id}`}
  //                                 className="cursor-pointer font-semibold text-content-accent no-underline"
  //                               >
  //                                 {device.doc.name?.length
  //                                   ? device.doc.name
  //                                   : 'Unnamed'}
  //                               </Link>
  //
  //                               <div className="text-neutral-400">
  //                                 {<DeviceType device={device} />}
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </td>
  //                         <td className={'w-1/3'}>
  //                           <DeviceOwnerCell
  //                             className={'justify-center'}
  //                             device={device}
  //                             owner={device.Owner}
  //                           />
  //                           {/*<UsersAndDevicesCell device={device} />*/}
  //                         </td>
  //                         <td className={'w-[130px]'}>
  //                           {/* {device.doc.enabled ? null : (
  //                             <div className="w-min select-none bg-background-negative-secondary whitespace-nowrap text-content-negative text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
  //                               DISABLED
  //                             </div>
  //                           )} */}
  //                           <DeviceDisableSettings deviceDoc={device.doc} />
  //                           {/* <div className="h-full whitespace-nowrap flex items-center">
  //                             <button
  //                               // @ts-ignore
  //                               onClick={e => editDeviceSet(device)}
  //                               type="button"
  //                               className={`cursor-pointer
  //                               bg-background-${color}-secondary
  //                               text-content-${color}
  //                               border-none
  //                               text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
  //                             >
  //                               {!device.doc.type
  //                                 ? 'REQUIRES SET UP'
  //                                 : `${onlineCount} MEMBER${
  //                                     onlineCount > 1 || onlineCount === 0
  //                                       ? 'S'
  //                                       : ''
  //                                   } ONLINE`}*/}
  //                           {/*</button>*/}
  //                           {/*</div>*/}
  //                         </td>
  //                         <td>
  //                           <div className="h-full flex items-center">
  //                             {/* <button>Actions</button> */}
  //                             <DeviceListOptions
  //                               onDelete={() => {
  //                                 refetch();
  //                               }}
  //                               device={device}
  //                               onComplete={() => {
  //                                 refetch();
  //                               }}
  //                               toggleRowSelected={() => {}}
  //                               // vertical={true}
  //                             />
  //                           </div>
  //                         </td>
  //                       </tr>
  //                     );
  //                   })}
  //                 </tbody>
  //               </table>
  //               {devicesLoading ? (
  //                 <div className={'pt-5 text-neutral-400 w-full text-center'}>
  //                   Loading Devices...
  //                 </div>
  //               ) : null}
  //               {!devicesLoading && !devices?.length ? (
  //                 <div className={'pt-5 text-neutral-400 w-full text-center'}>
  //                   No devices have been created yet
  //                 </div>
  //               ) : null}
  //               {/*<div>*/}
  //               {/*  <div className="">*/}
  //               {/*    <div className="text-2xl" onClick={() => refetch()}>*/}
  //               {/*      Devices*/}
  //               {/*    </div>*/}
  //               {/*    <div className="flex justify-between">*/}
  //               {/*      <div className="text-md mb-2 text-neutral-500">*/}
  //
  //               {/*      </div>*/}
  //               {/*      <div className="">*/}
  //               {/*        <button*/}
  //               {/*          className="btn whitespace-nowrap"*/}
  //               {/*          onClick={toggleOpenCreateDialog}*/}
  //               {/*        >*/}
  //               {/*        </button>*/}
  //               {/*      </div>*/}
  //               {/*    </div>*/}
  //               {/*  </div>*/}
  //               {/*  <div className="p-4 space-y-4">*/}
  //               {/*{devices.map(device => {*/}
  //               {/*  return (*/}
  //               {/*    <div key={device.id} className="flex justify-between ">*/}
  //               {/*      <div className="flex">*/}
  //               {/*        <div className="">*/}
  //               {/*          <div className="font-semibold mr-4">*/}
  //               {/*            {device.doc.device_type}*/}
  //               {/*          </div>*/}
  //               {/*        </div>*/}
  //               {/*        <div className="">*/}
  //               {/*          <div className="font-semibold">*/}
  //               {/*            <Link*/}
  //               {/*              to={`/v2/settings/devices/${device.id}`}*/}
  //               {/*              className="cursor-pointer"*/}
  //               {/*            >*/}
  //               {/*              {device.doc.name?.length*/}
  //               {/*                ? device.doc.name*/}
  //               {/*                : 'Unnamed'}*/}
  //               {/*            </Link>*/}
  //               {/*          </div>*/}
  //               {/*          <div className="text-neutral-400">*/}
  //               {/*            {device.doc.owner_id}*/}
  //               {/*          </div>*/}
  //               {/*        </div>*/}
  //               {/*      </div>*/}
  //               {/*      <div>*/}
  //               {/*        <button>Actions</button>*/}
  //               {/*      </div>*/}
  //               {/*    </div>*/}
  //               {/*  );*/}
  //               {/*})}*/}
  //               {/*{devicesLoading ? <div>Loading Devices...</div> : null}
  //               {!devicesLoading && !devices?.length ? (
  //                 <div>
  //                   No additional phones or devices have been added to your
  //                   account
  //                 </div>
  //               ) : null}*/}
  //             </div>
  //           </div>
  //         </SimpleBar>
  //       </div>
  //     </div>
  //   </>
  // );
}

const AddDevicesDialog = ({ onCancel, open }) => {
  return (
    <Dialog size={'lg'} open={open} onClose={onCancel}>
      <CreatePhonesBulkv2 onComplete={onCancel} />
    </Dialog>
  );
};

export default Devices;
