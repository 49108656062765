import { AddressAutocomplete } from 'app/components/AddressAutocomplete';
import setupAccountDetails from 'app/components/Onboarding/Vsb/Step3/fn/setupAccountDetails';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { useAuthSelector } from 'app/data/auth';
import { Box, Fade, Slide, useTheme } from 'app/design';
import { Button } from 'app/design-lib';
import { useCreateCallflow } from 'app/hooks/mutations/callflow/useCreateCallflow';
import { useAccountQuery } from 'app/hooks/queries/account';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import React, { Fragment, useEffect, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { Building as BuildingIcon, PinAlt as PinIcon } from 'iconoir-react';
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

// const PulseBackgroundKeyframe = keyframes`
//   0% {
//     transform:
//   }
//   100% {
//     box-shadow: 0px 0px 20px rgb(239, 83, 80, 1.0)
//   }
// `;

const Step1 = () => {
  const router = useHistory();
  const { handleSubmit, watch, setValue, setError, trigger, clearErrors } =
    useFormContext();
  const { isSubmitting, isValid, errors } = useFormState();
  const { auth_token, account_id } = useAuthSelector();
  const createCallflow = useCreateCallflow();
  const [isSaving, setIsSaving] = useState(false);
  const [inVal, setInVal] = useState(true);

  const { data: account } = useAccountQuery({});
  const companyName = watch('companyName');
  const address = watch('address');
  useEffect(() => {
    if (!account || watch('address')) {
      return;
    }
    if (!account.doc.name?.toLowerCase().startsWith('callingio')) {
      setValue('companyName', account.doc.name);
    }
    if (account.doc.address) {
      // set values for address (will be re-translated back)
      setValue('address', {
        streetLine: account.doc.address.street_address, // line 1
        secondary: account.doc.address.extended_address,
        city: account.doc.address.locality,
        state: account.doc.address.region, // state
        zipcode: account.doc.address.postal_code,
      });
      setValue('existingAddress', account.doc.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  // const clearAndHandleSubmit = e => {
  //   clearErrors();
  //   handleSubmit(onSubmit)(e);
  // };

  const prevStep = watch('prevStep');
  const transitionToStep = watch('transitionToStep');
  const thisStep = 0;
  const timeout = 350;
  const handleNext = async () => {
    setIsSaving(true);
    try {
      // Update account name/address (used for integrations, etc)
      await setupAccountDetails({
        auth_token,
        accountId: account_id,
        companyName,
        address,
      });
    } catch (e: any) {
      const response = e?.response;

      const errors = parseAndSetKazooMutationErrors({
        response,
        setError: () => {},
      });

      console.log('kazoo errors', errors);

      const nameError = errors.find(error => error.field === 'name');

      if (nameError) {
        // assuming
        setValue('activeStep', 0);
        setError('companyName', {
          message: nameError.message,
        });
      } else {
        alert(`Sorry, there was an error creating your account`);
      }
      setIsSaving(false);
      return;
    }
    setIsSaving(false);

    // TODO: validate the address?
    // - figure out what prefix to set
    setValue('prefix', '831');

    // transition:
    setInVal(false);
    setValue('transitionToStep', thisStep + 1);
    setTimeout(() => {
      // setValue('prevStep', thisStep);
      setValue('activeStep', thisStep + 1);
    }, timeout);
  };
  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const direction = inVal
    ? prevStep > thisStep
      ? 'right'
      : 'left'
    : transitionToStep > thisStep
    ? 'right'
    : 'left';

  const theme = useTheme();
  const existingAddress = watch('existingAddress');

  return (
    <div className={`w-full flex flex-col space-y-4`}>
      <div className={`w-full`}>
        <HookFormTextField
          startIcon={<BuildingIcon fr={undefined} />}
          options={{ maxLength: 128 }}
          name={'companyName'}
          label={'Company name'}
          infoText={`This will be used as your Caller ID display. You can change
                  this later if needed.`}
          // errorMessageParser={() => {
          //   return 'Company Name cannot exceed 128 characters';
          // }}
          // label={'Key Input'}
          // @ts-ignore
          variant="outlined"
          // placeholder="Company Name"
          fullWidth
          // autoFocus
        />
      </div>
      <div className={`w-full`}>
        <AddressAutocomplete
          // @ts-ignore
          startIcon={<PinIcon fr={undefined} />}
          label={'Business address'}
          infoText={`This is required for taxes, and emergency service operators
                  (dialing 911).`}
          websiteKey={'120287158445923608'}
          textfieldProps={{ placeholder: 'Street Address' }}
          autocompleteProps={{
            sx: {
              '& .MuiAutocomplete-endAdornment': {
                opacity: 0,
              },
            },
          }}
          onChange={value => {
            console.log('Address Chosen:', value);
            if (!value) {
              return;
            }
            setValue('address', value?.value);
          }}
        />
        {existingAddress ? (
          <div className="text-sm text-neutral-700">
            {existingAddress.street_address} {existingAddress.extended_address}{' '}
            {existingAddress.locality}, {existingAddress.region}{' '}
            {existingAddress.postal_code}
          </div>
        ) : null}
      </div>
      <Button
        disabled={isSaving || !isValid || !watch('companyName', '').length}
        color={'accent'}
        size={'lg'}
        variant={'fill'}
        onClick={handleNext}
        className={`justify-self-end w-full self-end`}
      >
        {isSaving ? 'Loading...' : 'Next'}
      </Button>
    </div>
  );

  // return (
  //   <Slide in={inVal} timeout={timeout} direction={direction}>
  //     <div>
  //       <Fade in={inVal} timeout={timeout}>
  //         <div>
  //           <div className="rounded bg-white shadow-md p-4">
  //             <div>
  //               <div className="text-lg mb-1">
  //                 What is the name of your company or team?
  //               </div>
  //               <div className="text-md text-neutral-700  mb-1">
  //                 This will be used as your Caller ID display. You can change
  //                 this later if needed.
  //               </div>
  //               <HookFormTextField
  //                 options={{ maxLength: 128 }}
  //                 name={'companyName'}
  //                 errorMessageParser={() => {
  //                   return 'Company Name cannot exceed 128 characters';
  //                 }}
  //                 // label={'Key Input'}
  //                 // @ts-ignore
  //                 variant="outlined"
  //                 placeholder="Company Name"
  //                 fullWidth
  //                 autoFocus
  //               />
  //             </div>
  //             <div className="mt-4">
  //               <div className="text-lg mb-1">
  //                 Where is your business address?
  //               </div>
  //               <div className="text-md text-neutral-700 mb-1">
  //                 This is required for taxes, and emergency service operators
  //                 (dialing 911).
  //               </div>
  //               {/* <TextField
  //               variant="outlined"
  //               placeholder="Street Address"
  //               fullWidth
  //             /> */}
  //               <AddressAutocomplete
  //                 // @ts-ignore
  //                 websiteKey={'120287158445923608'}
  //                 textfieldProps={{ placeholder: 'Street Address' }}
  //                 autocompleteProps={{
  //                   sx: {
  //                     '& .MuiAutocomplete-endAdornment': {
  //                       opacity: 0,
  //                     },
  //                   },
  //                 }}
  //                 onChange={value => {
  //                   console.log('Address Chosen:', value);
  //                   if (!value) {
  //                     return;
  //                   }
  //                   setValue('address', value?.value);
  //                 }}
  //               />
  //               {existingAddress ? (
  //                 <div className="text-sm text-neutral-700">
  //                   {existingAddress.street_address}{' '}
  //                   {existingAddress.extended_address}{' '}
  //                   {existingAddress.locality}, {existingAddress.region}{' '}
  //                   {existingAddress.postal_code}
  //                 </div>
  //               ) : null}
  //               {/* <GooglePlacesAutocomplete
  //               apiKey="AIzaSyDo7DbGtZg7WgeCW8Ik3enQo5zHftI6GTo"
  //               selectProps={{
  //                 placeholder: 'Street Address',
  //                 openMenuOnFocus: false,
  //                 openMenuOnClick: false,
  //                 noOptionsMessage: m => 'test no options',
  //                 loadingMessage: m => 'Searching addresses',
  //                 components: {
  //                   DropdownIndicator: () => null,
  //                   IndicatorSeparator: () => null,
  //                 },
  //                 styles: {
  //                   // container: (provided, state) => ({
  //                   //   ...provided,
  //                   //   paddingBottom: state.isFocused ? 0 : '4px',
  //                   //   '&:hover': {
  //                   //     paddingBottom: '3px',
  //                   //   },
  //                   // }),
  //                   control: (provided, state) => ({
  //                     ...provided,
  //                     boxShadow: state.isFocused
  //                       ? `0px 0px 2px ${theme.palette.primary.main}`
  //                       : `none`,
  //                     // marginBottom: '1px',
  //                     borderColor: state.isFocused
  //                       ? `${theme.palette.primary.main} !important`
  //                       : 'rgba(0, 0, 0, 0.23)',
  //                     '&:focus': {
  //                       // marginLeft: '-1px',
  //                       // marginTop: '-1px',
  //                       borderColor: theme.palette.primary.main,
  //                       boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
  //                       // borderWidth: '2px',
  //                     },
  //                     '&:hover': {
  //                       // marginLeft: '-1px',
  //                       // marginTop: '-1px',
  //                       // // marginBottom: '0px',
  //                       borderColor: '#333',
  //                       // boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
  //                       // borderWidth: '2px',
  //                     },
  //                   }),
  //                   input: (provided, state) => ({
  //                     ...provided,
  //                     // color: 'blue',
  //                     fontWeight: 500,
  //                     fontFamily: 'DM Sans',
  //                     padding: '11px 4px',
  //                   }),
  //                   placeholder: provided => ({
  //                     ...provided,
  //                     // color: 'blue',
  //                     opacity: 0.4,
  //                     color: 'currentColor',
  //                     fontFamily: 'DM Sans',
  //                     fontWeight: 500,
  //                     marginLeft: '6px',
  //                     // padding: '11px 14px',
  //                   }),
  //                   option: provided => ({
  //                     ...provided,
  //                     fontFamily: 'DM Sans',
  //                     fontWeight: 500,
  //                   }),
  //                   singleValue: provided => ({
  //                     ...provided,
  //                     fontFamily: 'DM Sans',
  //                     fontWeight: 500,
  //                     marginLeft: '6px',
  //                   }),
  //                 },
  //               }}
  //             /> */}
  //             </div>
  //             <div className="mt-4 ">
  //               <Button
  //                 color={
  //                   !isValid || !watch('companyName', '').length
  //                     ? 'neutral'
  //                     : 'positive'
  //                 }
  //                 variant={'fill'}
  //                 className="ml-auto"
  //                 onClick={handleNext}
  //                 disabled={
  //                   !isValid || !watch('companyName', '').length
  //                   // ||
  //                   // !watch('address')
  //                 }
  //               >
  //                 Next
  //               </Button>
  //             </div>
  //           </div>
  //           {/* {process.env.NODE_ENV === 'development' ? (
  //             <div className="mt-2 text-center">
  //               <Button
  //                 color="error"
  //                 size="small"
  //                 to={'/admin/dashboard'}
  //                 component={RouterLink}
  //               >
  //                 DEV: Reset account from Dashboard (very bottom)
  //               </Button>
  //             </div>
  //           ) : null} */}
  //         </div>
  //       </Fade>
  //     </div>
  //   </Slide>
  // );
};

const buildMainSchedule = data => {
  /*
  holidays.usa_standard
  holidays.usa_auto_update
  holidays.use_extra
  holidays.extra
  */
  const holidays = {};
};

export default Step1;
