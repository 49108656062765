import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

export const phoneNumberMessagingSet = async ({ number, data }: any) => {
  return baseTioApiAccountRequest(
    'POST',
    `/phone_numbers/${encodeURIComponent(number)}/messaging`,
    { data }, // note we are passing "data" as the key in an object!
  );
};

export const useCnamSet = () => {
  return useMutation(phoneNumberCnamSet);
};

export const phoneNumberCnamSet = async ({ number, data }: any) => {
  return baseTioApiAccountRequest(
    'POST',
    `/phone_numbers/${encodeURIComponent(number)}/cnam`,
    { data }, // note we are passing "data" as the key in an object!
  );
};

export const useMessagingSet = () => {
  return useMutation(phoneNumberMessagingSet);
};

export const useTioMutation = () => {
  // @ts-ignore
  return useMutation(async ({ method, url, data }) => {
    return baseTioApiAccountRequest(
      // @ts-ignore
      method,
      // @ts-ignore
      url,
      // @ts-ignore
      { data },
    );
  });
};

export const useTioQuery = accountUrl => {
  // @ts-ignore
  return useQuery([accountUrl], () => {
    return baseTioApiAccountRequest(
      // @ts-ignore
      'GET',
      // @ts-ignore
      accountUrl,
    );
  });
};

export const usePipeCreate = () => {
  return useMutation(async (data: any) => {
    const mutationResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        mutation pipeCreate($data: JSON) {
          pipeCreate(data: $data) {
            success
            message
            data
          }
        }
      `,
      {
        data,
      },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );

    return mutationResponse.pipeCreate.data;
  });
};

export const usePipesQuery = (
  { filters = {}, skip = 0, take = 100 },
  enabled = true,
) => {
  return useQuery(
    ['pipes', filters, skip, take],
    async () => {
      // console.log('data:', data);

      const queryResponse = await request(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
        gql`
          query pipes(
            $skip: Int
            $take: Int
            $orderBy: JSON
            $filters: JSON
            $mask: String
          ) {
            pipes(
              skip: $skip
              take: $take
              orderBy: $orderBy
              filters: $filters
              mask: $mask
            ) {
              totalCount
              skip
              take
              pipes {
                id
                account_id
                key
                type
                metadata
                users
                settings
                updatedAt
                createdAt
              }
            }
          }
        `,
        {
          skip,
          take,
          orderBy: [],
          filters,
          mask: '',
        },
        // @ts-ignore
        { authorization: store.getState().auth.auth_token },
      );

      // console.log('pipes queryResponse:', queryResponse);

      return queryResponse.pipes;
    },
    { enabled, keepPreviousData: true },
  );
};

export const usePipeConvoUpsert = () => {
  const queryClient = useQueryClient();
  return useMutation(async (info: any) => {
    const mutationResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        mutation pipeConvoUpsert($data: JSON) {
          pipeConvoUpsert(data: $data) {
            success
            message
            data
          }
        }
      `,
      {
        ...info,
      },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );

    // console.log('pipeUpdateById mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    return mutationResponse.pipeConvoUpsert.data;
  });
};

export const usePipeUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(async (info: any) => {
    const mutationResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        mutation pipeUpdateById($id: ID, $data: JSON) {
          pipeUpdateById(id: $id, data: $data) {
            success
            message
            data
          }
        }
      `,
      {
        ...info,
      },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );

    // console.log('pipeUpdateById mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    return mutationResponse.pipeUpdateById.data;
  });
};

export const usePipeConvoUsersQuery = (filters = {}) => {
  return useQuery(['pipes', 'pipes-convo-users', filters], async () => {
    // console.log('data:', data);

    const queryResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        query pipeConvoUsers(
          $skip: Int
          $take: Int
          $orderBy: JSON
          $filters: JSON
          $mask: String
        ) {
          pipeConvoUsers(
            skip: $skip
            take: $take
            orderBy: $orderBy
            filters: $filters
            mask: $mask
          ) {
            totalCount
            skip
            take
            pipeConvoUsers {
              id
              account_id
              user_id
              pipe_id
              convo_key
              metadata
              updatedAt
              createdAt
            }
          }
        }
      `,
      {
        skip: 0,
        take: 100,
        orderBy: [],
        filters,
        mask: '',
      },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );

    // console.log('pipeConvoUsers queryResponse:', queryResponse);

    return queryResponse.pipeConvoUsers;
  });
};

export const usePipeConvoUserUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(async (info: any) => {
    const mutationResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        mutation pipeConvoUserUpdateById($id: ID, $data: JSON) {
          pipeConvoUserUpdateById(id: $id, data: $data) {
            success
            message
            data
          }
        }
      `,
      {
        ...info,
      },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );

    // console.log('pipeConvoUserUpdateById mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    return mutationResponse.pipeConvoUserUpdateById.data;
  });
};

export const usePipeConvosQuery = ({ filters }, enabled = true) => {
  return useQuery(
    ['pipe', filters, 'pipe-convos'],
    async () => {
      // console.log('data:', data);

      const queryResponse = await request(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
        gql`
          query pipeConvos(
            $skip: Int
            $take: Int
            $orderBy: JSON
            $filters: JSON
            $mask: String
          ) {
            pipeConvos(
              skip: $skip
              take: $take
              orderBy: $orderBy
              filters: $filters
              mask: $mask
            ) {
              totalCount
              skip
              take
              pipeConvos {
                id
                account_id
                pipe_id
                key
                participants
                metadata
                updatedAt
                createdAt
                LastMessageAt
              }
            }
          }
        `,
        {
          skip: 0,
          take: 100,
          orderBy: [],
          filters,
          mask: '',
        },
        // @ts-ignore
        { authorization: store.getState().auth.auth_token },
      );

      // console.log('pipeConvos queryResponse:', queryResponse);

      return queryResponse.pipeConvos;
    },
    {
      enabled,
    },
  );
};

export const usePipeMessagesQuery = ({
  filters,
  orderBy = [['createdAt', 'desc']],
  take = 500,
}) => {
  const queryKey = ['pipe', filters, 'pipe-messages', take];

  const query = useQuery(
    queryKey,
    async () => {
      // console.log('data:', data);

      const queryResponse = await request(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
        gql`
          query pipeMessages(
            $skip: Int
            $take: Int
            $orderBy: JSON
            $filters: JSON
            $mask: String
          ) {
            pipeMessages(
              skip: $skip
              take: $take
              orderBy: $orderBy
              filters: $filters
              mask: $mask
            ) {
              totalCount
              skip
              take
              pipeMessages {
                id
                account_id
                pipe_id
                key
                convo_key
                type
                data
                metadata
                items
                replies {
                  id
                  account_id
                  pipe_id
                  key
                  convo_key
                  type
                  data
                  metadata
                  items
                  updatedAt
                  createdAt
                }
                updatedAt
                createdAt
              }
            }
          }
        `,
        {
          skip: 0,
          take, // : 100,
          orderBy, //: [['createdAt', 'asc']],
          filters,
          mask: '',
        },
        // @ts-ignore
        { authorization: store.getState().auth.auth_token },
      );

      // console.log('pipeMessages queryResponse:', queryResponse);

      return queryResponse.pipeMessages;
    },
    {
      keepPreviousData: true,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export const usePipeMessageUpdateById = () => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    const mutationResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        mutation pipeMessageUpdateById($data: JSON) {
          pipeMessageUpdateById(data: $data) {
            success
            message
            data
          }
        }
      `,
      {
        data,
      },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );

    // console.log('pipeMessageUpdateById mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    return mutationResponse.pipeMessageUpdateById.data;
  });
};

export const usePipeMessageItemsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    const mutationResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        mutation pipeMessageUserItemsUpdate($data: JSON) {
          pipeMessageUserItemsUpdate(data: $data) {
            success
            message
            data
          }
        }
      `,
      {
        data,
      },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );

    // console.log('pipeMessageUserItemsUpdate mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    return mutationResponse.pipeMessageUserItemsUpdate.data;
  });
};

export const useBrandCreate = () => {
  return useMutation(async data => {
    return tcrRequest('POST', '/brands', data);
  });
};

export const useQueryBrandsList = () => {
  return useQuery(['brands'], async () => {
    return tcrRequest('GET', '/brands');
  });
};

export const useQueryCampaignsList = () => {
  return useQuery(['campaigns'], async () => {
    return tcrRequest('GET', '/campaigns');
  });
};

export const useQueryBrandFeedback = ({ brand_id }) => {
  return useQuery(
    ['brand', brand_id, 'feedback'],
    async () => {
      return tcrRequest('GET', `/brands/${brand_id}/feedback`);
    },
    {
      enabled: !!brand_id,
    },
  );
};

export const useQueryCampaignUsecasesBrand = ({ brand_id }) => {
  return useQuery(
    ['brand', brand_id, 'campaign_use_case'],
    async () => {
      return tcrRequest('GET', `/campaigns/${brand_id}/usecases`);
      // return tcrRequest('campaign_usecases', { brand_id });
    },
    {
      enabled: !!brand_id,
    },
  );
};

export const useQueryCampaignMms = ({ campaign_id }) => {
  return useQuery(
    ['campaign', campaign_id, 'mms'],
    async () => {
      return tcrRequest('GET', `/campaigns/${campaign_id}/mms`);
    },
    {
      enabled: !!campaign_id,
    },
  );
};

export const useQueryCampaignSharingStatus = ({ campaign_id }) => {
  return useQuery(
    ['campaign', campaign_id, 'mms'],
    async () => {
      return tcrRequest('GET', `/campaigns/${campaign_id}/sharing`);
    },
    {
      enabled: !!campaign_id,
    },
  );
};

export const useCampaignSharingSet = () => {
  return useMutation(async data => {
    return tcrRequest(
      'PUT',
      // @ts-ignore
      `/campaigns/${data.campaign_id}/sharing/${data.upstream_cnp_id}`,
      data,
    );
  });
};

export const useCampaignCreate = () => {
  return useMutation(async data => {
    return tcrRequest('POST', '/campaigns', data);
  });
};

export const useCampaignAttachmentUpload = () => {
  return useMutation(async data => {
    return tcrRequest('POST', '/campaigns/attachment/upload', data);
  });
};

export const useCampaignAttachmentDelete = () => {
  // TODO: anyway of guaranteeing the attachment ID is owned by the Campaign/Brand?
  // - uuid, so assume No?
  return useMutation(async data => {
    return tcrRequest('DELETE', '/campaigns/attachment', data);
  });
};

export const tcrRequest = async (method, url, data?: any) => {
  // this needs to use the "/tproxy" trunkingio proxy, for the correct trunkingio_account_id and authorization for the request (without exposing auth_token)
  return tproxyRequest(
    method,
    `/api/accounts/____account_id____/messaging${url}`,
    data ? { data } : null, // note this is a "data" key! (ie "{data:{..}}" is all sent to trunkingio)
  );
};

export const baseTioApiAccountRequest = async (
  method,
  url,
  data?: any,
  rawData: boolean = false,
) => {
  // this needs to use the "/tproxy" trunkingio proxy, for the correct trunkingio_account_id and authorization for the request (without exposing auth_token)
  return tproxyRequest(
    method,
    `/api/accounts/____account_id____${url}`,
    data,
    rawData,
  );
};

export const baseTioApiAccountUrl = url => {
  return `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/accounts/____account_id____${url}`;
};

export const tproxyRequest = async (
  method,
  url,
  data?: any,
  rawData: boolean = false,
) => {
  // this needs to use the "/tproxy" trunkingio proxy, for the correct trunkingio_account_id and authorization for the request (without exposing auth_token)
  try {
    const newUrl = `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy${url}`;
    console.log('newUrl:', newUrl, { data, rawData });
    const response = await fetch(
      newUrl,
      // `localhost/api/accounts/acct123/messaging`,
      {
        method,
        headers: buildHeaders(!rawData),
        body: data ? (rawData ? data : JSON.stringify(data)) : undefined,
        // body: JSON.stringify({
        //   url: url,
        //   data,
        // }),
      },
    );
    const resp = await response.json();
    console.log('resp', resp);

    return resp;

    // return resp;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const buildHeaders = (contentTypeJson: boolean = true) => {
  const headers = new Headers();
  // @ts-ignore
  headers.set('X-AUTH-TOKEN', store.getState().auth.auth_token);
  // @ts-ignore
  headers.set('Authorization', store.getState().auth.auth_token); // used for tproxy
  if (contentTypeJson) {
    headers.set('Content-Type', 'application/json');
  }
  return headers;
};

const tcrFileUpload = async (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  const headers = new Headers();
  // @ts-ignore
  headers.set('X-AUTH-TOKEN', store.getState().auth.auth_token);
  return headers;

  try {
    const response = await fetch(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/tcr_file`,
      {
        method: 'POST',
        headers,
        body: formData,
      },
    );
    const resp = await response.json();
    console.log('resp', resp);

    return resp;

    // return resp;
  } catch (err) {
    console.error(err);
    return err;
  }
};
