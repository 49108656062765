import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  IconButton,
  Listbox,
  SquareProgress,
} from '../../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import {
  getAtPath,
  setAtPath,
  useToggleReducer,
} from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import {
  useListVmboxesQuery,
  useVmboxQuery,
} from '../../../../../../hooks/queries/vmbox';
import { useUpdateVmboxPartial } from '../../../../../../hooks/mutations/vmbox';
import { useHoverDirty } from 'react-use';
import { AudioPlayer } from '../../../../../../components/AudioPlayer';
import {
  Cancel,
  DeleteCircle,
  EditPencil,
  NavArrowDown,
  NavArrowUp,
  Plus,
  Trash,
  WarningTriangle,
} from 'iconoir-react';
import { GreetingVmbox } from '../../../../../admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { EditLineGreeting } from '../../../PipeView/components/Voicemail/Voicemail';
import { SettingsPanelLoader } from '../../../../../../design-lib/components/SettingsPanelLoader.tsx';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import AudioEditor from '../../../../../../components/AudioEditor/AudioEditor';
import { useBuilderContext } from '../../../../../../components/IvrBuilder/IvrBuilder';
import { INLINE_BLANK_CALLFLOW } from '../../../../../../components/IvrBuilder/Flow/nodes/InsertMenuOption/InsertMenuOption';
import { TIMEZONE_LIST } from '../../../../../../components/TimezoneDialogAccount/TimezoneDialogAccount';
import {
  deserializeValues,
  ScheduleNodeContent,
  ValuesDisplay,
} from '../../../../../../components/IvrBuilder/Flow/nodes/OptionSchedule/ScheduleNodeDialog';
import { format } from 'date-fns';
import { DaysOfWeek } from 'app/components/IvrBuilder/Flow/nodes/OptionSchedule/optionSchedule';
import { getScheduleLabel } from '../../../../../../components/IvrBuilder/Flow/nodes/OptionSchedule/optionSchedule';
import { useAccountQuery } from '../../../../../../hooks/queries/account';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormTextField } from '../../../../../../components/reactHookFormComponents/HookFormTextField';
import { NextModuleButton } from './NextModuleButton';

export const MenuOptionModule = () => {
  return (
    <>
      <div className={`flex flex-wrap justify-between`}>
        <NextModuleButton />
      </div>
    </>
  );
};
