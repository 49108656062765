import React, { useEffect, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { NextModuleButton } from './NextModuleButton';
import { useBuilderContext } from '../../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { ModifyMatchPopoverContent } from '../../../../../../components/MessageRoutingVisualBuilder/Flow/nodes/OptionMatchText/OptionMatchText';
import { setAtPath } from '../../../../../../utilities';
import { HookFormListbox } from '../../../../../../components/reactHookFormComponents/HookFormListbox';
import { HookFormTextField } from '../../../../../../components/reactHookFormComponents/HookFormTextField';
import { Listbox, TextField } from '../../../../../../design-lib';
import { useDebounce } from 'react-use';

export const StaticOptionModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const data = selectedElement.data;
  const { moduleItem, modifyPath, targetIdx } = selectedElement.data;

  const match =
    selectedElement.data?.matchTextData?.moduleItem?.data?.targets[targetIdx]
      ?.match;

  return (
    <>
      <div className={`flex flex-wrap mt-4 justify-between`}>
        <NextModuleButton />
      </div>
    </>
  );
};
