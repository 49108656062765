import { Alert, Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { Plus as AddIcon } from 'iconoir-react';
import React from 'react';
import {
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  Link,
  Chip,
  Divider,
} from 'app/design';
import { Link as RouterLink } from 'react-router-dom';

import { AddIntegrationButton } from 'app/components/AddIntegrationButton';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';
// import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import currency from 'currency.js';

import { sortBy } from 'lodash';

function Integrations({ account, customer, refetch }) {
  // const {
  //   toggleOpen: toggleOpenIntegrationAddDialog,
  //   Dialog: IntegrationAddDialog,
  //   DialogProps: IntegrationAddDialogProps,
  // } = useIntegrationAddDialog({ initialOpen: false });

  const handleOnComplete = () => {
    refetch();
    // toggleOpenIntegrationAddDialog();
  };

  return (
    <SettingsPanel
      leftChild={
        <Setting
          label={'Integrations'}
          body={'Active integrations for account.'}
        />
      }
      rightChild={
        <Table>
          <TableRow showBorder={false}>
            <TableCell className={`font-medium`}>Trunking.io</TableCell>
          </TableRow>
        </Table>
      }
    />
  );

  // return (
  //   <>
  //     <div>
  //       <span className={'text-lg font-bold'}>Integrations</span>
  //
  //       <Divider />
  //       <Grid
  //         container
  //         columnSpacing={1}
  //         alignItems="center"
  //         sx={{ padding: 1 }}
  //       >
  //         <Grid item md={3}>
  //           <span>TrunkingIO</span>
  //         </Grid>
  //         <Grid item md={9}>
  //           <span style={{ fontStyle: 'italic' }}>
  //             Billing via TrunkingIO is not yet available.
  //           </span>
  //         </Grid>
  //         {/* {account.doc.integrations?.trunkingio_account_id ? (
  //           <>
  //             <Grid item>
  //               <Typography variant="body2">Basic Plan</Typography>
  //             </Grid>
  //             <Grid item>
  //               <Button
  //                 color="info"
  //                 // variant="contained"
  //                 // onClick={toggleOpenIntegrationAddDialog}
  //               >
  //                 View Account
  //               </Button>
  //             </Grid>
  //           </>
  //         ) : (
  //           <>
  //             <Grid item>
  //               <AddIntegrationButton onComplete={handleOnComplete} />
  //             </Grid>
  //           </>
  //         )} */}
  //       </Grid>
  //       <Divider />
  //     </div>
  //   </>
  // );
}

export default Integrations;
