import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Sentry from '@sentry/react';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import React, { useState, useEffect, useRef } from 'react';

import { useQueryClient, useMutation } from 'react-query';
import { userQueryKeys } from 'app/hooks/queries/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useUserQuery } from 'app/hooks/queries/user';
import PersonIcon from '@mui/icons-material/Person';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { UserPrivLevel } from 'types/user';
import {
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
} from 'app/design';
import { Button } from 'app/design-lib';
import { toast } from 'react-toastify';
import {
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
  LocalPolice as LocalPoliceIcon,
  Build as BuildIcon,
} from 'app/design/icons-material';

import { store } from 'store';

import SimpleBar from 'simplebar-react';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';

import { useAuthSelector } from 'app/data/auth';
import ImgReducer from 'image-blob-reduce';
import constants from 'app/constants';

import {
  useBrandCreate,
  useTioMutation,
  useTioQuery,
} from 'app/pages/v2/common/sdk';

import COUNTRIES_OBJ from './countries.json';
import STATES_OBJ from './states.json';
import VERTICALS_OBJ from './verticals.json';
import STOCK_EXCHANGES_ARR from './stock_exchanges.json';

let COUNTRIES: any[] = [];
let US_STATES: any[] = [];
let VERTICALS: any[] = [];
const STOCK_EXCHANGES = STOCK_EXCHANGES_ARR.map(v => ({ label: v, value: v }));
Object.keys(COUNTRIES_OBJ).map(code =>
  COUNTRIES.push({ label: COUNTRIES_OBJ[code], value: code }),
);
COUNTRIES = COUNTRIES.sort((a, b) => a.label.localeCompare(b.label));
Object.keys(STATES_OBJ).map(code =>
  US_STATES.push({ label: STATES_OBJ[code], value: code }),
);
US_STATES = US_STATES.sort((a, b) => a.label.localeCompare(b.label));
Object.keys(VERTICALS_OBJ).map(code =>
  VERTICALS.push({
    label: VERTICALS_OBJ[code].displayName,
    description: VERTICALS_OBJ[code].description,
    value: code, //VERTICALS_OBJ[code].industryId,
  }),
);
VERTICALS = VERTICALS.sort((a, b) => a.label.localeCompare(b.label));

const schema = Joi.object({
  stockSymbol: Joi.alternatives().conditional('entityType', {
    is: 'PUBLIC_PROFIT',
    then: Joi.number().required(),
  }),
});

const BrandCreate = ({}) => {
  const { account_id, owner_id, auth_token } = useAuthSelector();
  // const createBrand = useBrandCreate();
  const createBrand = useTioMutation();
  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: {
      entityType: '',
      country: 'US',
      altBusinessIdType: 'NONE',
      altBusinessId: '',
      einIssuingCountry: 'US',
    },
    // resolver: joiResolver(schema),
  });
  const {
    watch,
    register,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const onSubmit = async () => {
    console.log('submitting');
    const data = getValues();
    console.log('data:', data);

    // @ts-ignore
    const resp = await createBrand.mutateAsync({
      method: 'PUT',
      url: `/messaging/brands`,
      data,
    });

    console.log('brand create resp:', resp);

    // TODO: handle "generic" error codes, such as 553 (unsupported email address)
    if (resp?.brandId) {
      // success!
      alert('Succeeded creating Brand!');
    } else {
      // expecting an array of failed values
      try {
        let generalErrors: any[] = [];
        for (let item of resp) {
          switch (item.code) {
            case 501:
              setError(item.field, {
                type: 'custom',
                message: item.description,
              });
              break;

            default:
              console.error('Invalid item error code:', item);
              generalErrors.push({ description: item.description });
              break;
          }
        }
        if (generalErrors.length) {
          // @ts-ignore
          setError(`generalErrors`, { type: 'custom', arr: generalErrors });
        }
      } catch (err) {
        // unknown error
        console.error('Failed Brand Create validation:', err);
      }
    }
    // console.log('resp:', resp);
  };

  const isSoleProprietor =
    watch('entityType') === 'SOLE_PROPRIETOR' ? true : false;
  const isPublicCompany =
    watch('entityType') === 'PUBLIC_PROFIT' ? true : false;

  console.log('ERRORS:', errors);
  // useEffect(() => {
  //   // clearErrors();
  // }, []);

  return (
    <>
      <div
        className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
      >
        <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
          <SimpleBar className={'h-full'} style={{ height: '100%' }}>
            <div className={'px-8 py-3 h-full'}>
              <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
                <div className={'flex  items-center space-x-2'}>
                  <div
                    className={
                      'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
                    }
                  >
                    <PersonIcon
                      className={'fill-content-accent '}
                      sx={{ fontSize: 'inherit' }}
                    />
                  </div>
                  <div>
                    <div className="font-bold text-xl">Brand Creation</div>
                    <div className="text-base text-neutral-500">
                      Your "Brand" is the corporate information about your
                      Company. Learn how/why this will be vetted for accuracy.
                      <br />
                      For most businesses, vetting is done immediately, and
                      after you setup a Campaign you will be able to send
                      SMS/MMS messages!
                    </div>
                  </div>
                </div>
              </div>
              <FormProvider {...methods}>
                <div className="p-3 flex flex-col mt-4 h-full">
                  <div className="mb-6 text-lg font-bold">Brand Details</div>
                  {/* @ts-ignore */}
                  {errors?.generalErrors?.arr ? (
                    <div className="p-4 mb-4 rounded bg-red-100/20">
                      <div className="font-bold text-lg">Errors</div>
                      {/* @ts-ignore */}
                      {errors?.generalErrors?.arr?.map((err, i) => (
                        <div key={i} className="text-base">
                          {err.description}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div className="space-y-6 ">
                    <Select
                      field="entityType"
                      title="What type of legal form is your organization?"
                      options={[
                        { value: 'PRIVATE_PROFIT', label: 'Private Company' },
                        {
                          value: 'SOLE_PROPRIETOR',
                          label: 'Sole Proprietor',
                          disabled: true,
                        },
                        {
                          value: 'NON_PROFIT',
                          label: 'Non-Profit Organization',
                        },
                        {
                          value: 'PUBLIC_PROFIT',
                          label: 'Publicly Traded Company',
                        },
                        { value: 'GOVERNMENT', label: 'Government' },
                      ]}
                    />

                    {!isSoleProprietor ? (
                      <TextField
                        field="companyName"
                        title="Legal Company Name"
                      />
                    ) : null}
                    <TextField
                      field="displayName"
                      title="DBA or Brand Name, if different from Legal Name"
                      // disabled={isSoleProprietor}
                    />

                    <Select
                      field="country"
                      title="Country of Registration"
                      options={COUNTRIES}
                      disabled
                    />

                    <TextField
                      field="ein"
                      title="Tax Number/ID/EIN (depends on Country)"
                      disabled={isSoleProprietor}
                    />
                    <Select
                      field="einIssuingCountry"
                      title="EIN Issuing Country"
                      options={COUNTRIES}
                      disabled={isSoleProprietor}
                    />
                    <Select
                      field="altBusinessIdType"
                      title="Additional Businesss ID Type"
                      options={[
                        {
                          label: '',
                          value: 'NONE',
                        },
                        {
                          label: 'DUNS',
                          value: 'DUNS',
                        },
                        {
                          label: 'GIIN',
                          value: 'GIIN',
                        },
                        ,
                        {
                          label: 'LEI',
                          value: 'LEI',
                        },
                      ]}
                      disabled={isSoleProprietor}
                    />
                    <TextField
                      field="altBusinessId"
                      title={`${
                        watch('altBusinessIdType') !== 'NONE'
                          ? watch('altBusinessIdType')
                          : 'DUNS or GIIN or LEI'
                      } Number`}
                      disabled={
                        !watch('altBusinessIdType')?.length || isSoleProprietor
                      }
                    />
                    <TextField field="street" title={`Address/Street`} />
                    <TextField field="city" title={`City`} />
                    <Select field="state" title="State" options={US_STATES} />
                    <TextField field="postalCode" title={`Zipcode`} />
                    <TextField field="website" title={`Website`} />
                    <Select
                      field="vertical"
                      title="Vertical"
                      options={VERTICALS}
                    />

                    {isPublicCompany ? (
                      <>
                        <Select
                          field="stockExchange"
                          title="Stock Exchange"
                          options={STOCK_EXCHANGES}
                          disabled={!isPublicCompany}
                        />
                        <TextField
                          field="stockSymbol"
                          title={`Stock Symbol`}
                          disabled={!isPublicCompany}
                        />
                      </>
                    ) : null}

                    {isSoleProprietor ? (
                      <>
                        <TextField field="firstName" title={`First Name`} />
                        <TextField field="lastName" title={`Last Name`} />
                        <TextField
                          field="mobilePhone"
                          title={`Mobile Phone`}
                          hint="This is only used if you are a Sole Proprietor. It will be used for the SMS verification step."
                        />
                      </>
                    ) : null}
                  </div>

                  <div className="mt-8 mb-6 text-lg font-bold">
                    Contact Details
                  </div>

                  <div className="space-y-6">
                    <TextField field="phone" title={`Support Phone Number`} />
                    <TextField field="email" title={`Support Email Address`} />

                    <div>
                      <Button
                        variant={'outline'}
                        color={'positive'}
                        onClick={() => {
                          clearErrors();
                          methods.handleSubmit(onSubmit)();
                        }}
                      >
                        {createBrand.isLoading
                          ? 'please wait...'
                          : 'Create Brand'}
                      </Button>
                    </div>
                  </div>
                </div>
              </FormProvider>
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  );
};

interface SelectProps {
  field: string;
  title: any;
  // hint: any;
  options?: any;
  placeholder?: any;
  disabled?: boolean;
}
const Select = ({
  field,
  title,
  placeholder,
  options,
  disabled = false,
}: SelectProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="">
      <div className={'text-sm font-bold'}>{title}</div>
      <select disabled={disabled} {...register(field)}>
        {options.map(opt => (
          <option key={opt.value} value={opt.value} disabled={opt.disabled}>
            {opt.label}
          </option>
        ))}
      </select>
      {errors[field] && (
        <p className="text-red-500">{errors[field]?.message}</p>
      )}
    </div>
  );
};

interface TextFieldProps {
  field: string;
  title: any;
  hint?: any;
  placeholder?: any;
  disabled?: boolean;
}
const TextField = ({
  field,
  title,
  hint = null,
  placeholder = '',
  disabled = false,
}: TextFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="">
      <div className={'text-sm font-bold'}>{title}</div>
      <input
        className={
          'rounded text-base border-solid border border-border-neutral py-1.5 px-3 font-sans'
        }
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        {...register(field)}
      />
      {hint !== null ? (
        <p className="text-sm text-neutral-600">{hint}</p>
      ) : null}
      {errors[field] && (
        <p className="text-red-500">Error: {errors[field]?.message}</p>
      )}
    </div>
  );
};

export default BrandCreate;
