import PersonIcon from '@mui/icons-material/Person';
import { Loading } from 'app/components/Sidebar2/components/Loading';
import {
  RouteMatchComponent,
  UserAvatar,
} from 'app/components/Sidebar2/Sidebar2';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { usePNContext } from 'app/utilities/pubnub';
import { Plus, Settings } from 'iconoir-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { store } from 'store';
import { User } from 'types/user';
import { UnreadBadge } from 'app/components/Sidebar2/components/LinesDisplay/LinesDisplay';
import { AvatarGroup, IconButton, SquareProgress } from 'app/design-lib';
import { ParticipantName } from 'app/pages/v2/pipe/components/ParticipantName';
import {
  usePipeConvosQuery,
  usePipeConvoUsersQuery,
  usePipeConvoUpsert,
  usePipeMessagesQuery,
  usePipesQuery,
} from 'app/pages/v2/common/sdk';

interface DirectMessagesProps {
  user: User;
}

const DirectMessages = ({ user }: DirectMessagesProps) => {
  const pipesQuery = usePipesQuery({
    filters: {
      // users: {
      //   path: [store.getState().auth.owner_id, 'enabled'],
      //   equals: true,
      // },
      // type: 'personal',
      key: user.doc.presence_id,
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];
  const personalPipe = pipes?.length ? pipes[0] : null;

  return (
    <div
      className={
        'mt-2 flex-[2] overflow-hidden text-md w-full flex flex-col justify-between items-center'
      }
    >
      <div className={'px-4 flex items-center justify-between w-full py-1'}>
        <Link to={`/pipe/${personalPipe?.id}`} className={'text-sm'}>
          Direct messages
        </Link>
        {/* <IconButton
          size={'sm'}
          variant={'ghost'}
          component={Link}
          to={`/pipe/${personalPipe?.id}?auto_focus=1`}
        >
          <Plus fr={undefined} />
        </IconButton>*/}
      </div>
      <div className={'flex-grow w-full overflow-hidden'}>
        <MessageThreads user={user} />
      </div>
    </div>
  );
};

const MessageThreads = ({ user }: DirectMessagesProps) => {
  // Find team members
  const {
    data: usersPage,
    isLoading: usersLoading,
    // refetch,
  } = useListUsersQuery({
    take: 500,
    filters: {},
    orderBy: [['presence_id', 'asc']],
    options: {},
    quick: true,
  });

  const {
    // @ts-ignore
    users: { users = [] },
  } = usersPage ?? { users: {} };
  // console.log('users:', users);

  // Find my Personal Pipe/Line for my extension
  const pipesQuery = usePipesQuery({
    filters: {
      // users: {
      //   path: [store.getState().auth.owner_id, 'enabled'],
      //   equals: true,
      // },
      // type: 'personal',
      key: user.doc.presence_id,
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];
  const personalPipe = pipes?.length ? pipes[0] : null;

  // Get convos for that Personal Line
  // - match convos to users/team (by presence_id!)
  const pipeConvosQuery = usePipeConvosQuery(
    {
      filters: {
        pipe_id: personalPipe?.id,
      },
      // key: pipe_key,
    },
    personalPipe ? true : false,
  );
  const pipeConvos = pipeConvosQuery.data?.pipeConvos ?? [];

  const [orderedConvos, orderedConvosSet] = useState([]);

  useEffect(() => {
    let orderedConvos: any = [];
    const user_id = store.getState().auth.owner_id;

    pipeConvos.forEach(convo => {
      // filter out "internal" convo!
      if (convo.key !== 'internal' && convo.key.length) {
        orderedConvos.push({
          convo,
          users: users.filter(user =>
            convo.participants.includes(user.doc.presence_id),
          ),
        });
      }
    });
    // console.log('pipeConvos:', pipeConvos);
    // console.log('ordered convos', orderedConvos);

    // add each user as well
    // - for one-click creation of the conversation, instead of instant-redirect
    for (let user of users) {
      if (
        // find where has 1 user, who is our user
        // - could also check by presence_id? of the user, matching participant?
        !orderedConvos.find(
          c => c.users.length === 1 && c.users[0].id === user.id,
        )
      ) {
        orderedConvos.push({
          convo: null,
          users: [user],
        });
      }
    }

    orderedConvosSet(orderedConvos);

    // const user_id = store.getState().auth.owner_id;
    // const me = users.find(u => u.id === user_id);
    // const rest = users.filter(u => u.id !== user_id);
    // if (me) {
    //   // orderedUsersSet([me, ...rest])
    //   // @ts-ignore
    //   let orderedWithConvos: any = [];
    //   // if (pipeConvos) {
    //   for (let user of [me, ...rest] as any[]) {
    //     let convo = pipeConvos.find(c => c.key === user.doc.presence_id);
    //     orderedWithConvos.push({
    //       user,
    //       convo,
    //     });
    //   }
    // console.log('orderedWithConvos:', { orderedWithConvos, pipeConvos });
    // orderedConvosSet(orderedWithConvos);
    // orderedUsersSet([]);
    // }
    // }
    // @ts-ignore
  }, [usersPage?.users?.users, pipeConvosQuery.data]);

  // useEffect(() => {
  //   console.log('pipeConvos updated in sidebar', pipeConvosQuery.data);
  // }, [pipeConvosQuery.data]);

  // if (!orderedUsers) {
  //   // return null;
  // }

  if (pipesQuery.isLoading || pipeConvosQuery.isLoading || usersLoading) {
    return (
      <div className={`w-full mt-4 h-full grid place-items-center`}>
        <SquareProgress size={'sm'} />
      </div>
    );
  }

  if (!orderedConvos.length) {
    return (
      <div className={'h-full grid place-items-center'}>
        <span className={'font-medium opacity-50'}>No Direct Messages</span>
      </div>
    );
  }

  if (!personalPipe) {
    return null;
  }

  return (
    // was Simplebar
    <div className={'w-full'} data-tour-sidebar-team>
      {orderedConvos.map((info: any) => (
        // @ts-ignore
        <RouteMatchComponent
          key={info.convo?.id ?? info.users[0].id}
          to={
            info.convo
              ? `/pipe/${personalPipe?.id}/convo/${info.convo.key}`
              : '/nomatch'
          }
        >
          {({ active, nav, href }) => (
            <DirectMessage
              key={info.convo?.id ?? info.users[0].id}
              convo={info.convo}
              pipe_id={personalPipe?.id}
              participants={info.users.map(u => u.doc.presence_id)}
              info={info}
              active={active}
              href={href}
              currentUser={users.find(
                user => user.id === store.getState().auth.owner_id,
              )}
            />
          )}
        </RouteMatchComponent>
      ))}
    </div>
  );

  // return (
  //   <div className="mt-6" data-tour-sidebar-team>
  //     <div className="text-md font-bold flex space-x-1 items-center p-2">
  //       <PersonIcon
  //         className={'fill-content-accent -mt-[2px]'}
  //         sx={{ fontSize: 'inherit' }}
  //       />
  //       <span>Team</span>
  //     </div>
  //     <div className="">
  //       {orderedUsers.map((info: any) => (
  //         // @ts-ignore
  //         <RouteMatchComponent
  //           key={info.user.id}
  //           to={`/v2/pipe/${personalPipe?.id}/convo/${info.user.doc.presence_id}`}
  //         >
  //           {({ active, nav, href }) => (
  //             <Link
  //               className={`block flex items-center no-underline justify-between hover:bg-background-accent-secondary-hover/30 cursor-pointer text-neutral-800 p-2 ${
  //                 active ? 'bg-background-accent-secondary-active/30' : ''
  //               }`}
  //               to={href}
  //             >
  //               <div className="text-sm font-semibold ">
  //                 {/* @ts-ignore */}
  //                 <UserName
  //                   showTitle={false}
  //                   owner_id={info.user.id}
  //                   size={'sm'}
  //                 />
  //               </div>
  //               {info.convo ? (
  //                 <div className="">
  //                   {info.convo.metadata?.unread ? (
  //                     <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
  //                       {info.convo.metadata.unread}
  //                     </span>
  //                   ) : null}
  //                 </div>
  //               ) : null}
  //             </Link>
  //           )}
  //         </RouteMatchComponent>
  //       ))}
  //     </div>
  //   </div>
  // );
};

const DirectMessage = ({
  info,
  active,
  href,
  currentUser,
  pipe_id,
  convo,
  participants,
}: {
  info: any;
  active: boolean;
  href: string;
  currentUser: User;
  pipe_id?: any;
  convo?: any;
  participants?: any;
}) => {
  const pipeConvoUpsert = usePipeConvoUpsert();

  const history = useHistory();
  const handleCreateConvo = async e => {
    e.preventDefault();
    e.stopPropagation();
    const convo = await pipeConvoUpsert.mutateAsync({
      data: { pipe_id, participants },
    });
    // console.log('CONVO:', convo);
    if (convo.key) {
      history.push(`/pipe/${pipe_id}/convo/${convo.key}`);
    } else {
      alert(
        'Sorry, failed with participants. Please try again, or contact support',
      );
    }
  };

  // console.log('info:', info);
  return (
    <Link
      className={`flex w-full items-center space-x-2 py-2 px-4 cursor-pointer hover:bg-gray-10 ${
        active ? 'bg-gray-20' : ''
      }`}
      // @ts-ignore
      to={convo ? href : ''}
      // @ts-ignore
      onClick={!convo ? handleCreateConvo : undefined}
    >
      <div className={`flex w-full items-center justify-between  `}>
        {info.users.length > 1 ? (
          <MultiUserConvo {...info} />
        ) : (
          <SingleUserConvo {...info} currentUser={currentUser} />
        )}

        {/*<div className="text-sm font-semibold ">*/}
        {/*  /!* @ts-ignore *!/*/}
        {/*  <UserName*/}
        {/*    showTitle={false}*/}
        {/*    owner_id={info.user.id}*/}
        {/*    size={'sm'}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*{info.convo ? (*/}
        {/*  <div className="">*/}
        {/*    {info.convo.metadata?.unread ? (*/}
        {/*      <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">*/}
        {/*        {info.convo.metadata.unread}*/}
        {/*      </span>*/}
        {/*    ) : null}*/}
        {/*  </div>*/}
        {/*) : null}*/}
        {info.convo?.metadata?.unread ? (
          <UnreadBadge
            isActive={active}
            count={info.convo.metadata?.unread}
            word="message"
          />
        ) : null}
      </div>
    </Link>
  );
};

const MultiUserConvo = ({ users, convo }) => {
  return (
    <div className={`flex w-[80%] space-x-2 items-center`}>
      <AvatarGroup size={'md'} condensed>
        {users.map(user => (
          <UserAvatar key={user.id} user={user} />
        ))}
      </AvatarGroup>
      <div className={`inline-block w-full whitespace-nowrap overflow-hidden`}>
        <p className={'text-md font-medium truncate'}>
          {users.map(user => user.extra.fullName).join(', ')}
        </p>
      </div>
    </div>
  );
};

const SingleUserConvo = ({ users, convo, currentUser }) => {
  const [user] = users.length ? users : [currentUser];

  // @ts-ignore
  const { onlineUsers } = usePNContext();
  const isOnline = onlineUsers?.[user?.id] ? true : false;

  // console.log('Participants:', convo);
  if (convo?.participants) {
    return <ParticipantName value={convo.participants[0]} />;
  }

  return (
    <div className={`flex w-full space-x-2 items-center`}>
      <UserAvatar user={user} size={'md'} enabled={isOnline} />
      <div className={`inline-block w-[70%] whitespace-nowrap overflow-hidden`}>
        <p className={'text-md font-medium truncate'}>{user.extra.fullName}</p>
      </div>
    </div>
  );
};

export default DirectMessages;
