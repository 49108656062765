import {
  Dialog,
  DialogContent,
  DialogHeader,
  IconButton,
} from '../../../../../../design-lib';
import { InfoEmpty } from 'iconoir-react';
import React, { ReactNode, useState } from 'react';
import { useToggleReducer } from '../../../../../../utilities';
import { useCallRouteEditor } from '../CallflowContext';
import { RingLinesUsersModule } from './RingLinesUsersModule';
import { RingGroupModule } from './RingGroupModule';
import { RingUserModule } from './RingUserModule';
import { RingDeviceModule } from './RingDeviceModule';
import { PlayAudioModule } from './PlayAudioModule';
import { SendMessageModule } from './SendMessageModule';
import { MenuModule } from './MenuModule';
import { ScheduleModule } from './ScheduleModule';
import { VmboxModule } from './VmboxModule';
import { TransferModule } from './TransferModule';
import { JumpToModule } from './JumpToModule';
import { HangupModule } from './HangupModule';
import modeSelectModule from '../../../../../../components/IvrBuilder/Flow/nodes/common/ModeSelectModule/ModeSelectModule';
import { MenuOptionModule } from './MenuOptionModule';
import { ScheduleOptionModule } from './ScheduleOptionModule';

export const ModuleInfo = () => {
  const [open, setOpen] = useState(false);
  const { selectedElement } = useCallRouteEditor();
  // console.log('selected element', selectedElement);
  if (!selectedElement) return null;

  let title: string;
  let content: ReactNode;

  switch (selectedElement.type) {
    case 'OptionNode':
      switch (selectedElement.data.targetKey) {
        case '_':
          title = 'Invalid Entry Module';
          content = (
            <>
              <p>
                This route will be activated if the caller dials an invalid
                entry (key press) three times in a row.
              </p>
            </>
          );
          break;
        case 'timeout':
          title = 'Menu Timeout Module';
          content = (
            <>
              <p>
                This route will be activated if the caller does not respond with
                an input to the menu after 10 seconds.
              </p>
            </>
          );
          break;
        default:
          title = `Dial ${selectedElement.data.targetKey} Module`;
          content = (
            <>
              <p>
                This route will be activated if the caller dials{' '}
                {selectedElement.data.targetKey} in response to the menu.
              </p>
            </>
          );
          break;
      }

      break;
    case 'OptionScheduleNode':
      if (selectedElement.data.category.id === 'fallback') {
        title = 'Schedule Fallback Module';
        content = (
          <>
            <p>
              This route will be activated if none of the schedule's other rules
              match.
            </p>
          </>
        );
      } else {
        title = 'Schedule Route Module';
        content = (
          <>
            <p>
              This route will be activated when the date and time of the call
              matches one or more of the routes temporal rules.
            </p>
          </>
        );
      }
      break;
    default:
      const module = selectedElement?.data.moduleItem;
      switch (module?.type) {
        case 'Ring':
          switch (module.data.ring_type) {
            case 'line':
              title = `Ring Line's Users Module`;
              content = (
                <>
                  <p>
                    Use this module to ring the all the users assigned to the
                    specified line.
                  </p>
                  <p>
                    If no one answers the call or all of their devices are
                    offline, the call will proceed to the next module.
                  </p>
                </>
              );
              break;
            case 'group':
              title = 'Ring User Module';
              content = (
                <>
                  <p>Use this module to ring the specified Ring Group.</p>
                  <p>
                    This module will ring the devices and users assigned to the
                    Ring Group following the configured ring strategy.
                  </p>
                  <p>
                    If no one answers the call or all of the devices in the
                    group are offline, the call will proceed to the next module.
                  </p>
                </>
              );
              break;
            case 'user':
              title = 'Ring User Module';
              content = (
                <>
                  <p>Use this module to ring a specific member on your team.</p>
                  <p>
                    This module will ring all of the selected user's devices. If
                    the user does not pick up or all of their devices are
                    offline, the call will proceed to the next module.
                  </p>
                </>
              );
              break;
            case 'device':
              title = 'Ring Device Module';
              content = (
                <>
                  <p>Use this module to ring an individual device.</p>
                  <p>
                    If this device is offline or no one picks up, the call will
                    proceed to the next module.
                  </p>
                </>
              );
              break;
            default:
              title = 'Unknown Module';
              content = null;
          }
          break;
        case 'PlayAudio':
          title = 'Play Audio Module';
          content = (
            <>
              <p>
                Use this module to play a selected media file to the caller.
              </p>
              <p>
                Options include text-to-speech, recorded audio or a user
                uploaded file.
              </p>
            </>
          );
          break;
        case 'Message':
          title = 'Send Message Module';
          content = (
            <>
              <p>
                Use this module to send an automated SMS message to the caller.
              </p>
            </>
          );
          break;
        case 'Menu':
          title = 'Menu Module';
          content = (
            <>
              <p>
                Use this module to present callers with different options to
                direct their call.
              </p>
            </>
          );
          break;
        case 'Schedule':
          title = 'Schedule Module';
          content = (
            <>
              <p>
                Use this module to route callers to different options based on
                the time or day they call.
              </p>
            </>
          );
          break;
        case 'VoicemailBox':
          title = 'Voicemail Module';
          content = (
            <>
              <p>Use this module to direct the caller to voicemail.</p>
            </>
          );
          break;
        case 'Transfer':
          title = 'Transfer Module';
          content = (
            <>
              <p>Use this module to transfer the caller to another line.</p>
            </>
          );
          break;
        case 'GoToInFlow':
          title = 'Jump To Module';
          content = (
            <>
              <p>
                Use this module to jump to another location in this call route.
              </p>
            </>
          );
          break;
        case 'Hangup':
          title = 'Hangup Module';
          content = (
            <>
              <p>Use this module to terminate the call.</p>
            </>
          );
          break;
        default:
          title = 'Unknown Module';
          content = null;
      }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogHeader title={title} onClose={() => setOpen(false)} />
        <DialogContent className={`flex text-md flex-col space-y-4`}>
          {content}
        </DialogContent>
      </Dialog>
      <IconButton size={'md'} onClick={() => setOpen(true)} variant={'ghost'}>
        <InfoEmpty fr={undefined} />
      </IconButton>
    </>
  );
};
