import React, { useEffect, useState } from 'react';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { Typography, CircularProgress } from 'app/design';
import { sdk } from 'app/sdk';
import { isArray } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { store } from 'store';
import { useAccountQuery } from 'app/hooks/queries/account';

import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';

const PhoneNumber = () => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isValid },
    setValue,
    setError,
    clearErrors,
    watch,
    control,
  } = useFormContext();

  const [isSearching, setSearching] = useState(false);

  const {
    data: account,
    refetch: refetchAccount,
    isSuccess,
  } = useAccountQuery({});
  const accountBillingMutate = useAccountStripeCustomerMutate();

  const prefix = watch('prefix');
  const ptn = watch('ptn');
  const address = watch('address');

  // console.log('formState:', formState);
  // console.log('isValid:', isValid, formErrors);
  // console.log('ptn:', ptn);

  const search = async prefix => {
    // note: the api response is different depending on if you include the “prefix” query param
    // - (and “quantity” is ignored if “prefix” is not included)
    // const prefix = getValue('prefix');
    if (!account) {
      console.log('not searching, no account');
      return;
    }

    console.log('trunkingio: searching');

    setSearching(true);
    if (!account.internal?.integrations?.trunkingio?.account_id) {
      try {
        await accountBillingMutate.mutateAsync({
          data: {
            action: 'addIntegration',
            data: {
              // name: ccForm.name,
              // source: token?.id,
              // // @ts-ignore
              // setDefault: formDefaults?.setDefault ? true : false,
            },
          },
        });
      } catch (e: any) {
        setError('ptn', {
          type: 'error',
          message: 'Error creating trunkingio account',
        });
        console.error('failed creating trunkingio account', e);
        setSearching(false);
        return;
      }
      await refetchAccount();
    } else {
      console.log('trunkingio: account exists');
    }

    // - TODO: search by IP, if city/state do not exist

    const area_code_results = await sdk.phoneNumber.query.searchTrunkingio(
      {
        city: address?.city,
        state: address?.state,
        additionalAreaCodes: [
          '628', // sf
          '929', // nyc
          '872', // chicago
        ],
      }, // { prefix, quantity: 1 },
      { auth_token: store.getState().auth.auth_token },
    );
    console.log('searchTrunkingio RESULT:', area_code_results.data);
    const area_codes = Object.keys(area_code_results.data);
    let num;
    for (let area_code of area_codes) {
      let nums = Object.keys(area_code_results.data[area_code]);
      console.log('area code numbers:', nums);
      if (nums?.length) {
        num = nums[0];
        break;
      }
    }
    if (!num) {
      // unable to find a number!
      setError('ptn', {
        type: 'error',
        message: 'Sorry, unable to find an available number near you',
      });
      setSearching(false);
      return;
    }

    setValue('ptn', num, { shouldDirty: true, shouldValidate: true });
    setSearching(false);
    // console.log('SDK PTN Response:', v);
    // let ptns;
    // if (isArray(v.data)) {
    //   ptns = v.data.map(num => num.number);
    // } else {
    //   ptns = Object.keys(v.data?.numbers ?? {});
    // }
    // if (!ptns.length) {
    //   // alert('No phone numbers found');
    //   // setValue('ptn', '+12125554444', { shouldDirty: true });
    //   setError('ptn', {
    //     type: 'error',
    //     message: 'No Numbers found',
    //   });
    // } else {
    //   setValue('ptn', ptns[0], { shouldDirty: true, shouldValidate: true });
    // }
  };

  useEffect(() => {
    // // setValue('ptn', '+12125554444', { shouldDirty: true });
    if (ptn || !isSuccess) {
      return;
    }
    // if (!prefix) {
    //   setValue('ptn', null, { shouldDirty: true });
    //   return;
    // }
    // if (prefix.length !== 3) {
    //   setError('ptn', {
    //     type: 'error',
    //     message: '3 digits needed',
    //   });
    //   setValue('ptn', null, { shouldDirty: true });
    //   return;
    // }
    clearErrors();
    search(prefix);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix, ptn, isSuccess]);

  return (
    <>
      {formErrors?.ptn ? (
        <div className="text-red-500">{formErrors?.ptn.message}</div>
      ) : !ptn?.length ? (
        <div className="text-base py-4 text-center">
          <CircularProgress size={44} />
          <br />
          <br />
          Searching available numbers...
          <br />
          <br />
        </div>
      ) : (
        <PhoneNumberDisplay width="auto" ptn={ptn} />
      )}
    </>
  );
};

export default PhoneNumber;
