import { IvrBuilder } from 'app/components/IvrBuilder';
import { IvrInline } from 'app/components/IvrInline';
import {
  CallRoutingInline,
  CallRoutingAccount,
} from 'app/components/CallRoutingDialog';
import constants from 'app/constants';
import { useNavDetails } from 'app/hooks/general/useNavDetails';
import { useAccountQuery } from 'app/hooks/queries/account';
import {
  useCallflowQuery,
  useListCallflowsQuery,
} from 'app/hooks/queries/callflow';
import { parseGraphqlErrorCode } from 'app/utilities';
import { useParams } from 'react-router-dom';

import React from 'react';
import { IconButton, Listbox, SquareProgress, TextField } from 'app/design-lib';
import { usePipesQuery } from 'app/pages/v2/common/sdk';
import {
  Community,
  Dialpad,
  NetworkAlt,
  PhoneIncome,
  Number1Square,
  User,
  Voice,
  NavArrowDown,
  TextBox,
  Plus,
  NavArrowRight,
} from 'iconoir-react';
import { Divider } from '../../../../design-lib/mobile-components';
import IconButtonDropdown from '../../../../design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { UserAvatar } from '../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../components/PhoneNumberDisplay';
import { isMobile } from '@headlessui/react/dist/utils/platform';
import { useMediaQuery } from '@mui/material';
import { MobileView } from './MobileView/MobileView';

function PipeEditCallRouting() {
  // @ts-ignore
  const { callflow_id, pipe_id } = useParams();

  const pipesQuery = usePipesQuery(
    { filters: { id: pipe_id } },
    pipe_id ? true : false,
  );
  const pipes = pipesQuery.data?.pipes ?? [];
  const pipe = pipes?.length ? pipes[0] : null;

  const {
    data: callflowsPageResponse,
    isLoading: callflowsAreLoading,
    isFetching: callflowsFetching,
    refetch: refetchDevices,
    error: callFlowError,
    status,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['id', '=', callflow_id],
              // ['type', '=', 'general'],
            ],
          },
        },
      ],
    },
    options: { enabled: !!callflow_id },
  });
  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  const [callflow] = callflowsPageResponse?.callflows ?? [];

  // useNavDetails({
  //   sidebar_key: constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key,
  //   crumbs: () => [
  //     constants.nav.ADMIN.crumb,
  //     // constants.nav.ADMIN_DASHBOARD.crumb,
  //     constants.nav.ADMIN_ACCOUNT_CALLING.crumb,
  //   ],
  // });

  if (callflowsAreLoading || pipesQuery.isLoading) {
    return (
      <div
        className={`w-full h-screen bg-neutral-10/30 grid place-items-center`}
      >
        <div className={`flex flex-col justify-center items-center`}>
          <SquareProgress />
          <span className={`text-md font-medium`}>Loading call route...</span>
        </div>
      </div>
    );
  }

  if (callFlowError) {
    const error = callFlowError; // ? callFlowError : accountError;

    // @ts-ignore
    const code = parseGraphqlErrorCode(error) ?? error?.message;

    return (
      <div
        className={`w-full h-screen bg-neutral-10/30 grid place-items-center`}
      >
        <div className={`flex flex-col justify-center items-center`}>
          <span className={`text-md text-negative-60 font-medium`}>
            Error loading call route.
          </span>
          <span className={`text-md`}>{code}</span>
        </div>
      </div>
    );
  }

  // return (
  //   <CallRoutingAccount
  //     name_type="account"
  //     owner_type="account"
  //     type="main"
  //     resourceId={account?.id}
  //     defaultNumbers={[]}
  //     callflow={callflow}
  //     onComplete={() => {
  //       // toggleOpenCallRoutingDialog();
  //       // refetch();
  //     }}
  //   />
  // );

  if (!callflow) {
    return (
      <div
        className={`w-full h-screen bg-neutral-10/30 grid place-items-center`}
      >
        <div className={`flex flex-col justify-center items-center`}>
          <span className={`text-md text-negative-60 font-medium`}>
            Error: Invalid call route
          </span>
          <span className={`text-md`}>
            ID does not match existing call route.
          </span>
        </div>
      </div>
    );
  }

  if (!pipe) {
    return (
      <div
        className={`w-full h-screen bg-neutral-10/30 grid place-items-center`}
      >
        <div className={`flex flex-col justify-center items-center`}>
          <span className={`text-md text-negative-60 font-medium`}>
            Error: Invalid line
          </span>
          <span className={`text-md`}>ID does not match existing line.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col h-full md:h-screen bg-neutral-10/30 ">
      <IvrBuilder
        miniMap={isMobile}
        key={callflow.id}
        callflow={callflow}
        pipe={pipe}
      />
    </div>
  );
}

export default PipeEditCallRouting;
