import AudioEditor from 'app/components/AudioEditor/AudioEditor';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { EditGreetingDialogVmbox } from 'app/components/EditGreetingDialogVmbox';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { BUILT_IN_AUDIO } from 'app/components/MediaDialog/components/BuiltInAudio/BUILT_IN_AUDIO';
import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { Alert, Setting, Table } from 'app/design-lib';
import { SettingsPanelLoader } from 'app/design-lib/components/SettingsPanelLoader.tsx';
import { useUpdateAccountPartial } from 'app/hooks/mutations/account';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { GreetingVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import { AddUser, Cancel, EditPencil, WarningTriangle } from 'iconoir-react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { useCreateVmbox } from 'app/hooks/mutations/vmbox/useCreateVmbox';
import { useListVmboxesQuery } from 'app/hooks/queries/vmbox';

function Voicemail({ pipe, refetch }) {
  // shows the vmboxes for the vmbox_ids of the pipe

  const queryClient = useQueryClient();
  const createVmbox = useCreateVmbox();

  const {
    data: vmboxesPage,
    isLoading: vmboxesLoading,
    refetch: vmboxesRefetch,
  } = useListVmboxesQuery({
    take: 500,
    filters: {
      // ids: pipe.vmboxes,
      // context: {
      //   ownerIds: pipe.id, // TODO: use pipe_id instead of owner_id ?
      // },
      raw: [
        {
          variables: {
            docFilters: [['pipe_id', '=', pipe.id]],
          },
        },
      ],
    },
    options: {},
  });
  const vmboxes = vmboxesPage?.vmboxes ?? [];

  const handleCreate = async () => {
    await createVmbox.mutateAsync({
      pin: undefined,
      require_pin: false,
      announcement_only: false,
      include_message_on_notify: true,
      include_transcription_on_notify: true,
      is_setup: true,
      is_voicemail_ff_rw_enabled: false,
      check_if_owner: true,
      delete_after_notify: false,
      save_after_notify: false,
      skip_envelope: false,
      skip_greeting: false,
      skip_instructions: false,
      transcribe: false,
      transcribe2: true,
      notify_email_addresses: [],
      oldest_message_first: false,
      // ...vmboxForm,
      mailbox: pipe.key,
      name: `Pipe Vmbox`,
      // @ts-ignore
      pipe_id: pipe.id,
    });

    // console.log('pipeUpdateById mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    vmboxesRefetch();
  };
  const [edit, toggleEdit] = useToggleReducer();
  const [vmbox] = vmboxes;
  const mediaId = vmbox?.doc?.media?.unavailable;
  return (
    <SettingsPanel
      id="voicemail"
      leftChild={
        <Setting
          label={'Voicemail greeting'}
          body={
            "Media that will be played when a caller reaches this line's voicemail."
          }
          iconButtonProps={
            edit
              ? undefined
              : {
                  children: <EditPencil fr={undefined} />,
                  onClick: toggleEdit,
                }
          }
          buttonProps={
            edit
              ? {
                  color: 'neutral',
                  startIcon: <Cancel fr={undefined} />,
                  children: 'Cancel',

                  onClick: toggleEdit,
                }
              : undefined
          }
        />
      }
      rightChild={
        vmboxesLoading ? (
          <SettingsPanelLoader label={'Loading voicemail box...'} />
        ) : edit ? (
          <EditLineGreeting
            onCancel={toggleEdit}
            vmbox={vmbox}
            mediaId={mediaId}
            onComplete={() => {
              refetch();
              toggleEdit();
            }}
          />
        ) : vmbox ? (
          mediaId ? (
            <AudioPlayer mediaId={mediaId} />
          ) : (
            <Alert
              reverse
              color={'neutral'}
              label={'No greeting'}
              body={
                <div className={`flex flex-col space-y-2 pr-2`}>
                  <span>
                    Customize your voicemail greeting to help your business
                    standout.
                  </span>
                </div>
              }
              // actionProps={{
              //   onClick: toggleEdit,
              //   children: 'Customize greeting',
              // }}
            />
          )
        ) : (
          <Alert
            color={'attention'}
            label={'No voicemail box'}
            body={
              'We could not find a voicemail box associated with this line.'
            }
            icon={<WarningTriangle fr={undefined} />}
            actionProps={{
              children: 'Add voicemail box',
              onClick: handleCreate,
            }}
            reverse
          />
        )
      }
    />
  );
}

export default Voicemail;

export const EditLineGreeting = ({ vmbox, mediaId, onComplete, onCancel }) => {
  const updateVmbox = useUpdateVmboxPartial();

  const onMutate = async (mediaId: string | null) => {
    // let throw error for editor to handle
    const resp = await updateVmbox.mutateAsync({
      id: vmbox.id,
      media: {
        ...vmbox?.doc.media,
        unavailable: mediaId,
      },
    });

    onComplete();
  };

  return (
    <AudioEditor
      mediaName={`Mailbox ${vmbox.doc.mailbox} Greeting`}
      subType={'vmbox-greeting'}
      loadingLabel={'Updating greeting...'}
      isLoading={updateVmbox.isLoading}
      mediaId={mediaId}
      onClose={onCancel}
      onComplete={onMutate}
      hasPlatformDefault={false}
    />
  );
};
