import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { BuyPhoneNumberDialog } from 'app/components/BuyPhoneNumberDialog';
import { ExtensionInUseWarnings } from 'app/components/ExtensionInUseWarnings';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { RichTooltip } from 'app/components/RichTooltip';
import { AnimationDialog } from 'app/design';
import { Button, Dialog, IconButton, Setting } from 'app/design-lib';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import GenericMutationDialogContent from 'app/design/components/tailwind/GenericMutationDialogContent/GenericMutationDialogContent';
import { useExtensionUsedByQuery } from 'app/hooks/general/useExtensionUseQuery';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { AddSharedLineDialog } from 'app/pages/v2/settings/Pipes/components/AddSharedLineDialog';
import { usePipeNew } from 'app/pages/v2/settings/Pipes/Pipes';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import EmojiPicker, { Emoji } from 'emoji-picker-react';
import {
  AddUser,
  ArrowLeft,
  EditPencil,
  Plus as PlusIcon,
} from 'iconoir-react';
import Joi from 'joi';
import { startCase } from 'lodash';
import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import { Controller, useForm } from 'react-hook-form';
import SimpleBar from 'simplebar-react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import useDevMode from 'app/utilities/useDevMode';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';
import { User } from 'types/user';

import { General } from './components/General';
import { Messaging } from './components/Messaging';
import { Users } from './components/Users';
import { CallRouting } from './components/CallRouting';
import { MessageRouting } from './components/MessageRouting';
import { Voicemail } from './components/Voicemail';
import { Schedule } from './components/Schedule';

import { usePipesQuery, usePipeUpdate } from 'app/pages/v2/common/sdk';

const PipeView = () => {
  const { devMode } = useDevMode();
  const [editPipeDetails, toggleEditPipeDetails] = useToggleReducer(false);
  // @ts-ignore
  const { pipe_id } = useParams();

  const pipesQuery = usePipesQuery({ filters: { id: pipe_id } });
  const pipes = pipesQuery.data?.pipes ?? [];
  const pipe = pipes?.length ? pipes[0] : null;

  return (
    <>
      <EditPipeDetailsDialog
        pipe={pipe}
        open={editPipeDetails}
        onComplete={() => {
          pipesQuery.refetch();
          toggleEditPipeDetails();
        }}
        onCancel={toggleEditPipeDetails}
      />
      <SettingsPageWrapper
        isLoading={pipesQuery.isLoading}
        loadingLabel={'Loading line...'}
        label={
          pipe ? (
            <div className={'flex items-center space-x-2'}>
              <IconButton
                component={Link}
                variant={'ghost'}
                size={'md'}
                color={'neutral'}
                className={'no-underline text-content-accent'}
                to="/v2/settings/pipes"
              >
                <ArrowLeft fr={undefined} />
              </IconButton>
              <Emoji unified={pipe.metadata.emoji || '1f937'} size={24} />
              <span>
                {pipe.metadata.name?.length ? pipe.metadata.name : 'Unnamed'}
              </span>
              <span className={'text-neutral-60 text-sm'}>
                <PhoneNumberDisplay ptn={pipe.key} hideFlag />
              </span>
            </div>
          ) : null
        }
      >
        {pipe ? (
          <>
            <SettingsPanel
              leftChild={
                <Setting
                  label={'Line details'}
                  body={'Name and emoji to display along with the line number.'}
                  iconButtonProps={{
                    children: <EditPencil fr={undefined} />,
                    onClick: toggleEditPipeDetails,
                  }}
                />
              }
              rightChild={<></>}
            />
            <Users pipe={pipe} refetch={pipesQuery.refetch} />
            <Voicemail pipe={pipe} refetch={pipesQuery.refetch} />
            <CallRouting pipe={pipe} />
            {process.env.NODE_ENV === 'development' ||
            pipe.type === 'external' ? (
              <MessageRouting pipe={pipe} />
            ) : null}
            {/* <Schedule pipe={pipe} refetch={pipesQuery.refetch} /> */}
            {devMode &&
            pipe?.type === 'external' &&
            (process.env.NODE_ENV === 'development' ||
              pipe?.key?.length > 8) ? (
              <>
                <Messaging pipe={pipe} />
              </>
            ) : null}
          </>
        ) : (
          'Error loading pipe'
        )}
      </SettingsPageWrapper>
    </>
  );

  // return (
  //   <div
  //     className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //   >

  //     <div
  //       className="overflow-hidden bg-background-neutral/75 relative h-screen w-full"
  //       data-tour-settings-lines-view
  //     >
  //       <SimpleBar style={{ height: '100%' }}>
  //         <div className={'px-8 py-3 h-full'}>
  //           <div className="border-solid w-full pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
  //             <div className="text-sm mb-2">
  //               <Link
  //                 className={'no-underline text-content-accent'}
  //                 to="/v2/settings/pipes"
  //               >
  //                 &lt; Back to Lines
  //               </Link>
  //             </div>
  //             <div className={'flex w-full items-center justify-between'}>
  //               <div className={'flex  items-center space-x-2'}>
  //                 <div
  //                   className={
  //                     'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
  //                   }
  //                 >
  //                   <Emoji unified={pipe.metadata.emoji || '1f937'} size={25} />
  //                 </div>
  //                 <div>
  //                   <div className="font-bold text-xl">
  //                     {pipe.metadata.name?.length
  //                       ? pipe.metadata.name
  //                       : 'Unnamed'}{' '}
  //                   </div>
  //                   <div className="text-base text-neutral-500">
  //                     <PhoneNumberDisplay ptn={pipe.key} width="auto" />
  //                   </div>
  //                 </div>
  //               </div>
  //               <Button
  //                 color={'accent'}
  //                 size={'sm'}
  //                 variant={'outline'}
  //                 className={'-mb-2'}
  //                 onClick={toggleEditPipeDetails}
  //                 startIcon={<PersonAddIcon />}
  //               >
  //                 Customize Details
  //               </Button>
  //             </div>
  //             <span className={'text-sm font-light text-neutral-400'}>
  //               {startCase(pipe.type)} Line
  //             </span>
  //           </div>
  //           <div className="p-4">
  //             {/* <div className="text-sm mb-2">
  //               <Link to="/v2/settings/pipes">&lt;&lt; Back to Lines</Link>
  //             </div>
  //             <div className="text-2xl mb-1">
  //               {pipe.metadata.name?.length ? pipe.metadata.name : 'Unnamed'} -{' '}
  //               <PhoneNumberDisplay ptn={pipe.key} width="auto" />
  //             </div>
  //             <div className="text-lg text-neutral-300 mb-4">
  //               Manage settings for this Line
  //             </div>*/}
  //             <div className="space-y-4 ">
  //               {devMode && pipe.type !== 'external' ? (
  //                 <>
  //                   <General pipe={pipe} refetch={pipesQuery.refetch} />
  //                   <hr />
  //                 </>
  //               ) : null}
  //               {/* <div className="text-sm">[users - name, access_level]</div> */}
  //               <Users pipe={pipe} refetch={pipesQuery.refetch} />
  //               <hr className={'opacity-50'} />
  //               <Voicemail pipe={pipe} refetch={pipesQuery.refetch} />
  //               <hr className={'opacity-50'} />
  //               <CallRouting pipe={pipe} />
  //               <hr className={'opacity-50'} />
  //               {/* <div className="text-sm">[schedule]</div>
  //             <hr />
  //             <div className="text-sm">[menus]</div>
  //             <hr /> */}
  //               <Schedule pipe={pipe} refetch={pipesQuery.refetch} />
  //               {devMode &&
  //               (process.env.NODE_ENV === 'development' ||
  //                 pipe?.key?.length > 8) ? (
  //                 <>
  //                   <hr className={'opacity-50'} />
  //                   <Messaging pipe={pipe} />
  //                 </>
  //               ) : null}
  //             </div>
  //           </div>
  //         </div>
  //       </SimpleBar>
  //     </div>
  //   </div>
  // );
};

interface EditPipeDetailsProps {
  pipe: any;
  open: boolean;
  onComplete: (lineId: string) => void;
  onCancel: () => void;
}

const EditPipeDetailsDialog = ({ open, ...props }: EditPipeDetailsProps) => {
  return (
    <Dialog onClose={props.onCancel} open={open}>
      <EditPipeDetailsContent {...props} />
    </Dialog>
  );
};

const EditPipeDetailsContent = ({
  pipe,
  onComplete,
  onCancel,
}: Omit<EditPipeDetailsProps, 'open'>) => {
  const pipeNew = usePipeNew();
  const formMethods = useForm({
    defaultValues: {
      emoji: pipe.metadata.emoji,
      name: pipe.metadata.name,
    },
    resolver: joiResolver(
      Joi.object({
        emoji: Joi.string(),
        name: Joi.string().max(128),
      }),
    ),
  });

  // shows the users for the user_ids of the pipe

  const pipeUpdate = usePipeUpdate();

  // const [name, setName] = React.useState(pipe.metadata.name ?? '');
  // const [emoji, setEmoji] = React.useState(pipe.metadata.emoji ?? '');
  //
  // const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSave = async ({ emoji, name }: any) => {
    await pipeUpdate.mutateAsync({
      id: pipe.id,
      data: {
        metadata: {
          ...pipe.metadata,
          emoji,
          name,
        },
      },
    });
    // console.log('Updated users!');
    // refetch();
  };

  // const { data: phoneNumbersPage, isLoading: phoneNumbersLoading } =
  //   useListPhoneNumbersQuery({
  //     take: 500,
  //     filters: {
  //       // ids: pipe.phoneNumbers,
  //       id: pipe.key,
  //     },
  //     options: {},
  //   });
  // const phoneNumbers = phoneNumbersPage?.phoneNumbers ?? [];
  // const phoneNumber = phoneNumbers?.length ? phoneNumbers[0] : null;

  const [emojiPickerOpen, emojiPickerOpenSet] = useState(false);

  console.log('values', formMethods.watch(), formMethods.formState);

  return (
    <GenericMutationDialogContent
      formMethods={formMethods}
      title={emojiPickerOpen ? 'Change Line Emoji' : 'Customize Line Details'}
      mutation={pipeUpdate}
      onComplete={() => {
        onComplete(pipeUpdate.data.id);
      }}
      hideSubmitButton={emojiPickerOpen}
      onCancel={
        emojiPickerOpen
          ? () => {
              emojiPickerOpenSet(false);
            }
          : onCancel
      }
      cancelButtonLabel={emojiPickerOpen ? 'Cancel Emoji Change' : undefined}
      onSubmit={formMethods.handleSubmit(handleSave)}
      isLoadingLabel={'Updating line details'}
      onSuccessLabel={'Line details updated'}
      submitButtonLabel={'Update'}
    >
      <div className={'flex flex-col space-y-8'}>
        {emojiPickerOpen ? (
          <div className="[&_aside]:!border-0 [&_aside]:!h-auto [&_.epr-body]:!overflow-y-auto">
            <EmojiPicker
              previewConfig={{ showPreview: false }}
              onEmojiClick={e => {
                console.log('emoji:', e?.unified, e);
                // onChange(e.unified);
                formMethods.setValue('emoji', e?.unified, {
                  shouldDirty: true,
                });
                emojiPickerOpenSet(false);
              }}
            />
          </div>
        ) : (
          <>
            <Controller
              control={formMethods.control}
              render={({ field: { value, onChange, onBlur, ...props } }) => (
                <div className={'flex space-x-2 items-center'}>
                  {emojiPickerOpen ? null : (
                    // {/* <RichTooltip
                    //   open={emojiPickerOpen}
                    //   onClose={() => emojiPickerOpenSet(false)}
                    //   placement="bottom"
                    //   arrow
                    //   content={
                    //     <EmojiPicker
                    //       searchDisabled
                    //       previewConfig={{ showPreview: false }}
                    //       onEmojiClick={e => {
                    //         console.log('emoji:', e?.unified, e);
                    //         onChange(e.unified);
                    //         emojiPickerOpenSet(false);
                    //       }}
                    //     />
                    //   }
                    // > */}
                    <>
                      <span
                        className={'cursor-pointer'}
                        onClick={e => emojiPickerOpenSet(true)}
                      >
                        <div
                          className={
                            'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
                          }
                        >
                          <Emoji unified={value} size={25} />
                        </div>
                      </span>
                      {/* </RichTooltip> */}
                      <Button
                        variant={'outline'}
                        color={'accent'}
                        onClick={e => emojiPickerOpenSet(true)}
                      >
                        Change Emoji
                      </Button>
                    </>
                  )}
                </div>
              )}
              name={'emoji'}
            />
            <HookFormTextField name={'name'} label={'Line Name'} />
          </>
        )}
      </div>
    </GenericMutationDialogContent>
  );
};

// const usePipeUpdate = () => {
//   return useMutation(async ({ id, data }) => {
//     const mutationResponse = await request(
//       `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
//       gql`
//         mutation pipeUpdate($id: ID, $data: JSON) {
//           pipeUpdate(id: $id, data: $data) {
//             success
//             message
//             data
//           }
//         }
//       `,
//       {
//         id,
//         data,
//       },
//       // @ts-ignore
//       { authorization: store.getState().auth.auth_token },
//     );

//     console.log('pipes mutationResponse:', mutationResponse);

//     return mutationResponse.data;
//   });
// };

export default PipeView;
