import { GroupCreateDialog } from 'app/components/GroupCreateDialog';
import { UserListOptions } from 'app/components/ListUsers/components/UserListOptions';
import { usePNContext } from 'app/utilities/pubnub';
import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import SimpleBar from 'simplebar-react';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  Box,
  Typography,
  Grid,
  AnimationDialog,
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  CircularProgress,
} from 'app/design';
import { Button } from 'app/design-lib';
import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import useToggleReducer from 'app/utilities/useToggleReducer';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import {
  useTioMutation,
  useTioQuery,
  useQueryBrandsList,
  useQueryCampaignsList,
  useQueryBrandFeedback,
  useQueryCampaignMms,
  useQueryCampaignSharingStatus,
  useCampaignSharingSet,
  useCampaignAttachmentUpload,
} from 'app/pages/v2/common/sdk';

function CampaignsHome() {
  const brandsQuery = useTioQuery(`/messaging/brands`);
  // const brandsQuery = useQueryBrandsList();
  const campaignsQuery = useTioQuery(`/messaging/brands/all/campaigns`);
  // const campaignsQuery = useQueryCampaignsList();
  const campaigns = campaignsQuery.data?.records ?? [];

  // const campaignsQuery = useQueryCampaigns(); // no filter for brands (likely not necessary, we are only going to allow 1 Brand for now)
  const brand = brandsQuery.data?.records?.length
    ? brandsQuery.data.records[0]
    : null;
  // console.log('brandsQuery: ', brand, brandsQuery);
  console.log('campaignsQuery: ', campaignsQuery);
  // console.log('brandFeedback', brandFeedbackQuery);
  // console.log('campaigns:', campaigns)

  return (
    <>
      <div
        className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
      >
        <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
          <SimpleBar className={'h-full'} style={{ height: 'inherit' }}>
            <div className={'px-8 py-3 h-full'}>
              <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between space-x-1">
                <div className={'flex flex-auto items-center space-x-2'}>
                  <div
                    className={
                      'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
                    }
                  >
                    <CampaignIcon
                      className={'fill-content-accent '}
                      sx={{ fontSize: 'inherit' }}
                    />
                  </div>
                  <div>
                    <div
                      className="font-bold text-xl"
                      onClick={() => brandsQuery.refetch()}
                    >
                      SMS/MMS
                    </div>
                    <div className="text-base text-neutral-500">
                      Campaigns allow you to send SMS/MMS messages to customers.
                      These are required by mobile carrier operators, otherwise
                      your messages may fail to be delivered.
                    </div>
                  </div>
                </div>
                {brand ? (
                  <div className="flex flex-col space-y-1">
                    <Button
                      color={'accent'}
                      size={'sm'}
                      variant={'outline'}
                      className={'mx-auto'}
                      // onClick={toggleOpenCreateDialog}
                      // startIcon={<GroupAddIcon />}
                      component={Link}
                      to="/v2/settings/campaigns/brand/create"
                    >
                      Add New Brand
                    </Button>
                    {/* <Button
                      color={'accent'}
                      size={'sm'}
                      variant={'outline'}
                      className={'h-min'}
                      // onClick={toggleOpenCreateDialog}
                      // startIcon={<GroupAddIcon />}
                    >
                      Update Brand
                    </Button> */}
                    {/* <Button
                    color={'accent'}
                    size={'sm'}
                    variant={'outline'}
                    className={'h-min'}
                    // onClick={toggleOpenCreateDialog}
                    // startIcon={<GroupAddIcon />}
                  >
                    Add Campaign
                  </Button> */}
                  </div>
                ) : null}
              </div>
              {
                // Brand Loading
                brandsQuery.isLoading ? (
                  <div className="grid place-items-center min-h-[320px]">
                    <div className="text-center">
                      <CircularProgress size={40} />
                      <div className="text-base">
                        Loading Brand and Campaigns
                      </div>
                    </div>
                  </div>
                ) : // Done loading Brand, no Brand
                !brand ? (
                  <div className="grid place-items-center min-h-[320px]">
                    <div className="text-center">
                      <AccountBalanceIcon
                        className="text-neutral-400"
                        style={{ height: 28, width: 28, margin: '0 auto' }}
                      />
                      <div className="text-base">
                        Create a Brand to get started!
                      </div>
                      <Button
                        color={'accent'}
                        size={'sm'}
                        variant={'outline'}
                        className={'mx-auto'}
                        // onClick={toggleOpenCreateDialog}
                        // startIcon={<GroupAddIcon />}
                        component={Link}
                        to="/v2/settings/campaigns/brand/create"
                      >
                        Add New Brand
                      </Button>
                    </div>
                  </div>
                ) : (
                  // brand exists, no campaigns
                  <div className="">
                    <Brand brand={brand} />
                    {/* {campaignsQuery ? ( */}
                  </div>
                )
              }
              <div className="">
                {campaigns.map(campaign => {
                  return (
                    <Campaign key={campaign.campaignId} campaign={campaign} />
                  );
                })}
                {/* {groupsLoading ? (
                  <div className={'pt-5 text-neutral-400 w-full text-center'}>
                    Loading Groups...
                  </div>
                ) : null}
                {!groupsLoading && !groups?.length ? (
                  <div className={'pt-5 text-neutral-400 w-full text-center'}>
                    No groups have been created yet
                  </div>
                ) : null} */}
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  );
}

const Brand = ({ brand }) => {
  // TODO: show different, depending on entityType?

  // const brandFeedbackQuery = useQueryBrandFeedback({
  //   brand_id: brand?.brandId,
  // });
  const brandFeedbackQuery = useTioQuery(
    `/messaging/brands/${brand?.brandId}/feedback`,
  );

  console.log('brandFeedbackQuery:', brandFeedbackQuery);

  return (
    <div className="p-4 bg-neutral-100/40 flex justify-between">
      <div className="">
        <div className="text-xs">{brand.entityType}</div>
        <div className="text-base font-bold">{brand.displayName}</div>
        <div className="text-xs">{brand.brandId}</div>
        <div>
          {brand.identityStatus !== 'UNVERIFIED' ? (
            // TODO: potentially don't show this if Sole Proprietor
            <Button
              color={'accent'}
              size={'sm'}
              variant={'outline'}
              // className={'mx-auto'}
              // onClick={toggleOpenCreateDialog}
              // startIcon={<GroupAddIcon />}
              component={Link}
              to={`/settings/campaigns/create/${brand.brandId}`}
            >
              Create Campaign
            </Button>
          ) : null}
        </div>
      </div>
      <div className="">
        {brand.hasOwnProperty('evpVettingScore') ? (
          <div className="text-2xl">{brand.evpVettingScore}</div>
        ) : null}
        {brandFeedbackQuery.isLoading ? (
          <div className="text-xs">Loading Feedback</div>
        ) : brandFeedbackQuery.data?.category?.length ? (
          <div className="">
            {brandFeedbackQuery.data?.category.map((cat, i) => {
              return (
                <div key={i} className="text-xs">
                  <div className="text-base">{cat.displayName}</div>
                  <div className="text-xs">{cat.description}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-xs">No Negative Feedback</div>
        )}
      </div>
      <div className="">
        {/* TODO: provide more information about verification! (how long it may take, etc) */}
        {/* - when the identityStatus === 'UNVERIFIED' */}
        <div className="inline-block bg-background-accent-secondary text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          {brand.identityStatus}
        </div>
        {
          // SMS Verify for Sole Proprietor
          brand.entityType === 'SOLE_PROPRIETOR' &&
          brand.identityStatus === 'SELF_DECLARED' ? (
            <div className="mt-2">
              <Button
                color={'accent'}
                size={'sm'}
                variant={'outline'}
                className={'h-min'}
                // onClick={toggleOpenCreateDialog}
                // startIcon={<GroupAddIcon />}
              >
                todo: mobilePhone verify
              </Button>
            </div>
          ) : null
        }
      </div>
    </div>
  );
};

const Campaign = ({ campaign }) => {
  // TODO: show different, depending on entityType?
  // const uploadAttachment = useCampaignAttachmentUpload();
  // const campaignSharingSet = useCampaignSharingSet();
  const uploadAttachment = useTioMutation();
  const campaignSharingSetCnp = useTioMutation();
  const campaignMmsQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sample_media`,
  );
  const campaignSharingStatusQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sharing`,
  );
  // const campaignMmsQuery = useQueryCampaignMms({
  //   campaign_id: campaign.campaignId,
  // });
  // const campaignSharingStatusQuery = useQueryCampaignSharingStatus({
  //   campaign_id: campaign.campaignId,
  // });

  const handleChangeFile = async (file, e) => {
    // TODO:
    // - validate mms info, otherwise this may fail on the upload!
    // - verify file size, file type

    // TODO: show "uploading" status
    console.log('file change:', file);
    const value = await getBase64(file);
    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot + 1);
    // console.log('value:', ext, file, value);
    // @ts-ignore
    const result = await uploadAttachment.mutateAsync({
      method: 'PUT',
      url: `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sample_media`, // TODO: dont hardcode for Bandwidth
      data: {
        base64: value,
        extension: ext,
      },
    });
    campaignMmsQuery.refetch();
    console.log('result:', result, file);
    // // console.log('file:', file);
    // append({
    //   id: Date.now(),
    //   value: file,
    //   // result
    // });
    e.target.value = null;
  };

  const handleSetSharing = async () => {
    // // @ts-ignore
    // await campaignSharingSet.mutateAsync({
    //   campaign_id: campaign.campaignId,
    //   upstream_cnp_id: 'BANDW', // THIS SHOULD DEPEND ON THE WHICH PHONE NUMBERS WE WANT TO USE IT WITH!
    // });

    // @ts-ignore
    await campaignSharingSetCnp.mutateAsync({
      method: 'POST',
      url: `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sharing/cnp/BANDW`, // TODO: dont hardcode for Bandwidth
      data: {},
    });
  };

  // can set if not already set (and fetched?)
  const sharedByMe = campaignSharingStatusQuery?.data?.sharedByMe;
  const canSetSharing =
    !campaignSharingStatusQuery.isLoading && !sharedByMe?.upstreamCnpId;

  return (
    <div className="p-4 bg-neutral-100/40 flex justify-between">
      <div className="">
        <div className="text-xs">Campaign</div>
        <div className="text-base font-bold">{campaign.description}</div>
        <div className="text-xs">{campaign.campaignId}</div>
        <div className="text-xs">
          {campaignSharingStatusQuery.isLoading ? (
            <>Loading sharing status...</>
          ) : (
            <>
              Sharing:{' '}
              {canSetSharing ? (
                <>
                  <Button
                    color={'accent'}
                    size={'sm'}
                    variant={'outline'}
                    className={'h-min w-max'}
                    onClick={handleSetSharing}
                  >
                    Share Campaign
                  </Button>
                </>
              ) : (
                <>
                  {campaignSharingStatusQuery?.data?.sharedByMe
                    ?.upstreamCnpId ?? 'None'}
                  (
                  {campaignSharingStatusQuery?.data?.sharedByMe
                    ?.sharingStatus ?? '--'}
                  )
                </>
              )}
            </>
          )}
        </div>
        <div className="">
          {campaignMmsQuery.data?.length ? (
            <div className="">
              {campaignMmsQuery.data.length} Sample Media File(s)
            </div>
          ) : null}
          <label style={{ cursor: 'pointer' }}>
            <input
              type="file"
              // accept=".txt"
              style={{ display: 'none' }}
              // @ts-ignore
              onChange={e => handleChangeFile(e.target.files[0], e)}
            />

            <Button
              color={'accent'}
              size={'sm'}
              variant={'outline'}
              className={'h-min w-max'}
              // onClick={toggleOpenCreateDialog}
              // startIcon={<GroupAddIcon />}
              component="div"
            >
              {uploadAttachment.isLoading ? 'Uploading...' : 'Upload Media'}
            </Button>
          </label>
        </div>
      </div>
      {/* <div className="">
        <div className="text-2xl">{campaign.evpVettingScore ?? 'NoScore'}</div>
      </div> */}
      <div className="">
        <div className="bg-background-accent-secondary text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          {campaign.status}
        </div>
      </div>
    </div>
  );
};

const getBase64 = async input => {
  return new Promise((resolve, reject) => {
    console.log('reading..');
    const reader = new FileReader();

    reader.onloadend = async function (evt) {
      console.log('reading3..');
      const file = evt.target?.result;
      console.log('output base64:', file);
      // remove "data:image/jpeg;base64," (everything before the comma)
      // @ts-ignore
      let [ignore, ...rest] = file?.split(',');
      // @ts-ignore
      console.log('output base64:', rest.join(','));
      resolve(rest.join(','));
    };

    reader.onerror = reject;
    console.log('2..');
    reader.readAsDataURL(input);
  });
};

export default CampaignsHome;
