import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';
import { useBuilderContext } from 'app/components/IvrBuilder/IvrBuilder';

import { Box } from '../common/Box';
import { MenuButton } from '../common/MenuButton';
import { Dialpad, Voice, WarningTriangle, PhoneDisabled } from 'iconoir-react';
import useEnsureModuleItemId from '../common/useEnsureModuleItemId';
import { ModeSelectModuleThis } from '../common/ModeSelectModule';
import { MiniMapNode } from '../../../../../pages/v2/settings/PipeEditCallRouting/MobileView/MiniMapNode';

// why use memo??
const HangupNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
    pipe,
  } = data;

  // add id if does not exist
  useEnsureModuleItemId(data);

  const { setDisableScroll, miniMap } = useBuilderContext();

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    // if (data.optsPath === modifyPath) {
    //   setShowModifyDialog(true);
    // }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (miniMap) {
    return <MiniMapNode pref={boxRef} data={data} />;
  }

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <ModeSelectModuleThis id={moduleItem?.id} />
      <div
        className={`flex flex-col items-center  whitespace-nowrap w-full pt-4 px-2 pb-4`}
      >
        <div className={`flex space-x-2 text-neutral-60`}>
          <div
            className={`text-sm flex items-center space-x-2 whitespace-nowrap`}
          >
            <PhoneDisabled width={14} height={14} />
            <span>Hangup</span>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            type={'termination'}
            current={'Hangup'}
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              'replace',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );
});

export default HangupNode;
