import { Handle } from 'react-flow-renderer';
import { Button, IconButton } from '../../../../../design-lib';
import { Voice } from 'iconoir-react';
import React from 'react';
import { useBuilderContext } from '../../../../../components/IvrBuilder/IvrBuilder';
import { getElementProps } from './utils/getElementProps';
import { ModeSelectModuleThis } from '../../../../../components/IvrBuilder/Flow/nodes/common/ModeSelectModule';
import { Box } from '../../../../../components/IvrBuilder/Flow/nodes/common/Box';
import { useCallRouteEditor } from './CallflowContext';
import { element, elementType } from 'prop-types';

export const MiniMapNode = ({
  data,
  pref,
  type: elemType = '',
  elementId = '',
}) => {
  const { setSelectedElementKey, setOpenMiniMap } = useBuilderContext();
  const { elements } = useCallRouteEditor();
  const { moduleItem, modifyPath } = data;

  const { type, name, icon, nodeType, color } = getElementProps({
    type: elemType,
    data,
  });

  const content = (
    <>
      {' '}
      {nodeType !== 'root' ? (
        <Handle
          type="target"
          // @ts-ignore
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
      ) : null}
      <Button
        startIcon={icon}
        disabled={nodeType === 'root'}
        onClick={() => {
          setOpenMiniMap(false);
          let path;

          switch (elemType) {
            case 'InsertScheduleOption':
              path = data.scheduleData.modifyPath;
              break;
            case 'InsertMenuOption':
              path = data.menuData.modifyPath;
              break;
            case 'OptionNode':
              path =
                modifyPath +
                `.${data.targetKey}.callflow.strategy.data.modules`;
              break;
            case 'OptionScheduleNode':
              const element = elements.find(elem => elem.id === elementId);
              path = element.key;
              break;
            default:
              path = modifyPath;
          }

          setSelectedElementKey(path);
        }}
        // @ts-ignore
        color={color}
        size={'md'}
        variant={'outline'}
      >
        {name}
      </Button>
      {nodeType === 'terminal' ? null : (
        <Handle
          type="source"
          // @ts-ignore
          position="bottom"
          style={{
            top: 'auto',
            bottom: 1,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      )}
    </>
  );

  if (!pref) {
    return content;
  }

  return (
    <Box
      pref={pref}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      {data.moduleItem?.id !== 'root' &&
      data.moduleItem?.type !== 'GoToInFlow' ? (
        <ModeSelectModuleThis id={moduleItem?.id} />
      ) : null}
      {content}
    </Box>
  );
};
