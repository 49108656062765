import { Tab } from '@headlessui/react';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  TextField,
} from 'app/design-lib';
import { useUpdateMediaFull } from 'app/hooks/mutations/media';
import { useCreateMedia } from 'app/hooks/mutations/media/useCreateMedia';
import { useUploadMedia } from 'app/hooks/mutations/media/useUploadMedia';
import { useListMediasQuery } from 'app/hooks/queries/media';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';
import { useToggleReducer } from 'app/utilities';
import { startCase } from 'lodash';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { MediaDoc, MediaType } from 'types/media';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useTioMutation, useTioQuery } from 'app/pages/v2/common/sdk';

interface ContentProps {
  number?: string | null;
  onComplete: () => void;
  onCancel: () => void;
}

interface PftDialogProps extends ContentProps, Pick<DialogProps, 'open'> {
  noWrapper?: boolean;
}

const PftDialog = ({ noWrapper, open, ...props }: PftDialogProps) => {
  if (noWrapper) return <PftDialogContent {...props} />;

  return (
    <Dialog size={'md'} onClose={props.onCancel} open={open}>
      <PftDialogContent {...props} />
    </Dialog>
  );
};

const PftDialogContent = ({ number, onComplete, onCancel }: ContentProps) => {
  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();
  const queryClient = useQueryClient();
  const two_factor_numbers = useTioQuery(`/mfa/phone_numbers`);
  const add_2fa_number = useTioMutation();
  const verify_2fa_number = useTioMutation();
  const set_pft = useTioMutation();
  const formMethods = useForm({
    defaultValues: {
      customer_phone: '',
    },
  });
  const { register, handleSubmit, control, getValues, setValue, watch } =
    formMethods;

  console.log('two_factor_numbers:', two_factor_numbers);

  const handleSetNumber = async () => {
    const customer_phone = prompt('your number', '+16027059885');
    if (!customer_phone) {
      return false;
    }
    // @ts-ignore
    setValue('customer_phone', customer_phone);
  };

  const handleAddNumber = async () => {
    // @ts-ignore
    await add_2fa_number.mutateAsync({
      method: 'POST',
      url: '/mfa/phone_numbers/pin/send',
      data: {
        number: getValues('customer_phone'),
      },
    });
  };

  const handleVerifyNumber = async () => {
    const pin = prompt('PIN', '9TA6A7');
    // if (!number) {
    //   return false;
    // }
    console.log('number:', getValues('customer_phone'));
    // @ts-ignore
    await verify_2fa_number.mutateAsync({
      method: 'POST',
      url: '/mfa/phone_numbers/pin/verify',
      data: {
        // @ts-ignore
        number: getValues('customer_phone'),
        pin,
      },
    });
  };

  const handleJoinPft = async () => {
    console.log('customer_phone:', getValues('customer_phone'));
    // let number2 = '+16282791757';
    // @ts-ignore
    await set_pft.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${number}/pft`,
      data: {
        // @ts-ignore
        phone: getValues('customer_phone'),
        first_name: userData?.doc?.first_name,
        last_name: userData?.doc?.last_name,
      },
    });
  };

  const handleJoinDefaultLowVolume = async () => {
    // @ts-ignore
    await set_pft.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${number}/default_low_volume`,
      data: {},
    });
  };

  return (
    <>
      <div>
        <DialogTitle>Trial Messaging</DialogTitle>
        <div className="p-4">
          <Button
            color={'accent'}
            size={'sm'}
            variant={'outline'}
            className={'-mb-2'}
            onClick={handleJoinDefaultLowVolume}
            // startIcon={<PersonAddIcon />}
          >
            Join Default Low Volume
          </Button>
          <br />
          <Button
            color={'accent'}
            size={'sm'}
            variant={'outline'}
            className={'-mb-2'}
            onClick={handleSetNumber}
            // startIcon={<PersonAddIcon />}
          >
            Set 2FA Number
          </Button>
          {watch('customer_phone') ? (
            <>
              <br />
              <br />
              <Button
                color={'accent'}
                size={'sm'}
                variant={'outline'}
                className={'-mb-2'}
                onClick={handleAddNumber}
                // startIcon={<PersonAddIcon />}
              >
                Add Number
              </Button>
              <br />
              <Button
                color={'accent'}
                size={'sm'}
                variant={'outline'}
                className={'-mb-2'}
                onClick={handleVerifyNumber}
                // startIcon={<PersonAddIcon />}
              >
                Verify PIN
              </Button>
              <br />
              <Button
                color={'accent'}
                size={'sm'}
                variant={'outline'}
                className={'-mb-2'}
                onClick={handleJoinPft}
                // startIcon={<PersonAddIcon />}
              >
                Join PFT
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PftDialog;
