import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Tooltip,
  Link,
  Typography,
  ThemeProvider,
  Chip,
} from 'app/design';
import { Emoji } from 'emoji-picker-react';
import { Message, MoreVert, Phone, Plus, Search } from 'iconoir-react';

import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
  Search as SearchIcon,
  Person as PersonIcon,
} from 'app/design/icons-material';

import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import {
  normalizeNumber,
  PhoneNumberDisplay,
} from 'app/components/PhoneNumberDisplay';
import { useContactsQuery } from 'app/hooks/queries/contact';
import { cloneDeep, merge, orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { store } from '../../../../../../../store';
import { UserPrivLevel } from '../../../../../../../types/user';
import {
  useWebphoneSelector,
  useWebphoneSlice,
} from '../../../../../../data/webphone';
import { useWebphoneCall } from 'app/components/Webphone';
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Listbox,
  Table,
  TableCell,
  TableRow,
  TextField,
} from '../../../../../../design-lib';
import IconButtonDropdown from '../../../../../../design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { SettingsPageWrapper } from '../../../../../../design-lib/components/SettingsPageWrapper';
import { useDeleteContact } from '../../../../../../hooks/mutations/contact';
import { useAccountQuery } from '../../../../../../hooks/queries/account';
import {
  useAuthenticatedUserQuery,
  useListUsersQuery,
  useUserQuery,
} from '../../../../../../hooks/queries/user';
import {
  usePipeConvoUpsert,
  usePipesQuery,
} from '../../../../../../pages/v2/common/sdk';
import ParticipantName from '../../../../../../pages/v2/pipe/components/ParticipantName/ParticipantName';
import { useConfirmationDialog } from '../../../../../DefaultDialogActions/DefaultDialogActions';
import {
  EditContactDialog,
  useEditContactDialog,
} from '../../../../../EditContactDialog';
import { AvatarCell } from '../../../../../ListUsers/components/AvatarCell';
import { useWebphoneContext } from '../../../../Store';
import { ViewContainer } from '../../../ViewContainer';
import { useToggleReducer } from '../../../../../../utilities';

// import Menu from 'material-ui-popup-state/HoverMenu'; // use for HoverMenu!

const epochOffset = 62167219200;

// TODO: this has a bunch of duplicate code
// - nick copy-pasted to create useCombinedContacts so it would be available earlier in the app
//   - ie, we probably should do the dispatch() here...
export const useCombinedContacts = () => {
  // const dispatchRedux = useDispatch();
  const { contacts } = useWebphoneSelector();
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();
  const {
    data: contactsData,
    isLoading: contactsLoading,
    error: contactsError,
    refetch: refetchContacts,
    isFetched: contactsFetched,
    isFetching: contactsFetching,
  } = useContactsQuery({});
  const {
    data: usersPageResp,
    isLoading: usersLoading,
    error: usersError,
    refetch: refetchUsers,
    isFetched: usersFetched,
    isFetching: usersFetching,
  } = useListUsersQuery({ quick: true });
  const { users = [] } = usersPageResp?.users ?? {};
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (contactsFetched && usersFetched) {
      // update contacts state (persisted to local storage)
      // console.log({ usersFetched, usersPageResp });
      dispatch(
        actions.setContacts(
          [
            ...(users ?? []).flatMap(user => {
              return {
                user,
                info: {
                  firstName: user.doc.first_name ?? user.doc.name,
                  lastName: user.doc.last_name,
                  // only showing exts/numbers on main cf
                  phoneNumbers: [
                    {
                      number: user.doc.presence_id,
                      digits: user.doc.presence_id,
                    },
                    ...user.Pipes.map(pipe => ({
                      number: pipe.key,
                      digits: pipe.key,
                    })),
                  ], // TODO: show Pipes they have access to (or receive messages for?)
                  // user.Callflows.find(cf => cf.doc.type === 'main')
                  //   ?.doc.numbers.filter(num => !num.startsWith('cid'))
                  //   .map(num => ({ number: num, digits: num })) ?? [],

                  // using company field to display title
                  company: user.doc.title,
                },
                id: user.id,
              };
            }),
            ...(contactsData ?? []),
          ].sort((a, b) => {
            const aValue = `${a.info.lastName ?? ''}${a.info.firstName ?? ''}${
              a.info.company ?? ''
            }`.toLowerCase();

            const bValue = `${b.info.lastName ?? ''}${b.info.firstName ?? ''}${
              b.info.company ?? ''
            }`.toLowerCase();

            return aValue.localeCompare(bValue);
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsFetched, usersFetched, contactsData, usersPageResp?.users]);

  return null;
};

const CombinedContacts = props => {
  const { show } = props;
  // const dispatchRedux = useDispatch();
  const { contacts } = useWebphoneSelector();
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();
  const [type, setType] = useState('all');
  const {
    data: contactsData,
    isLoading: contactsLoading,
    error: contactsError,
    refetch: refetchContacts,
    isFetched: contactsFetched,
    isFetching: contactsFetching,
  } = useContactsQuery({});
  const {
    data: usersPageResp,
    isLoading: usersLoading,
    error: usersError,
    refetch: refetchUsers,
    isFetched: usersFetched,
    isFetching: usersFetching,
  } = useListUsersQuery({ quick: true });
  const { users = [] } = usersPageResp?.users ?? {};
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    // console.log('running');
    if (contactsFetched && usersFetched) {
      // update contacts state (persisted to local storage)
      // console.log({ usersFetched, usersPageResp });
      dispatch(
        actions.setContacts(
          [
            ...(contactsData ?? []),

            ...(users ?? []).flatMap(user => {
              return {
                user,
                info: {
                  firstName: user.doc.first_name ?? user.doc.name,
                  lastName: user.doc.last_name,
                  // only showing exts/numbers on main cf
                  phoneNumbers: [
                    {
                      number: user.doc.presence_id,
                      digits: user.doc.presence_id,
                    },
                    ...user.Pipes.map(pipe => ({
                      number: pipe.key,
                      digits: pipe.key,
                    })),
                  ],
                  // user.Callflows.find(cf => cf.doc.type === 'main')
                  //   ?.doc.numbers.filter(num => !num.startsWith('cid'))
                  //   .map(num => ({ number: num, digits: num })) ?? [],

                  // using company field to display title
                  company: user.doc.title,
                },
                id: user.id,
              };
            }),
          ].sort((a, b) => {
            const aValue = `${a.info.lastName ?? ''}${a.info.firstName ?? ''}${
              a.info.company ?? ''
            }`.toLowerCase();

            const bValue = `${b.info.lastName ?? ''}${b.info.firstName ?? ''}${
              b.info.company ?? ''
            }`.toLowerCase();

            return aValue.localeCompare(bValue);
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactsFetched,
    usersFetched,
    // contacts,
    contactsData,
    usersPageResp?.users,
  ]);

  // const [debouncedFilterText, setDebouncedFilterText] = useState('');

  const allContacts = [];
  const [filteredContacts, setFilteredContacts] = useState([]);

  // const {
  //   Dialog: EditContactDialog,
  //   DialogProps: EditContactDialogProps,
  //   toggleOpen: toggleOpenEditContactDialog,
  // } = useEditContactDialog();
  const [showAddContact, toggleAddContact] = useToggleReducer();

  // useDebounce(
  //   () => {
  //     setDebouncedFilterText(filterText);
  //   },
  //   250,
  //   [filterText],
  // );

  useEffect(() => {
    setFilteredContacts(
      matchSorter(contacts, filterText, {
        keys: [
          test => `${test.info.firstName} ${test.info.lastName}`,
          'info.lastName',
          'info.firstName',
          'info.company',
          'info.phoneNumbers.*.digits',
          'info.phoneNumbers.*.number',
        ],
        sorter: items => items,
        // sorter: items => orderBy(items, ['info.lastName', 'info.firstName']),
      }).filter(contact =>
        type === 'all'
          ? true
          : type === 'internal'
          ? !!contact.user
          : !contact.user,
      ),
    );
  }, [filterText, contacts, contactsData, type, usersPageResp?.users]);

  // console.log('contacts', contacts);

  const handleContactChange = () => {
    toggleAddContact();
    // refetchContacts();
  };

  return (
    <SettingsPageWrapper
      isLoading={usersLoading || contactsLoading}
      loadingLabel={'Loading contacts...'}
      label={'Contacts'}
      // subLabel={'Account settings'}
      actions={
        <>
          <EditContactDialog
            open={showAddContact}
            onCancel={toggleAddContact}
            onComplete={handleContactChange}
          />
          <div className={'flex items-center space-x-2'}>
            <TextField
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
              placeholder="Search"
              className={
                'border-none focus:outline-0 focus:ring-0 focus:border-none'
              }
              startIcon={<Search fr={undefined} />}
            />
            <div className={'w-48'}>
              <Listbox
                variant={'ghost'}
                className={'w-full'}
                value={type}
                options={[
                  { label: 'All Types', value: 'all' },
                  { label: 'External', value: 'external' },
                  { label: 'Internal', value: 'internal' },
                ]}
                onChange={setType}
              />
            </div>
            <Button
              variant={'fill'}
              color={'accent'}
              size={'md'}
              startIcon={<Plus fr={undefined} />}
              onClick={toggleAddContact}
            >
              Add new
            </Button>
          </div>
        </>
      }
    >
      {filteredContacts.length ? (
        <Table>
          <tr
            className={`border-b-2 text-neutral-60 font-medium text-sm first:flex-1 border-neutral-20 w-full min-h-10 whitespace-nowrap flex  items-center`}
          >
            <TableCell className={'font-medium w-56 py-1 text-sm'}>
              Name
            </TableCell>
            <TableCell className={'font-medium w-48 py-1 text-sm'}>
              Number(s)
            </TableCell>
            <TableCell className={'font-medium w-28 py-1 text-sm'}>
              Extension
            </TableCell>
            <TableCell className={'font-medium w-28 py-1 text-sm'}>
              Type
            </TableCell>
            <TableCell className={'font-medium w-52 py-1 text-sm'}>
              Title / Company
            </TableCell>
            <TableCell className={'font-medium w-64 py-1 text-sm'}>
              Email
            </TableCell>
            <TableCell
              className={'font-medium flex-1 py-1 text-sm'}
            ></TableCell>
          </tr>
          {filteredContacts.map((contact, index) => {
            return (
              <ExternalContactRow
                onEdit={refetchContacts}
                key={index}
                contact={contact}
                showBorder={
                  filteredContacts.length > 1 &&
                  index < filteredContacts.length - 1
                }
              />
            );
          })}
        </Table>
      ) : (
        <div
          className={
            'w-full absolute top-1/2 -translate-y-1/2 h-full grid place-items-center'
          }
        >
          <span className={'text-md text-gray-60 font-medium'}>
            No contacts match search criteria
          </span>
        </div>
      )}
    </SettingsPageWrapper>
  );

  // return (
  //   <>
  //     <EditContactDialog
  //       open={showAddContact}
  //       onCancel={toggleAddContact}
  //       onComplete={handleContactChange}
  //     />
  //     <Box
  //       sx={{
  //         display: show ? 'flex' : 'none',
  //         height: '100%',
  //         flexDirection: 'column',
  //       }}
  //     >
  //       <Box sx={{ flex: '0 1 auto' }}>
  //         <ViewContainer
  //           title={'Contacts'}
  //           searchComponent={
  //             <div className={`flex space-x-2 items-center`}>
  //               <TextField
  //                 value={filterText}
  //                 onChange={e => setFilterText(e.target.value)}
  //                 placeholder="Filter Contacts"
  //                 variant="outlined"
  //                 fullWidth
  //                 size="small"
  //                 style={{ margin: 0 }}
  //                 InputProps={{
  //                   startAdornment: (
  //                     <InputAdornment position="start">
  //                       <SearchIcon style={{ transform: 'scaleX(-1)' }} />
  //                     </InputAdornment>
  //                   ),
  //                 }}
  //               />
  //               <ButtonDropdownMenu
  //                 menuItems={[
  //                   {
  //                     text: 'New Contact',
  //                     onClick: toggleAddContact,
  //                   },
  //                 ]}
  //                 // menuItemsDependencies={[dispatchRedux, actions]}
  //               />
  //             </div>
  //           }
  //           show={show}
  //         />
  //       </Box>
  //       <Divider />
  //       <Box
  //         sx={{
  //           flex: 1,
  //           overflowY: 'auto',
  //         }}
  //       >
  //         <Box>
  //           {filteredContacts.length ? (
  //             <Box>
  //               {filteredContacts.map((contact, i) => (
  //                 <>
  //                   <ExternalContactItem
  //                     key={contact.id}
  //                     contact={contact}
  //                     onEdit={refetchContacts}
  //                   />
  //                   <Divider />
  //                 </>
  //               ))}
  //             </Box>
  //           ) : allContacts.length ? (
  //             <Box sx={{ padding: 1, height: '100%' }}>
  //               <Grid
  //                 container
  //                 style={{ height: '100%' }}
  //                 justifyContent="center"
  //                 alignItems="center"
  //               >
  //                 <Grid item style={{ textAlign: 'center' }}>
  //                   <Typography variant="h6">No Contacts for Filter</Typography>
  //                   <Typography variant="caption">
  //                     Try a different search
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </Box>
  //           ) : usersLoading || contactsLoading ? (
  //             <div style={{ padding: 1, height: '100%' }}>
  //               <Grid
  //                 container
  //                 style={{ height: '100%' }}
  //                 justifyContent="center"
  //                 alignItems="center"
  //               >
  //                 <Grid item style={{ textAlign: 'center' }}>
  //                   <Typography variant="h1">
  //                     <CircularProgress size={44} />
  //                   </Typography>
  //                   <Typography variant="caption">
  //                     Checking for Contacts
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </div>
  //           ) : (
  //             <div style={{ padding: 1, height: '100%' }}>
  //               <Grid
  //                 container
  //                 sx={{ height: '100%' }}
  //                 justifyContent="center"
  //                 alignItems="center"
  //               >
  //                 <Grid item style={{ textAlign: 'center' }}>
  //                   <Typography variant="h6">No Contacts</Typography>
  //                   <Typography variant="caption">
  //                     Contacts are shared between your devices
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </div>
  //           )}
  //           {usersFetching || contactsFetching ? (
  //             <Box sx={{ width: '100%', padding: 2, textAlign: 'center' }}>
  //               <Typography
  //                 sx={{
  //                   fontStyle: 'italic',
  //                   //// color: theme => theme.palette.text.secondary,
  //                 }}
  //               >
  //                 Syncing contacts...
  //               </Typography>
  //             </Box>
  //           ) : null}
  //         </Box>
  //       </Box>
  //     </Box>
  //     {/*<Box
  //       sx={{
  //         height: '100%',
  //         overflowY: 'auto',
  //         display: show ? 'block' : 'none',
  //         background: theme => theme.palette.content.background,
  //         py: '20px',
  //         px: '30px',
  //       }}
  //     >
  //       <Grid container alignItems={'center'} justifyContent={'space-between'}>
  //         <Grid item>
  //           <Typography
  //             sx={{ color: '#0B2027', fontWeight: 700, fontSize: '18px' }}
  //           >
  //             Contacts
  //           </Typography>
  //         </Grid>
  //         <Grid item></Grid>
  //       </Grid>
  //       <br />
  //
  //       <ThemeProvider
  //         // @ts-ignore
  //         theme={outerTheme => {
  //           return merge(cloneDeep(outerTheme), {
  //             components: {
  //               MuiInputAdornment: {
  //                 styleOverrides: {
  //                   root: {
  //                     // @ts-ignore
  //                     color: `${outerTheme.palette.primary.dark} !important`,
  //                   },
  //                 },
  //               },
  //               MuiAutocomplete: {
  //                 styleOverrides: {
  //                   input: {
  //                     width: '100%',
  //                     // color: 'white !important',
  //                     '&::placeholder': {
  //                       textOverflow: 'ellipsis',
  //                       color: 'rgba(15, 97, 113, 0.53)',
  //                       opacity: 1,
  //                     },
  //                     color: 'rgba(15, 97, 113, 0.53)',
  //                   },
  //                   endAdornment: {
  //                     opacity: 0,
  //                   },
  //                   // groupLabel: {
  //                   //   fontStyle: 'italic',
  //                   //   fontWeight: 700,
  //                   //   borderTop: '1px solid #eee',
  //                   //   borderBottom: '1px solid #fafafa',
  //                   // },
  //                   listbox: {
  //                     maxHeight: '80vh',
  //                   },
  //                 },
  //               },
  //               MuiOutlinedInput: {
  //                 styleOverrides: {
  //                   root: {
  //                     padding: '4px !important',
  //                     background: 'white',
  //                   },
  //                   notchedOutline: {
  //                     // borderColor: 'white !important',
  //                     borderColor: 'rgba(27, 125, 144, 0.18) !important',
  //                     borderWidth: '1px !important',
  //                   },
  //                 },
  //               },
  //             },
  //           });
  //         }}
  //       ></ThemeProvider>
  //       <br />
  //       <br />
  //       <Divider />
  //        Handle empty states
  //       {filteredContacts.length ? (
  //         <Box>
  //           {filteredContacts.map((contact, i) => (
  //             <>
  //               <ExternalContactItem
  //                 key={contact.id}
  //                 contact={contact}
  //                 onEdit={refetchContacts}
  //               />
  //               <Divider />
  //             </>
  //           ))}
  //         </Box>
  //       ) : allContacts.length ? (
  //         <Box sx={{ padding: 1, height: '100%' }}>
  //           <Grid
  //             container
  //             style={{ height: '100%' }}
  //             justifyContent="center"
  //             alignItems="center"
  //           >
  //             <Grid item style={{ textAlign: 'center' }}>
  //               <Typography variant="h6">No Contacts for Filter</Typography>
  //               <Typography variant="caption">
  //                 Try a different search
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         </Box>
  //       ) : usersLoading || contactsLoading ? (
  //         <div style={{ padding: 1, height: '100%' }}>
  //           <Grid
  //             container
  //             style={{ height: '100%' }}
  //             justifyContent="center"
  //             alignItems="center"
  //           >
  //             <Grid item style={{ textAlign: 'center' }}>
  //               <Typography variant="h1">
  //                 <CircularProgress size={44} />
  //               </Typography>
  //               <Typography variant="caption">Checking for Contacts</Typography>
  //             </Grid>
  //           </Grid>
  //         </div>
  //       ) : (
  //         <div style={{ padding: 1, height: '100%' }}>
  //           <Grid
  //             container
  //             sx={{ height: '100%' }}
  //             justifyContent="center"
  //             alignItems="center"
  //           >
  //             <Grid item style={{ textAlign: 'center' }}>
  //               <Typography variant="h6">No Contacts</Typography>
  //               <Typography variant="caption">
  //                 Contacts are shared between your devices
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         </div>
  //       )}
  //       {usersFetching || contactsFetching ? (
  //         <Box sx={{ width: '100%', padding: 2, textAlign: 'center' }}>
  //           <Typography
  //             sx={{
  //               fontStyle: 'italic',
  //            //   color: theme => theme.palette.text.secondary,
  //             }}
  //           >
  //             Syncing contacts...
  //           </Typography>
  //         </Box>
  //       ) : null}
  //     </Box>*/}
  //   </>
  // );
};

export const ContactNameDisplay = ({ contact, notification = undefined }) => {
  return (
    <div className="">
      <div className="flex h-5 items-center">
        {contact.info.firstName ? (
          contact.info.lastName?.length ? (
            <Typography
              sx={{
                //// color: theme => theme.palette.content.color,
                fontSize: '16px',
                display: 'inline-block',
              }}
            >
              {contact.info.firstName}&nbsp;
            </Typography>
          ) : (
            <Typography
              sx={{
                //// color: theme => theme.palette.content.color,
                fontSize: '16px',
                display: 'inline-block',
                fontWeight: 700,
              }}
            >
              {contact.info.firstName}
            </Typography>
          )
        ) : null}
        {contact.info.lastName ? (
          <Typography
            sx={{
              //// color: theme => theme.palette.content.color,
              fontSize: '16px',
              display: 'inline-block',
              fontWeight: 700,
              mr: 1,
            }}
          >
            {contact.info.lastName}{' '}
          </Typography>
        ) : null}
        {/*{notification ? <NotificationBadge /> : null}*/}
      </div>
      {contact.user ? (
        <div>
          <Typography
            variant="caption"
            sx={{
              //   color: theme => theme.palette.content.color,
              fontSize: '14px',
              display: 'block',
              opacity: 0.7,
            }}
          >
            Team Member
          </Typography>
        </div>
      ) : null}
      {contact.info?.company?.length ? (
        <div>
          {contact.info.firstName || contact.info.lastName ? (
            <Typography
              variant="caption"
              sx={{
                //   color: theme => theme.palette.content.color,
                fontSize: '14px',
                display: 'block',
                opacity: 0.7,
              }}
            >
              {contact.info?.company}
            </Typography>
          ) : (
            <Typography
              sx={{
                //// color: theme => theme.palette.content.color,
                fontSize: '16px',
                display: 'inline-block',
                fontWeight: 700,
              }}
            >
              {contact.info?.company}
            </Typography>
          )}
        </div>
      ) : null}
    </div>
  );
};

export const NotificationBadge = () => {
  return (
    <Tooltip arrow placement={'right'} title={'Unseen message'}>
      <span className="relative flex h-2.5 w-2.5 z-10">
        <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-teal-500"></span>
        <span className="animate-pulse duration-1000 absolute inline-flex h-full w-full rounded-full bg-teal-400 opacity-75"></span>
      </span>
    </Tooltip>
  );
};

const ExternalContactItem = props => {
  const { contact, onEdit } = props;
  const deleteContactMutation = useDeleteContact();
  const { callingDisabled } = useWebphoneSelector();

  const [state, dispatch] = useWebphoneContext();
  const { makeCall, eventBus } = state;
  const { openDialer } = useWebphoneCall();

  // if contact is user:
  // set primary extension if it exists in users callflow
  const { firstNumber, primaryExt } = useMemo(() => {
    let primaryExt, inMainCallflow;

    if (contact.user) {
      primaryExt = contact.user.doc.presence_id;
      inMainCallflow = contact.info.phoneNumbers.find(
        num => num.digits === primaryExt,
      );
    }

    const [firstNumber] = contact.info.phoneNumbers.filter(num =>
      contact.user ? num.digits.length > 6 : true,
    );

    return { firstNumber, primaryExt: inMainCallflow ? primaryExt : undefined };
  }, [contact]);

  const handleCall = digits => () => {
    if (callingDisabled) {
      window.alert('calling is disabled: missing input or output device');
      return;
    }

    openDialer(digits);
    // makeCall(
    //   digits,
    //   false, // isVideoCall
    //   undefined, // local tag (used default)
    //   undefined, // remote tag (uses default)
    //   {},
    // );
    // eventBus.emit('started-call');
  };

  // const {
  //   Dialog: EditContactDialog,
  //   DialogProps: EditContactDialogProps,
  //   toggleOpen: toggleOpenEditContactDialog,
  // } = useEditContactDialog();

  const [openEditContact, toggleOpenEditContactDialog] = useToggleReducer();
  const {
    Dialog: ConfirmationDialog,
    DialogProps: ConfirmationDialogProps,
    toggleOpen: toggleOpenConfirmation,
  } = useConfirmationDialog();
  const handleContactChange = () => {
    toggleOpenEditContactDialog();
    onEdit();
  };

  const handleDelete = () => {
    const deleteContactPromise = deleteContactMutation.mutateAsync(contact.id, {
      onSuccess: () => {
        toggleOpenConfirmation();
        onEdit();
      },
    });

    // toast.promise(deleteContactPromise, {
    //   pending: 'Removing contact...',
    //   success: 'Contact removed!',
    //   error: 'Error removing contact.',
    // });
  };

  // console.log('rendering contact item...');

  return (
    <Box sx={{ padding: 2 }}>
      <EditContactDialog
        open={openEditContact}
        onComplete={handleContactChange}
        onCancel={toggleOpenEditContactDialog}
        contact={contact}
      />
      <ConfirmationDialog
        {...ConfirmationDialogProps}
        onCancel={toggleOpenConfirmation}
        onConfirm={handleDelete}
        isLoading={deleteContactMutation.isLoading}
        loadingLabel={'Deleting contact...'}
        label={'Are you sure you would like to delete this contact?'}
        // key={'delete-confirmation-dialog'}
      />
      <Grid container alignItems="center" columnSpacing={1}>
        <Grid item>
          <AvatarCell
            resource={{
              doc: {
                first_name: contact.info.firstName,
                last_name: contact.info.lastName,
                company: contact.info.company,
              },
            }}
            size={40}
          />
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          {contact.user ? (
            // <Link
            //   component={RouterLink}
            //   // link={contact.user ? RouterLink : undefined}
            //   to={`/admin/users/view/${contact.user.id}`}
            //   color={'primary'}
            //   sx={{
            //     underline: 'hover',
            //     display: 'block',
            //   }}
            // >
            <ContactNameDisplay contact={contact} />
          ) : (
            // </Link>
            <div>
              <ContactNameDisplay contact={contact} />
            </div>
          )}
        </Grid>
        <Grid item sx={{ textAlign: 'right' }}>
          <Grid
            container
            alignItems={'center'}
            columnSpacing={2}
            wrap={'nowrap'}
          >
            <Grid item>
              {firstNumber ? (
                <PhoneNumberDisplay
                  key={firstNumber.id}
                  onClick={handleCall(firstNumber.digits)}
                  ptn={firstNumber.digits}
                />
              ) : null}
              {/*))}*/}
              {/* show primary ext if internal user and the ext exists in their main callflow*/}
              {primaryExt ? (
                <PhoneNumberDisplay
                  key={primaryExt}
                  onClick={handleCall(primaryExt)}
                  ptn={primaryExt}
                />
              ) : null}
              {!firstNumber && !primaryExt && (
                <Typography
                  sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                >
                  no numbers
                </Typography>
              )}
              {/*))}*/}
            </Grid>
            <Grid item>
              {contact.info.phoneNumbers.length > 1 ? (
                <Tooltip
                  arrow
                  title={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                      }}
                    >
                      {contact.info.phoneNumbers?.map((numData, i) => (
                        <PhoneNumberDisplay
                          key={i}
                          onClick={handleCall(numData.digits)}
                          ptn={numData.digits}
                        />
                      ))}
                    </Box>
                  }
                >
                  <Chip
                    label={`${contact.info.phoneNumbers.length} total`}
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                  />
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
          {/*{contact.info.phoneNumbers?.map(numData => (*/}
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              //// color: theme => theme.palette.primary.main,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {contact.user ? (
              <Tooltip title={'Contact is user on account'} arrow>
                <PersonIcon color={'inherit'} />
              </Tooltip>
            ) : (
              <ButtonDropdownMenu
                menuItems={[
                  { text: 'Edit', onClick: toggleOpenEditContactDialog },
                  { text: 'Delete', onClick: toggleOpenConfirmation },
                ]}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ExternalContactRow = props => {
  const { contact, onEdit, showBorder } = props;
  const deleteContactMutation = useDeleteContact();
  const { callingDisabled } = useWebphoneSelector();
  const pipeConvoUpsert = usePipeConvoUpsert();
  const { data: user } = useAuthenticatedUserQuery();
  const { data: account } = useAccountQuery();

  const numberInternal =
    user?.doc?.caller_id?.internal?.number ?? user?.doc?.presence_id;
  const numberExternal =
    user?.doc?.caller_id?.external?.number ??
    account?.doc?.caller_id?.external?.number;

  const [state, dispatch] = useWebphoneContext();
  const { makeCall, eventBus } = state;
  const { openDialer } = useWebphoneCall();
  const router = useHistory();
  // if contact is user:
  // set primary extension if it exists in users callflow
  const { firstNumber, primaryExt, phoneNumbers } = useMemo(() => {
    let primaryExt, inMainCallflow;

    if (contact.user) {
      primaryExt = contact.user.doc.presence_id;
      inMainCallflow = contact.info.phoneNumbers.find(
        num => num.digits === primaryExt,
      );
    }

    const phoneNumbers = contact.info.phoneNumbers.filter(num =>
      contact.user ? num.digits.length > 6 : true,
    );

    // console.log('contact phoneNumbers:', phoneNumbers);

    const [firstNumber] = phoneNumbers;

    return {
      firstNumber,
      primaryExt: inMainCallflow ? primaryExt : undefined,
      phoneNumbers,
    };
  }, [contact]);

  const handleCall = (digits, from) => () => {
    if (callingDisabled) {
      window.alert('calling is disabled: missing input or output device');
      return;
    }

    openDialer(digits, from);
    // makeCall(
    //   digits,
    //   false, // isVideoCall
    //   undefined, // local tag (used default)
    //   undefined, // remote tag (uses default)
    //   {},
    // );
    // eventBus.emit('started-call');
  };

  // const {
  //   Dialog: EditContactDialog,
  //   DialogProps: EditContactDialogProps,
  //   toggleOpen: toggleOpenEditContactDialog,
  // } = useEditContactDialog();

  const [openEditContact, toggleOpenEditContactDialog] = useToggleReducer();
  const {
    Dialog: ConfirmationDialog,
    DialogProps: ConfirmationDialogProps,
    toggleOpen: toggleOpenConfirmation,
  } = useConfirmationDialog();
  const handleContactChange = () => {
    toggleOpenEditContactDialog();
    onEdit();
  };

  const handleDelete = async () => {
    const deleteContactPromise = await deleteContactMutation.mutateAsync(
      contact.id,
      {
        onSuccess: () => {
          toggleOpenConfirmation();
          onEdit();
        },
      },
    );

    // toast.promise(deleteContactPromise, {
    //   pending: 'Removing contact...',
    //   success: 'Contact removed!',
    //   error: 'Error removing contact.',
    // });
  };

  // console.log('rendering contact item...');

  const name = [contact.info?.firstName, contact.info?.lastName]
    .filter(v => !!v?.length)
    .join(' ');
  const initials = name
    .split(' ')
    .map(n => n[0])
    .join('')
    .substring(0, 2);

  const isAdmin = user.doc.priv_level === 'admin';

  const pipesQuery = usePipesQuery({
    filters: {
      ...(isAdmin
        ? {}
        : {
            users: {
              path: [store.getState().auth.owner_id, 'enabled'],
              equals: true,
            },
          }),
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];

  const externalPipes = pipes.filter(p => p.type === 'external');
  const internalPipes = pipes.filter(
    p => p.type === 'personal' || p.type === 'shared',
  );

  console.log('internal pipes', internalPipes);

  const onSendMessage = async (number, as = undefined) => {
    // console.log('Submitting');

    const numberIsInternal = number.length < 6 ? true : false;

    let pipeId = as;
    if (!as) {
      if (numberIsInternal) {
        const pipe = internalPipes.find(pipe => pipe.key === numberInternal);
        pipeId = pipe.id;
      } else {
        const pipe = externalPipes.find(pipe => pipe.key === numberExternal);
        pipeId = pipe.id;
      }
    }

    // validate numbers
    // - do in form handling? elsewhere? also on server!
    let to = [number];

    // Get conversation for Pipe + Participants
    const convo = await pipeConvoUpsert.mutateAsync({
      data: { pipe_id: pipeId, participants: to },
    });

    console.log('CONVO created:', convo);
    if (convo.key) {
      router.push(`/pipe/${pipeId}/convo/${convo.key}`);
    } else {
      alert(
        'Sorry, failed with participants. Please try again, or contact support',
      );
    }

    // try {
    //   await sendMessage.mutateAsync({
    //     to: to,
    //     from: normalizeNumber(pipe.key),
    //     text: 'Example text',
    //   });
    //   callback();
    //   return false;
    // } catch (err: any) {
    //   console.error('Failed sending!', err, err.response);
    //   let msg = err?.response?.errors?.[0].message ?? '--no message--';
    //   alert(`Failed sending: ${msg}`);
    // }
  };

  return (
    <>
      <EditContactDialog
        open={openEditContact}
        onComplete={handleContactChange}
        onCancel={toggleOpenEditContactDialog}
        contact={contact}
      />
      <ConfirmationDialog
        {...ConfirmationDialogProps}
        onCancel={toggleOpenConfirmation}
        onConfirm={handleDelete}
        isLoading={deleteContactMutation.isLoading}
        loadingLabel={'Deleting contact...'}
        label={'Are you sure you would like to delete this contact?'}
        // key={'delete-confirmation-dialog'}
      />

      <TableRow showBorder={showBorder}>
        <TableCell className={'w-56 font-medium'}>
          <div className="inline-flex items-center space-x-2 text-md font-medium">
            <Avatar size={'sm'} initials={initials} />
            <p className={'truncate'}>{name}</p>
          </div>
        </TableCell>
        <TableCell className={'w-48'}>
          <div className={'flex space-x-2 items-center'}>
            {firstNumber ? (
              <PhoneNumberDisplay hideFlag ptn={firstNumber.number} />
            ) : null}
            {phoneNumbers.length > 1 ? (
              <Tooltip
                arrow
                title={
                  <>
                    {phoneNumbers.map(num => (
                      <div className={'text-sm'} key={num}>
                        <PhoneNumberDisplay hideFlag ptn={num.number} />
                      </div>
                    ))}
                  </>
                }
              >
                <div>
                  <Badge
                    color={'accent'}
                    variant={'fill'}
                    size={'sm'}
                    reverse
                  >{`${phoneNumbers.length} total`}</Badge>
                </div>
              </Tooltip>
            ) : null}
          </div>
        </TableCell>
        <TableCell className={'w-28'}>{primaryExt}</TableCell>
        <TableCell className={'w-28'}>
          {contact.user ? (
            contact.user.doc.priv_level === UserPrivLevel.Admin ? (
              <Badge color={'accent'} reverse size={'sm'} variant={'fill'}>
                Admin
              </Badge>
            ) : (
              <Badge color={'neutral'} reverse size={'sm'} variant={'fill'}>
                User
              </Badge>
            )
          ) : (
            <Badge color={'positive'} reverse size={'sm'} variant={'fill'}>
              External
            </Badge>
          )}
        </TableCell>
        <TableCell className={'w-52'}>{contact.info.company}</TableCell>
        <TableCell className={'w-64'}>
          <Tooltip arrow title={contact.user?.doc.username}>
            <p className={'truncate'}>{contact.user?.doc.username}</p>
          </Tooltip>
        </TableCell>
        <TableCell className={'flex-1 overflow-visible'}>
          <div
            className={
              'flex justify-end  overflow-visible space-x-2 items-center'
            }
          >
            {phoneNumbers.length ? (
              <IconButtonDropdown
                hideArrow
                size={'sm'}
                variant={'ghost'}
                color={'neutral'}
                menuItems={[
                  {
                    label: (
                      <div className={'text-neutral-60 italic text-sm pl-3'}>
                        Send message to...
                      </div>
                    ),
                    disabled: true,
                  },
                  ...(primaryExt
                    ? [
                        {
                          label: <div className={'pl-3'}>{primaryExt}</div>,
                          onClick: () => onSendMessage(primaryExt),
                        },
                      ]
                    : []),
                  ...phoneNumbers.map(num => ({
                    label: (
                      <div
                        className={
                          'flex space-x-1 w-full justify-between items-center'
                        }
                      >
                        <span>{num.label}</span>
                        <span className={'text-neutral-60'}>
                          <PhoneNumberDisplay hideFlag ptn={num.number} />
                        </span>
                      </div>
                    ),
                    onClick: () => onSendMessage(num.number),
                    subOptions:
                      externalPipes.length > 1
                        ? [
                            {
                              label: (
                                <span
                                  className={'text-neutral-60 italic text-sm'}
                                >
                                  Send message from...
                                </span>
                              ),
                              disabled: true,
                            },
                            ...externalPipes.map(pipe => ({
                              label: (
                                <div className={'flex space-x-1 items-center'}>
                                  {pipe.metadata.name ? (
                                    <Emoji
                                      unified={pipe.metadata?.emoji || '1f937'}
                                      size={16}
                                    />
                                  ) : null}
                                  {pipe.metadata.name ? (
                                    <span>{pipe.metadata.name}</span>
                                  ) : null}
                                  <span className={'text-neutral-60'}>
                                    <PhoneNumberDisplay
                                      hideFlag
                                      ptn={pipe.key}
                                    />
                                  </span>
                                </div>
                              ),
                              onClick: () => onSendMessage(num.number, pipe.id),
                            })),
                          ]
                        : undefined,
                  })),
                ]}
              >
                <Message fr={undefined} />
              </IconButtonDropdown>
            ) : (
              <IconButton
                onClick={() =>
                  onSendMessage(firstNumber ? firstNumber?.digits : primaryExt)
                }
                size={'sm'}
                variant={'ghost'}
                color={'neutral'}
              >
                <Message fr={undefined} />
              </IconButton>
            )}
            {phoneNumbers.length ? (
              <IconButtonDropdown
                hideArrow
                size={'sm'}
                variant={'ghost'}
                color={'accent'}
                menuItems={[
                  {
                    label: (
                      <div className={'text-neutral-60 italic text-sm pl-3'}>
                        Call...
                      </div>
                    ),
                    disabled: true,
                  },
                  ...(primaryExt
                    ? [
                        {
                          label: <div className={'pl-3'}>{primaryExt}</div>,
                          onClick: handleCall(primaryExt),
                        },
                      ]
                    : []),
                  ...phoneNumbers.map(num => ({
                    label: (
                      <div
                        className={
                          'flex space-x-1 w-full justify-between items-center'
                        }
                      >
                        <span>{num.label}</span>
                        <span className={'text-neutral-60'}>
                          <PhoneNumberDisplay hideFlag ptn={num.number} />
                        </span>
                      </div>
                    ),
                    onClick: handleCall(num.number),
                    subOptions:
                      externalPipes.length > 1
                        ? [
                            {
                              label: (
                                <span
                                  className={'text-neutral-60 italic text-sm'}
                                >
                                  From...
                                </span>
                              ),
                              disabled: true,
                            },
                            ...externalPipes.map(pipe => ({
                              label: (
                                <div className={'flex space-x-1 items-center'}>
                                  {pipe.metadata.name ? (
                                    <Emoji
                                      unified={pipe.metadata?.emoji || '1f937'}
                                      size={16}
                                    />
                                  ) : null}
                                  {pipe.metadata.name ? (
                                    <span>{pipe.metadata.name}</span>
                                  ) : null}
                                  <span className={'text-neutral-60'}>
                                    <PhoneNumberDisplay
                                      hideFlag
                                      ptn={pipe.key}
                                    />
                                  </span>
                                </div>
                              ),
                              onClick: handleCall(num.number, pipe.key),
                            })),
                          ]
                        : undefined,
                  })),
                ]}
              >
                <Phone fr={undefined} />
              </IconButtonDropdown>
            ) : (
              <IconButton
                onClick={handleCall(
                  firstNumber ? firstNumber?.digits : primaryExt,
                )}
                size={'sm'}
                variant={'ghost'}
                color={'accent'}
                disabled={!firstNumber && !primaryExt}
              >
                <Phone fr={undefined} />
              </IconButton>
            )}

            <IconButtonDropdown
              hideArrow
              className={`${!isAdmin && contact?.user ? 'opacity-0' : ''}`}
              disabled={!isAdmin && contact?.user}
              align={'left'}
              variant={'ghost'}
              color={'neutral'}
              menuItems={
                contact?.user
                  ? [
                      {
                        label: 'Edit User Line',
                        onClick: () =>
                          router.push(
                            `/settings/pipes/${
                              internalPipes.find(
                                pipe =>
                                  pipe.key === contact.user.doc.presence_id,
                              )?.id
                            }`,
                          ),
                      },
                    ]
                  : [
                      { label: 'Edit', onClick: toggleOpenEditContactDialog },
                      { label: 'Delete', onClick: toggleOpenConfirmation },
                    ]
              }
            >
              <MoreVert fr={undefined} />
            </IconButtonDropdown>
          </div>
        </TableCell>
      </TableRow>
      {/*<Grid container alignItems="center" columnSpacing={1}>*/}
      {/*  <Grid item>*/}
      {/*    <AvatarCell*/}
      {/*      resource={{*/}
      {/*        doc: {*/}
      {/*          first_name: contact.info.firstName,*/}
      {/*          last_name: contact.info.lastName,*/}
      {/*          company: contact.info.company,*/}
      {/*        },*/}
      {/*      }}*/}
      {/*      size={40}*/}
      {/*    />*/}
      {/*  </Grid>*/}
      {/*  <Grid item sx={{ flex: 1 }}>*/}
      {/*    {contact.user ? (*/}
      {/*      // <Link*/}
      {/*      //   component={RouterLink}*/}
      {/*      //   // link={contact.user ? RouterLink : undefined}*/}
      {/*      //   to={`/admin/users/view/${contact.user.id}`}*/}
      {/*      //   color={'primary'}*/}
      {/*      //   sx={{*/}
      {/*      //     underline: 'hover',*/}
      {/*      //     display: 'block',*/}
      {/*      //   }}*/}
      {/*      // >*/}
      {/*      <ContactNameDisplay contact={contact} />*/}
      {/*    ) : (*/}
      {/*      // </Link>*/}
      {/*      <div>*/}
      {/*        <ContactNameDisplay contact={contact} />*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </Grid>*/}
      {/*  <Grid item sx={{ textAlign: 'right' }}>*/}
      {/*    <Grid*/}
      {/*      container*/}
      {/*      alignItems={'center'}*/}
      {/*      columnSpacing={2}*/}
      {/*      wrap={'nowrap'}*/}
      {/*    >*/}
      {/*      <Grid item>*/}
      {/*        {firstNumber ? (*/}
      {/*          <PhoneNumberDisplay*/}
      {/*            key={firstNumber.id}*/}
      {/*            onClick={handleCall(firstNumber.digits)}*/}
      {/*            ptn={firstNumber.digits}*/}
      {/*          />*/}
      {/*        ) : null}*/}
      {/*        /!*))}*!/*/}
      {/*        /!* show primary ext if internal user and the ext exists in their main callflow*!/*/}
      {/*        {primaryExt ? (*/}
      {/*          <PhoneNumberDisplay*/}
      {/*            key={primaryExt}*/}
      {/*            onClick={handleCall(primaryExt)}*/}
      {/*            ptn={primaryExt}*/}
      {/*          />*/}
      {/*        ) : null}*/}
      {/*        {!firstNumber && !primaryExt && (*/}
      {/*          <Typography*/}
      {/*            sx={{ fontStyle: 'italic', color: 'text.secondary' }}*/}
      {/*          >*/}
      {/*            no numbers*/}
      {/*          </Typography>*/}
      {/*        )}*/}
      {/*        /!*))}*!/*/}
      {/*      </Grid>*/}
      {/*      <Grid item>*/}
      {/*        {contact.info.phoneNumbers.length > 1 ? (*/}
      {/*          <Tooltip*/}
      {/*            arrow*/}
      {/*            title={*/}
      {/*              <Box*/}
      {/*                sx={{*/}
      {/*                  display: 'flex',*/}
      {/*                  flexDirection: 'column',*/}
      {/*                  alignItems: 'end',*/}
      {/*                }}*/}
      {/*              >*/}
      {/*                {contact.info.phoneNumbers?.map((numData, i) => (*/}
      {/*                  <PhoneNumberDisplay*/}
      {/*                    key={i}*/}
      {/*                    onClick={handleCall(numData.digits)}*/}
      {/*                    ptn={numData.digits}*/}
      {/*                  />*/}
      {/*                ))}*/}
      {/*              </Box>*/}
      {/*            }*/}
      {/*          >*/}
      {/*            <Chip*/}
      {/*              label={`${contact.info.phoneNumbers.length} total`}*/}
      {/*              variant={'contained'}*/}
      {/*              color={'primary'}*/}
      {/*              size={'small'}*/}
      {/*            />*/}
      {/*          </Tooltip>*/}
      {/*        ) : null}*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*    /!*{contact.info.phoneNumbers?.map(numData => (*!/*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={1}>*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        //// color: theme => theme.palette.primary.main,*/}
      {/*        justifyContent: 'center',*/}
      {/*        width: '100%',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {contact.user ? (*/}
      {/*        <Tooltip title={'Contact is user on account'} arrow>*/}
      {/*          <PersonIcon color={'inherit'} />*/}
      {/*        </Tooltip>*/}
      {/*      ) : (*/}
      {/*        <ButtonDropdownMenu*/}
      {/*          menuItems={[*/}
      {/*            { text: 'Edit', onClick: toggleOpenEditContactDialog },*/}
      {/*            { text: 'Delete', onClick: toggleOpenConfirmation },*/}
      {/*          ]}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </Box>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </>
  );
};

export default CombinedContacts;
