import { useBuilderContext } from '../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { useCallRouteEditor } from './CallflowContext';
import { getAtPath } from '../../../../../utilities';
import { Calendar, Cancel, Clock, DeleteCircle, TimerOff } from 'iconoir-react';
import { SidebarButton } from './ModuleButton';
import { AddModuleButton } from './AddModuleButton';
import React from 'react';
import { MenuKeyMap } from './CallflowSidebar';
import { SidebarSeparator } from './SidebarSeparator';

export const ScheduleOptionButton = ({ route, expand, rootPath, modules }) => {
  const { selectedElementKey, setSelectedElementKey, invalidModules } =
    useBuilderContext();
  const { selectedElement, elements, callflow } = useCallRouteEditor();
  const segments = route.split('.');
  const targetIndex = segments.findIndex(val => val === 'routes') + 1;
  const target = segments[targetIndex];
  const targetPath =
    rootPath + segments.slice(0, targetIndex).join('.') + '.' + target;
  const targetModule = getAtPath(callflow, targetPath);

  let name, icon;
  const scheduleModule = getAtPath(
    callflow,
    rootPath + '.' + route.split('.')[1],
  );

  const schedule = callflow.schedules[scheduleModule?.data?.schedule_id];

  switch (target) {
    case 'fallback':
      icon = <DeleteCircle fr={undefined} />;
      name = schedule?.fallback.name ?? 'Fallback';
      break;
    default:
      icon = <Calendar fr={undefined} />;
      name =
        schedule?.categories.find(cat => cat.id === target)?.name ?? `Route`;
      break;
  }

  const targetModulesPath =
    targetPath + '.callflow.message_strategy.data.modules';

  const nextElement = modules?.length
    ? null
    : elements.find(elem => elem.data?.modifyPath === targetPath + '.callflow');

  return (
    <>
      <SidebarButton
        expand={expand}
        onClick={() => {
          setSelectedElementKey(targetModulesPath);
        }}
        isActive={targetModulesPath === selectedElementKey}
        icon={icon}
        name={targetModule?.name || name}
      />
      {!nextElement ? null : (
        <>
          {nextElement?.type === 'InsertNode' ? (
            <>
              <SidebarSeparator />

              <AddModuleButton expanded={expand} data={nextElement.data} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};
