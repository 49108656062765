import React, { useEffect, useState, useRef } from 'react';
import { useAuthSelector } from 'app/data/auth';
import { useWebphoneSelector, useWebphoneSlice } from 'app/data/webphone';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import deviceQueryKeys from 'app/hooks/queries/device/deviceQueryKeys';
import { useQueryClient } from 'react-query';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from 'app/design';
import {
  AccountBox as AccountBoxIcon,
  // Dialpad as DialpadIcon,
  History as HistoryIcon,
  // Phone as PhoneIcon,
  // Settings as SettingsIcon,
} from 'app/design/icons-material';
import {
  Phone as PhoneIcon,
  Dialpad as DialpadIcon,
  Settings as SettingsIcon,
  Cancel as CancelIcon,
  RightRoundArrow as RightRoundArrowIcon,
  PhoneDisabled as PhoneDisabledIcon,
  WarningTriangle,
} from 'iconoir-react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useWebphoneContext } from '../Store';
import { CallDetailView } from './CallDetailView';
import { CallHistoryView } from './CallHistoryView';
import { CallsView } from './CallsView';
import { ContactsView } from './ContactsView';
import { DialView } from './DialView';
import { ParkingView } from './ParkingView';
import { Permissions } from './Permissions';
import { SettingsView } from './SettingsView';
import { VideoFullView } from './VideoFullView';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useCreateWebphone } from './CreateWebphone';
import { Alert, Avatar, Badge, Button } from '../../../design-lib';
import { PhoneNumberDisplay } from '../../PhoneNumberDisplay';
import { Emoji } from 'emoji-picker-react';
import constants from '../../../constants';

let hasInit = false;

export const useWebphoneInit = () => {
  // const hasInit = useRef(null);
  const needsReconnect = useRef(false);
  const dispatchRedux = useDispatch();
  const [webphoneState, dispatchWebphone] = useWebphoneContext();
  const { actions: webphoneActions } = useWebphoneSlice();
  const {
    mainTab,
    sip,
    calls,
    // parked_calls,
    isLineConnected,
    isRegistered,
    registerSip,
    last_register_key,
    makeCall,
    getErrorMessage,
    eventBus,
  } = webphoneState;

  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  const owner_id = authState?.auth_token_decoded?.owner_id;
  const updateDevice = useUpdateDevicePartial();
  const webphoneQuery = useListDevicesQuery({
    take: 1,
    filters: {
      raw: [
        {
          or: [
            {
              variables: {
                docFilters: [
                  ['owner_id', '=', owner_id],
                  ['device_type', '=', 'webphone'],
                ],
              },
            },
            {
              variables: {
                docFilters: [
                  ['owner_id', '=', owner_id],
                  ['subtype', '=', 'webphone'],
                ],
              },
            },
          ],
        },
      ],
    },
  });

  const device = webphoneQuery.data?.devices?.length
    ? webphoneQuery.data?.devices[0]
    : undefined;

  const enableWebphone = async () => {
    // enable web phone
    const resp = await updateDevice.mutateAsync({
      id: device.id,
      enabled: true,
    });

    // error handled in jsx
    if (resp.status !== 'success') return;

    // refetch webphone
    await webphoneQuery.refetch();
    await queryClient.refetchQueries(deviceQueryKeys.byId(device.id));
  };

  const handleConnect = async (reconnect = false) => {
    if (hasInit && !reconnect) {
      console.log('webphone: Already hasInit for webphone');
      return;
    }
    // console.log('running connect');

    // TODO: update the server w/ our data
    // - on reconnect, we refetch data from the server to see if we should auto-reconnect
    //   - ie, has the "last_register_key" stayed what we set it to?
    //     - if not, we do NOT reconnect!

    // // same last_register_key on device?
    // const currentDeviceKey = device.doc.app_cio?.last_register_key;
    // if (currentDeviceKey === last_register_key) {
    //   console.log(
    //     'Same last_register_key for local device, not updating on server before re-registering',
    //   );
    //   hasInit = true;
    //   dispatchWebphone({
    //     type: 'sip:update',
    //     payload: { device, last_register_key },
    //   });
    //   dispatchRedux(webphoneActions.set_device(device));
    //   return false;
    // }

    // make server request here to set to a value we want
    // - store that value in redux (but NOT in localstorage!!) also (should store in window.session for this TAB only!)
    const new_last_register_key = uuidv4();

    const resp = await updateDevice.mutateAsync({
      id: device.id,
      app_cio: {
        last_register_key: new_last_register_key,
      },
    });

    if (resp.status !== 'success') {
      // console.log('running connect 1');
      return;
    }

    // console.log('running connect 2');
    hasInit = true;

    // await KazooSDK.resourceDevicePatch({
    //   id: device.id,
    //   app_cio: {
    //     last_register_key,
    //   },
    // });
    dispatchWebphone({
      type: 'sip:update',
      payload: { device, last_register_key: new_last_register_key },
    });
    // dispatchRedux({
    //   type: 'SET_WEBPHONE_DEVICE',
    //   payload: device,
    // });
    // console.log('webphone: set_device', device);
    dispatchRedux(webphoneActions.set_device(device));
  };

  // handle autoRegister
  // - ie, do not re-register!
  // const firstRegister = useRef(null);
  // - TODO: figure out how to run this in a more intelligent way...current seems error-prone to rely on react rendering
  useEffect(() => {
    (async () => {
      if (
        isLineConnected &&
        isLineConnected() &&
        isRegistered &&
        !isRegistered()
      ) {
        //} && sipStatus !== SIP_STATUS_REGISTERED) {

        // TODO: ensure the register key is correct (ie, latest on the device!)
        const headers = new Headers();
        // @ts-ignore
        headers.set('X-AUTH-TOKEN', authState.auth_token);
        headers.set('Content-Type', 'application/json');
        try {
          const response = await fetch(
            `${constants.env.REACT_APP_CIO_API_SERVER}/api/kproxy/accounts/${authState.account_id}/devices/${device.id}`,
            {
              method: 'GET',
              headers,
            },
          );
          const resp = await response.json();

          if (
            resp.status !== 'success' ||
            resp.data.app_cio.last_register_key !== last_register_key
          ) {
            // dont register, newer key present
            return;
          }
        } catch (e) {}

        // // disabled device?
        // if (!d.enabled) {
        //   return;
        // }

        // // verify that we have a valid "last_register_key" value!
        // if (d.app_cio?.last_register_key === state.last_register_key) {
        //   console.log('registering webphone cuz last_register_key is the same');
        registerSip();
        dispatchWebphone({
          type: 'loading',
          payload: false,
        });
        // } else {
        //   console.log(
        //     'NOT REGISTERING cuz webphone last_register_key has changed',
        //   );
        // }
      } else {
        // console.log('Line not connected, not registering');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLineConnected && isLineConnected(),
    isRegistered && isRegistered(),
    webphoneState?.last_register_key,
  ]);

  // on disconnect, remove the device
  // TODO: have a different way of handling?
  useEffect(() => {
    if (getErrorMessage && getErrorMessage() === 'disconnected') {
      // console.log('webphone: Disconnected, clearing (to trip ua.stop)');
      dispatchWebphone({
        type: 'sip:clear',
        payload: {},
      });

      // check reason for disconnect (ie was webphone claimed or generic disconnect)
      const checkRegistered = async () => {
        const headers = new Headers();
        // @ts-ignore
        headers.set('X-AUTH-TOKEN', authState.auth_token);
        headers.set('Content-Type', 'application/json');
        try {
          const response = await fetch(
            `${constants.env.REACT_APP_CIO_API_SERVER}/api/kproxy/accounts/${authState.account_id}/devices/${device.id}`,
            {
              method: 'GET',
              headers,
            },
          );
          const resp = await response.json();

          if (
            resp.status !== 'success' ||
            resp.data.app_cio.last_register_key !== last_register_key
          ) {
            console.log('hasInit = false 1', resp.status, {
              last_register_key,
              app_cio: resp.data.app_cio.last_register_key,
            });
            // hasInit = false;
            return;
          }
        } catch (e) {
          console.log('hasInit = false 2');
          // hasInit = false;
          return;
        }

        // last register key is valid so try to reconnect
        needsReconnect.current = true;
      };

      checkRegistered();
      // needsReconnect.current = true;
      dispatchRedux(webphoneActions.set_device(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getErrorMessage && getErrorMessage()]);

  useEffect(() => {
    if (device && !hasInit) {
      // console.log('webphone: autoconnecting');
      handleConnect();
    }
  }, [device]);

  useEffect(() => {
    if (needsReconnect.current) {
      console.log('ATTEMPTING TO RECONNECT!');
      hasInit = false;
      needsReconnect.current = false;
      setTimeout(() => {
        handleConnect();
      }, 100);
    }
  }, [needsReconnect.current]);

  // create new device if necessary
  const { createOnce, isCreating } = useCreateWebphone(
    false,
    webphoneQuery.refetch,
  );
  useEffect(() => {
    if (!device && !webphoneQuery.isLoading && !webphoneQuery.isError) {
      // console.log('webphone: creating webphone');
      createOnce();
    } else {
      // console.log('webphone: not creating');
    }
  }, [device, webphoneQuery.isLoading, webphoneQuery.isError]);

  let results = {
    isCreating,
    enableWebphone,
    handleConnect,
    hasInit: hasInit,
    deviceQueryIsLoading: webphoneQuery.isLoading,
    needsDeviceCreate: device ? false : true,
    deviceEnabled: device?.doc?.enabled ? true : false,
    device,
  };

  return results;
};

export const WebphoneUI = props => {
  const { open, device } = props; // the device we SHOULD use

  const { hasInit, handleConnect } = useWebphoneInit(device);

  // TODO: do a check to see if we should be registering the device immediately
  // - on page load, or when the Phone icon is clicked
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [showWsVideo, setWsVideo] = useState(false);

  // connecting immediately after sidebar/drawer is clicked (not waiting for "Connect Webphone" button to be clicked!)
  useEffect(() => {
    if (!hasInit && !hasBeenOpened) {
      setHasBeenOpened(true);
      handleConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const dispatchRedux = useDispatch();

  // shouldn't need this  handled in webphone.js
  // useEffect(() => {
  //   if (device.doc.enabled) handleConnect();
  // }, [device.doc.enabled]);

  const [webphoneState, dispatchWebphone] = useWebphoneContext();
  const {
    mainTab,
    sip,
    calls,
    // parked_calls,
    isLineConnected,
    isRegistered,
    registerSip,
    makeCall,
    getErrorMessage,
    eventBus,
  } = webphoneState;

  // const { data: parkingData } = useCallsParkedQuery();
  // const parked_calls = parkingData?.callsParked ?? [];

  const { actions: webphoneActions } = useWebphoneSlice();
  const { actions: localActions } = useLocalSlice();

  // console.log('webphoneState STATE:', webphoneState);

  // TODO: have a switch/slider for Enabled (doesnt actually change on the server, simply changes locally if we want to connect/register!)
  // - if enabled==false then dont allow easy connection
  // const [hasDetailView, setDetailView] = useState(false);

  // refs
  const localVideoEl = React.useRef(null);
  const remoteVideoEl = React.useRef(null);
  // const localConferenceVideoEl = React.useRef(null);
  // const remoteConferenceVideoEl = React.useRef(null);

  // const isDialer = calls?.length === 0;
  const incomingCallRinging = calls?.find(call => call.isRinging() === true);
  // const isRinging = incomingCallRinging !== undefined && calls?.length === 1;
  // const isActive = !isDialer && !isRinging;
  let activeCall = calls?.find(call => call.isMediaActive() === true);
  if (activeCall === undefined && calls?.length > 0) {
    activeCall = calls[calls.length - 1];
  }

  const changeTab = newTab => {
    dispatchWebphone({
      type: 'sip:update',
      payload: {
        mainTab: newTab,
      },
    });
  };

  useEffect(() => {
    const changeToCalls = () => changeTab('calls');
    eventBus.on('started-call', changeToCalls);
    return () => {
      eventBus.removeListener('started-call', changeToCalls);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // show dialer when there is a new incoming call
  useEffect(() => {
    if (incomingCallRinging) changeTab('calls');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingCallRinging]);

  useEffect(() => {
    if (calls?.length === 0 && showWsVideo) {
      setWsVideo(false);
    }
    //   if (activeCall && !hasDetailView && activeCall.hasLocalVideo()) {
    //     setDetailView(true);
    //   }
  }, [calls?.length, showWsVideo]);

  // const toggleDetailView = () => {
  //   setDetailView(!hasDetailView);
  // };
  const callEventHandler = (event, params) => {
    const call = params.obj;
    switch (event) {
      case 'input.stream.opened':
      case 'input.stream.modified':
        if (call !== undefined && params.video) {
          localVideoEl.current.srcObject = call.getInputMediaStream();
        }
        break;
      case 'output.stream.opened':
      case 'output.stream.modified':
        if (call !== undefined && params.video) {
          remoteVideoEl.current.srcObject = call.getOutputMediaStream();
        }
        break;
      default:
    }
  };

  const onDial = (target, isVideoCall) => {
    makeCall(target, isVideoCall, {}, callEventHandler);
    changeTab('calls');
  };
  const onDialConference = (target, isVideoCall, additionalInfo) => {
    makeCall(target, isVideoCall, additionalInfo, callEventHandler);
    changeTab('calls');
  };
  // const onAnswer = isVideoCall => {
  //   incomingCallRinging.accept(true, isVideoCall, callEventHandler);
  // };

  const handleTabChange = (event, newValue) => {
    // setTabValue(newValue);
    changeTab(newValue);
  };

  const onExpandVideo = () => {
    setWsVideo(true);
  };
  const onCloseFullView = onEndCall => {
    if (onEndCall) {
      activeCall.hangup();
    }
    setWsVideo(false);
  };

  const { device: webphone_device } = useWebphoneSelector();
  // const { webphone_device } = useSelector(state => ({
  //   webphone_device: state.tmp.webphone_device,
  // }));
  // console.log('webphone_device:', webphone_device);
  // console.log('sip:', sip);
  const message = getErrorMessage && getErrorMessage();
  // if (message === 'disconnected' || message === 'Authentication Error') {
  //   return (
  //     <Container style={{ height: '100vh', background: '#F8FDFD' }}>
  //       <Box
  //         style={{
  //           textAlign: 'center',
  //           padding: '0',
  //           display: 'flex',
  //           flexDirection: 'column',
  //           height: '100%',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //         }}
  //       >
  //         <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //           <ErrorOutlineIcon color={'error'} fontSize={'20'} />
  //           <Typography color={'error'} variant="h6">
  //             Error Connecting Webphone...
  //           </Typography>
  //         </Box>
  //         <Typography variant="body1">
  //           Refresh browser to retry connection
  //         </Typography>
  //       </Box>
  //     </Container>
  //   );
  // }

  // if (!webphone_device) {
  //   return (
  //     <Container style={{ height: '100vh', background: '#F8FDFD' }}>
  //       <Grid container style={{ height: '100%' }} alignItems="center">
  //         <Grid item>
  //           <div style={{ textAlign: 'center', padding: '0 12px' }}>
  //             <CircularProgress size={40} />
  //             <br />
  //             <Typography variant="h6">Connecting Webphone...</Typography>
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </Container>
  //   );
  // }

  // TODO: favicon handler for updating to show that this tab has the webphone active
  // - add a little "phone" to this tab (changing the favicon only affects this tab!)
  // - good example: https://reactgo.com/react-change-favicon-dynamically/

  // TODO: isRegistered status (for outgoing calls)
  const activeNum = calls?.filter(
    c => !c.isRinging() && c.isActive() && !c.isOnLocalHold(),
  ).length;
  const ringingNum = calls?.filter(c => c.isRinging()).length;
  const onHoldNum = calls?.filter(c => c.isOnLocalHold()).length;
  const outgoingNotActiveYetNum = calls?.filter(
    c => !c.isActive() && !c.isRinging(),
  ).length;
  // console.log({ activeNum, ringingNum, onHoldNum, calls: calls?.length });
  return (
    <div
      className="
      flex flex-col
      bg-white rounded-lg
      border-2 border-solid border-accent-20
      drop-shadow"
    >
      <div className="drag-handle flex pl-4 pr-3 justify-between align-center cursor-move p-1 pb-2 pt-2">
        <div className="">
          <div className="inline-block test-lg font-medium mr-2">Call</div>
          {/* TODO: have this header area updateable by the currently-selected view? */}
          {mainTab === 'dialer' ? (
            <div className="inline-block text-md text-neutral-400">New</div>
          ) : null}
          {mainTab === 'calls' ? (
            <div className="inline-block text-md text-neutral-400">
              {[
                activeNum ? `${activeNum} active` : false,
                onHoldNum ? `${onHoldNum} on hold` : false,
              ]
                .filter(Boolean)
                .join(', ')}
            </div>
          ) : null}
          {mainTab === 'settings' ? (
            <div className="inline-block text-md text-neutral-400">
              Settings
            </div>
          ) : null}
        </div>
        <div className="flex space-x-1">
          {calls?.length ? (
            <div
              className={[
                'rounded hover:bg-neutral-200 cursor-pointer grid place-items-center p-1',
                mainTab === 'calls' ? 'bg-neutral-300' : '',
                // calls?.length ? 'bg-neutral-300' : '',
              ].join(' ')}
              onClick={e => changeTab('calls')}
            >
              <PhoneIcon height={18} width={18} />
            </div>
          ) : null}
          <div
            className={[
              'rounded hover:bg-neutral-200 cursor-pointer grid place-items-center p-1',
              mainTab === 'dialer' ? 'bg-neutral-300' : '',
            ].join(' ')}
            onClick={e => changeTab('dialer')}
          >
            <DialpadIcon height={18} width={18} />
          </div>
          <div
            className={[
              'rounded hover:bg-neutral-200 cursor-pointer grid place-items-center p-1',
              mainTab === 'settings' ? 'bg-neutral-300' : '',
            ].join(' ')}
            onClick={e => changeTab('settings')}
          >
            <SettingsIcon height={18} width={18} />
          </div>
          <div
            className={[
              'rounded grid place-items-center p-1',
              calls?.length
                ? 'text-neutral-300 cursor-not-allowed'
                : 'hover:bg-neutral-200 cursor-pointer',
            ].join(' ')}
            onClick={() =>
              dispatchRedux(localActions.set_webphone_visible(false))
            }
          >
            <CancelIcon height={18} width={18} />
          </div>
        </div>
      </div>
      <WebphoneError onReconnect={handleConnect} />
      <Permissions />
      {ringingNum ? (
        <CallsView
          show
          show_only_ringing
          localVideoRef={localVideoEl}
          remoteVideoRef={remoteVideoEl}
        />
      ) : null}
      {mainTab !== 'calls' &&
      (activeNum || onHoldNum || outgoingNotActiveYetNum) ? (
        <div
          className="p-2 text-center text-white text-base font-medium bg-blue-500 cursor-pointer"
          onClick={e => changeTab('calls')}
        >
          Active Calls &gt;
        </div>
      ) : null}
      <div className="">
        <DialView
          show={mainTab === 'dialer'}
          dial={onDial}
          makeCall={makeCall}
          dialConference={onDialConference}
        />
        <CallsView
          show={mainTab === 'calls'}
          // toggleDetailView={toggleDetailView}
          localVideoRef={localVideoEl}
          remoteVideoRef={remoteVideoEl}
        />
        <ParkingView
          show={mainTab === 'parking'}
          // toggleDetailView={toggleDetailView}
        />
        <SettingsView
          show={mainTab === 'settings'}
          // toggleDetailView={toggleDetailView}
        />
      </div>
    </div>
  );

  // return (
  //   <div className="">
  //     {isRegistered ? (
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'column-reverse',
  //
  //           flexWrap: 'no-wrap',
  //           height: '100vh',
  //           background: '#F8FDFD',
  //         }}
  //       >
  //         <Box
  //           style={{
  //             color: 'white',
  //           }}
  //           sx={{
  //             boxShadow: '-20px -40px -30px -30px rgba(27, 125, 144, 0.7)',
  //           }}
  //         >
  //           <Tabs
  //             sx={{
  //               background: '#1B7D90',
  //               // height: '80px',
  //               zIndex: 100,
  //               alignItems: 'center',
  //             }}
  //             variant={'fullWidth'}
  //             value={mainTab}
  //             onChange={handleTabChange}
  //             textColor={'inherit'}
  //             TabIndicatorProps={{ sx: { display: 'none' } }}
  //           >
  //             <Tab
  //               value="calls"
  //               label={'Calls'}
  //               icon={<PhoneIcon fontSize={'large'} />}
  //               style={{ textTransform: 'none', minWidth: 'auto' }}
  //             />
  //             <Tab
  //               value="history"
  //               label={'Recent'}
  //               icon={<HistoryIcon fontSize={'large'} />}
  //               style={{ textTransform: 'none', minWidth: 'auto' }}
  //             />
  //             {/* <Tab
  //               value="contacts"
  //               label={'Contacts'}
  //               icon={<AccountBoxIcon fontSize={'large'} />}
  //               style={{ textTransform: 'none', minWidth: 'auto' }}
  //             />*/}
  //             <Tab
  //               value="dialer"
  //               label={'Dialer'}
  //               icon={<DialpadIcon fontSize={'large'} />}
  //               style={{ textTransform: 'none', minWidth: 'auto' }}
  //             />
  //             {/*<Tab
  //               value="parking"
  //               label={`Parking (${parked_calls.length})`}
  //               style={{ textTransform: 'none', minWidth: 'auto' }}
  //             />*/}
  //             <Tab
  //               icon={<SettingsIcon fontSize={'large'} />}
  //               value="settings"
  //               label={'Settings'}
  //               style={{ textTransform: 'none', minWidth: 'auto' }}
  //             />
  //           </Tabs>
  //         </Box>
  //         <div>
  //           <Divider />
  //         </div>
  //         <div
  //           style={{
  //             flex: 1,
  //             overflowY: 'hidden',
  //             width: 480,
  //             background: '#F8FDFD',
  //           }}
  //         >
  //           <DialView
  //             show={mainTab === 'dialer'}
  //             dial={onDial}
  //             dialConference={onDialConference}
  //           />
  //           <CallsView
  //             show={mainTab === 'calls'}
  //             // toggleDetailView={toggleDetailView}
  //             localVideoRef={localVideoEl}
  //             remoteVideoRef={remoteVideoEl}
  //           />
  //           <ContactsView
  //             show={mainTab === 'contacts'}
  //             dial={onDial}
  //             dialConference={onDialConference}
  //             // toggleDetailView={toggleDetailView}
  //           />
  //           <CallHistoryView
  //             show={mainTab === 'history'}
  //             dial={onDial}
  //             dialConference={onDialConference}
  //             // toggleDetailView={toggleDetailView}
  //           />
  //           <ParkingView
  //             show={mainTab === 'parking'}
  //             // toggleDetailView={toggleDetailView}
  //           />
  //           <SettingsView
  //             show={mainTab === 'settings'}
  //             // toggleDetailView={toggleDetailView}
  //           />
  //         </div>
  //         {/* TODO: instead of passing in the ref,
  //         the components below should request a copy
  //         of the video stream and point it at their
  //         own <video> elements  */}
  //         {/* Active Call Detail (Video-only) */}
  //
  //         <CallDetailView
  //           call={activeCall}
  //           wsVideo={showWsVideo}
  //           onExpand={onExpandVideo}
  //           localVideoRef={localVideoEl}
  //           remoteVideoRef={remoteVideoEl}
  //         />
  //         {showWsVideo && (
  //           <VideoFullView call={activeCall} onStop={onCloseFullView} />
  //         )}
  //         <Permissions />
  //         {/* Conference Room (when maximized) */}
  //         {/* {activeCall?._additionalInfo?.conference ? ( */}
  //         {/*
  //         TODO: UNCOMMENT THIS NICK (only commented out to get working)
  //         <ConferenceRoom
  //           // conference={activeCall._additionalInfo.conference}
  //           call={activeCall}
  //           localVideoRef={localConferenceVideoEl}
  //           remoteVideoRef={remoteConferenceVideoEl}
  //         />*/}
  //       </div>
  //     ) : (
  //       <>
  //         {/* View for "Registering"  */}
  //         <Grid
  //           container
  //           direction="column"
  //           alignItems="center"
  //           justifyContent="center"
  //           sx={{ height: '100%', padding: 3 }}
  //         >
  //           <Grid item>
  //             <Box sx={{ maxWidth: '280px', textAlign: 'center' }}>
  //               <>
  //                 <CircularProgress />
  //                 <Typography variant="h2" component="div">
  //                   Registering
  //                 </Typography>
  //                 <Typography variant="caption" component="div">
  //                   {sip?.status}
  //                   <br />
  //                   {sip?.status === `sipStatus/ERROR` &&
  //                     ` (${sip?.errorMessage})`}
  //                 </Typography>
  //               </>
  //             </Box>
  //           </Grid>
  //         </Grid>
  //       </>
  //     )}
  //   </div>
  // );
};

const WebphoneError = ({ onReconnect }) => {
  const [webphoneState] = useWebphoneContext();
  const { getErrorMessage, isLineConnected } = webphoneState;

  useEffect(() => {
    console.error('line error', getErrorMessage && getErrorMessage());
  }, [getErrorMessage && getErrorMessage()]);

  // console.log('is line', !isLineConnected || !isLineConnected());
  if (!isLineConnected || !isLineConnected()) {
    return (
      <Alert
        rounded={false}
        color={`negative`}
        reverse
        label={`Error: Webphone Disconnected.`}
        body={'Reconnect or refresh your browser if the problem persists.'}
        actionProps={{
          children: <>Reconnect</>,
          onClick: () => onReconnect(true),
        }}
        icon={<WarningTriangle />}
      ></Alert>
    );
  }

  return null;
};

export default WebphoneUI;
