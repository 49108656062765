import { Cancel, NetworkAlt } from 'iconoir-react';

import React, { useEffect, useRef } from 'react';
import { useToggleReducer } from '../../../../../utilities';

import { useCallRouteEditor } from './CallflowContext';
import ReactFlow, { Controls, useZoomPanHelper } from 'react-flow-renderer';
import { nodeTypes } from '../../../../../components/IvrBuilder/Flow/nodes';
import { Button, IconButton } from '../../../../../design-lib';
import { useBuilderContext } from '../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { useSharedFlow } from '../../../../../components/MessageRoutingVisualBuilder';
import { FitViewHelper } from '../../../../../components/MessageRoutingVisualBuilder/Flow/flow';

export const MiniMap = ({
  elements,
  nodeTypes,
  edgeTypes,
  scroll,
  expand,
  ...rest
}) => {
  // const [openMiniMap, toggleOpenMiniMap] = useToggleReducer(true);
  const { openMiniMap, setOpenMiniMap } = useBuilderContext();
  const showRef = useRef(false);
  const [sharedFlow] = useSharedFlow();
  // flow needs to render once so start open and instantly close
  useEffect(() => {
    setTimeout(() => {
      setOpenMiniMap(false);
      showRef.current = true;
    }, 1000);
  }, []);

  const { zoomTo, setCenter, transform, fitView, initialized } =
    useZoomPanHelper();

  useEffect(() => {
    if (openMiniMap) {
      setTimeout(() => {
        fitView();
      }, 10);
    }
  }, [openMiniMap]);

  return (
    <>
      {/*  @ts-ignore */}
      {expand ? (
        <Button
          startIcon={
            openMiniMap ? (
              <Cancel fr={undefined} />
            ) : (
              <NetworkAlt fr={undefined} className={`text-white`} />
            )
          }
          onClick={() => {
            setOpenMiniMap(prev => !prev);
          }}
          color={'accent'}
          variant={'outline'}
          className={`w-full`}
          size={'md'}
          // className={`absolute bg-accent-60 z-20 bottom-3 right-3 border p-4 rounded-full shadow-lg `}
        >
          Map
        </Button>
      ) : (
        <IconButton
          onClick={() => {
            setOpenMiniMap(prev => !prev);
          }}
          color={'accent'}
          variant={'outline'}
          size={'md'}
          // className={`absolute bg-accent-60 z-20 bottom-3 right-3 border p-4 rounded-full shadow-lg `}
        >
          {openMiniMap ? (
            <Cancel fr={undefined} />
          ) : (
            <NetworkAlt fr={undefined} className={`text-white`} />
          )}
        </IconButton>
      )}
      <div
        className={` ${
          (showRef.current && openMiniMap) ||
          //     @ts-ignore
          sharedFlow.state === 'select-module'
            ? 'cursor-grab w-full h-full'
            : 'opacity-0 pointer-events-none h-[1px] w-[1px]'
        } z-10 absolute left-0 top-0 bg-white `}
      >
        <ReactFlow
          maxZoom={1}
          elements={elements}
          // onConnect={onConnect}
          // onElementsRemove={onElementsRemove}
          // @ts-ignore
          connectionLineType="smoothstep" // smoothstep
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          zoomOnScroll={scroll}
          panOnScroll={scroll}
          preventScrolling={scroll}
          // @ts-ignore
          panOnScrollMode="free"
          nodesDraggable={false}
          elementsSelectable={true}
        />
        <Controls showInteractive={false} className={`bg-white mb-1`} />
        <FitViewHelper elements={elements} />
      </div>
    </>
  );
};
