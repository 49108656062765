import {
  Community,
  EditPencil,
  Group,
  InfoEmpty,
  NavArrowDown,
  NavArrowRight,
  NavArrowUp,
  Network,
  NetworkAlt,
  Phone,
  Plus,
  User,
  WarningTriangle,
} from 'iconoir-react';
import { Divider } from '../../../../../../design-lib/mobile-components';
import {
  Alert,
  Button,
  IconButton,
  Listbox,
  TextField,
} from '../../../../../../design-lib';
import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import React, { useEffect, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { getElementProps } from '../utils/getElementProps';
import { RingLinesUsersModule } from './RingLinesUsersModule';
import {
  getAtPath,
  setAtPath,
  useToggleReducer,
} from '../../../../../../utilities';
import { VmboxModule } from './VmboxModule';
import { RingUserModule } from './RingUserModule';
import { RingGroupModule } from './RingGroupModule';
import { RingDeviceModule } from './RingDeviceModule';
import { useBuilderContext } from '../../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { PlayAudioModule } from './PlayAudioModule';
import { MenuModule } from './MenuModule';
import { SendMessageModule } from './SendMessageModule';
import { TransferModule } from './TransferModule';
import { JumpToModule } from './JumpToModule';
import { useDebounce } from 'react-use';
import { ScheduleModule } from './ScheduleModule';
import { ScheduleOptionModule } from './ScheduleOptionModule';
import { HangupModule } from './HangupModule';
import { MenuOptionModule } from './MenuOptionModule';
import { ModuleInfo } from './ModuleInfo';
import { NextModuleButton } from './NextModuleButton';
import { MatchTextModule } from './MatchTextModule';
import { MatchTextOptionModule } from './MatchTextOptionModule';
import { StaticOptionModule } from './StaticOptionModule';
import { LastCommunicatedModule } from './LastCommunicatedModule';

export const ModuleView = () => {
  const { selectedElementKey } = useBuilderContext();
  const { selectedElement, callflow, setCallflow } = useCallRouteEditor();

  if (!selectedElement) return null;

  return (
    <ModuleRoot selectedElement={selectedElement} key={selectedElementKey} />
  );
};

const ModuleRoot = ({ selectedElement }) => {
  const { selectedElementKey, invalidModules } = useBuilderContext();
  const { callflow, setCallflow } = useCallRouteEditor();
  // console.log('selected element', selectedElement);
  const { icon, name, type } = getElementProps(selectedElement);
  const [nameValue, setNameValue] = useState(name || type);

  const updateName = e => {
    const value = e.target.value;
    setNameValue(value ?? null);
  };

  const [debouncedInput, setDebouncedInput] = useState(nameValue);
  useDebounce(() => setDebouncedInput(nameValue), 250, [nameValue]);

  useEffect(() => {
    if (!selectedElement || debouncedInput === (name || type)) return;

    let path;

    switch (selectedElement?.type) {
      case 'OptionNode':
        path = `${selectedElement.data.modifyPath}.${selectedElement.data.targetKey}.name`;
        break;
      case 'OptionScheduleNode':
        if (selectedElement.data.category.id === 'fallback') {
          path = `.schedules.${selectedElement.data.schedule_id}.fallback.name`;
        } else {
          path = `.schedules.${selectedElement.data.schedule_id}.categories.${selectedElement.data.categoryIndex}.name`;
        }
        break;
      case 'OptionMatchTextNode':
        path = `${selectedElement.data?.matchTextData.modifyPath}.data.targets.${selectedElement.data.targetIdx}.name`;
        break;
      case 'OptionStaticNode':
        if (selectedElement.data?.matchTextData) {
          path = `${selectedElement.data?.matchTextData.modifyPath}.data.no_match.name`;
        } else {
          path = `${selectedElement.data?.menuData.modifyPath}.data.targets.${selectedElement.data.targetKey}.name`;
        }
        break;
      default:
        path = `${selectedElement.data.modifyPath}.name`;
        break;
    }

    setAtPath(callflow, path, debouncedInput);
    setCallflow({ ...callflow });
  }, [debouncedInput]);

  const isInvalid = invalidModules.includes(
    selectedElement?.data.moduleItem?.id,
  );

  const [editName, toggleEditName] = useToggleReducer();

  if (!selectedElementKey || selectedElementKey === 'root')
    return (
      <div className={`flex-1 px-2 overflow-y-auto flex flex-col`}>
        <div className={`flex space-x-2 py-4 px-3 items-center`}>
          <NetworkAlt fr={undefined} className={`h-5 w-5 text-neutral-60`} />
          <span>Message Route Editor</span>
        </div>
        <Divider />
        <div className={`p-4 flex space-y-4 flex-col`}>
          <p className={``}>
            Customize your message routing to automatically reply to incoming
            messages.
          </p>
          <p>
            Navigate existing modules using the sidebar on the left and add new
            ones using the{' '}
            <div className={`inline-block pointer-events-none`}>
              <IconButton variant={'outline'} color={'attention'}>
                <Plus fr={undefined} />
              </IconButton>
            </div>{' '}
            button.
          </p>
          <p>
            The sidebar only displays modules in the current path of the
            selected module. To explore deeply nested paths, select the match or
            schedule module you wish to explore further.
          </p>
          <p>
            To quickly navigate complex message routes, use the{' '}
            <div className={`inline-block pointer-events-none`}>
              <IconButton variant={'outline'} color={'accent'}>
                <NetworkAlt fr={undefined} />
              </IconButton>{' '}
            </div>{' '}
            map button in the bottom left of the sidebar.
          </p>
          <p>
            In addition, you can expand the sidebar using the{' '}
            <div className={`inline-block pointer-events-none`}>
              <IconButton variant={'outline'} color={'accent'}>
                <NavArrowRight fr={undefined} />
              </IconButton>{' '}
            </div>{' '}
            button to view each modules name.
          </p>
          <NextModuleButton />
        </div>
      </div>
    );

  // option module (schedule or menu)
  // if (selectedElementKey.endsWith('modules')) {
  //   return <OptionModule />;
  // }

  // standard modules
  return (
    <div className={`flex-1 px-3 pb-3 min-h-0 overflow-y-auto flex flex-col`}>
      <div className={`w-full h-fit flex py-4 items-center justify-between`}>
        <div className={`mt-1 flex min-w-0 space-x-2 px-3 items-start`}>
          <div>
            {React.cloneElement(icon, {
              className: 'h-5 w-5 mt-0.5 text-neutral-60',
            })}
          </div>
          <p className={`truncate`}>{name || type}</p>
        </div>
        <div className={`flex flex-col items-end`}>
          <div className={`flex space-x-2`}>
            <ModuleInfo />
            <IconButton
              variant={'ghost'}
              color={'neutral'}
              size={'md'}
              onClick={toggleEditName}
              // startIcon={<EditPencil fr={undefined} />}
            >
              <EditPencil fr={undefined} />
              {/*{editName ? 'Hide Edit Name' : 'Edit Name'}*/}
            </IconButton>
          </div>
        </div>
      </div>
      <Divider />
      {isInvalid ? <ModuleUnassigned /> : null}
      <br />
      {editName ? (
        <>
          <TextField
            label={'Custom Module Name'}
            onChange={updateName}
            value={nameValue}
            autoFocus
          />
          <br />
        </>
      ) : null}
      <ModuleDisplay element={selectedElement} />
    </div>
  );
};

const ModuleUnassigned = () => {
  const { selectedElement } = useCallRouteEditor();

  let label;
  let body;

  switch (selectedElement?.type) {
    case 'MenuNode':
      label = 'No Greeting';
      body = 'Give your menu a greeting to direct callers.';
      break;
    default:
      label = 'Module Is Unassigned';
      body = 'Assign a value or remove this module to save your call route.';
      break;
  }

  return (
    <Alert
      className={`mt-4`}
      reverse
      icon={<WarningTriangle fr={undefined} />}
      color={'negative'}
      label={label}
      body={body}
    />
  );
};

const ModuleDisplay = ({ element }) => {
  switch (element.type) {
    case 'OptionScheduleNode':
      return <ScheduleOptionModule />;
    case 'OptionMatchTextNode':
      return <MatchTextOptionModule />;
    case 'OptionStaticNode':
      return <StaticOptionModule />;
  }

  // if (element.type === 'OptionNode') return <MenuOptionModule />;

  const module = element.data.moduleItem;

  switch (module?.type) {
    case 'Ring':
      switch (module.data.ring_type) {
        case 'line':
          return <RingLinesUsersModule />;
        case 'group':
          return <RingGroupModule />;
        case 'user':
          return <RingUserModule />;
        case 'device':
          return <RingDeviceModule />;
      }
      break;
    case 'PlayAudio':
      return <PlayAudioModule />;
    case 'Message':
      return <SendMessageModule />;
    case 'Menu':
      return <MenuModule />;
    case 'Schedule':
      return <ScheduleModule />;
    case 'VoicemailBox':
      return <VmboxModule />;
    case 'Transfer':
      return <TransferModule />;
    case 'GoToInFlow':
      return <JumpToModule />;
    case 'Hangup':
      return <HangupModule />;
    case 'MatchText':
      return <MatchTextModule />;
    case 'LastCommunicated':
      return <LastCommunicatedModule />;
  }

  return <>Unhandled Module</>;
};

const OptionModule = () => {
  return null;
};
