import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import { Button, Listbox, SquareProgress } from '../../../../../../design-lib';
import React from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath } from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import { useListUsersQuery } from '../../../../../../hooks/queries/user';
import { NavArrowDown } from 'iconoir-react';
import { NextModuleButton } from './NextModuleButton';

export const RingUserModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const onSelect = (id: string) => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }

    setAtPath(callflow, `${modifyPath}.data.data.id`, id);
    setCallflow({ ...callflow });
  };

  const { data: usersResp, isLoading: usersIsLoading } = useListUsersQuery({
    // mask: 'id,extra(fullName)', // mask
    // quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp ?? {};

  // console.log('user resp', usersResp);

  // console.log('ring user:', user);

  if (usersIsLoading)
    return (
      <div className={`w-full h-full grid place-items-center`}>
        <div className={`text-center`}>
          <SquareProgress />
          <span>Loading...</span>
        </div>
      </div>
    );

  const user = users.find(user => user.id === moduleItem?.data?.data?.id);

  return (
    <>
      <Listbox
        label={'Select User'}
        // size={'md'}
        emptyLabel={'No Users'}
        onChange={onSelect}
        placeholder="Select a User"
        className={`w-full mt-1 self-center`}
        // onOpenChanged={setDisableScroll}
        options={users.map(({ id, extra: { fullName } }) => ({
          value: id,
          label: fullName!,
        }))}
        value={moduleItem?.data.data.id}
      />

      <MenuButton
        type={'ring'}
        current={moduleItem?.data?.ring_type}
        data={selectedElement.data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          'replace',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <NextModuleButton />
    </>
  );
};
