import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import {
  Button,
  Listbox,
  SquareProgress,
  TextField,
} from '../../../../../../design-lib';
import React from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath } from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import { useSharedFlow } from '../../../../../../components/IvrBuilder';
import { useBuilderContext } from '../../../../../../components/IvrBuilder/IvrBuilder';
import { NavArrowUp } from 'iconoir-react';
import { PreviousModuleButton } from './PreviousModuleButton';

export const JumpToModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setOpenMiniMap, setSelectedElementKey } = useBuilderContext();
  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const currentModuleLink = elements.find(
    element => element.data?.moduleItem?.id === moduleItem.data?.id,
  );

  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${modifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  const handleStartSelecting = () => {
    setOpenMiniMap(true);
    setSharedFlow(s => ({
      ...s,
      state: 'select-module',
      data: {
        currentId: moduleItem?.data?.id,
        onSelect: id => {
          handleUpdate({
            ...(moduleItem ?? {}),
            data: {
              ...(moduleItem?.data ?? {}),
              id,
              // timeout: timeoutDefault,
            },
          });
        },
      },
    }));
  };

  // console.log('current module link', currentModuleLink);

  return (
    <>
      {currentModuleLink.data?.moduleItem ? (
        <>
          <span className={`text-md font-medium`}>Module to Jump To</span>
          <TextField
            className={`!text-neutral-90 mb-6 mt-1 pointer-events-none`}
            value={
              currentModuleLink.data?.moduleItem?.name ??
              currentModuleLink.data?.moduleItem?.type
            }
          ></TextField>
        </>
      ) : null}
      <Button
        color={'attention'}
        variant={'outline'}
        size={'md'}
        className={`self-center mb-10`}
        // startIcon={<WarningTriangle />}
        onClick={handleStartSelecting}
        // disabled={skipEditing}
      >
        {currentModuleLink &&
        (!!currentModuleLink.data?.moduleItem?.name ||
          !!currentModuleLink.data?.moduleItem?.type)
          ? 'Change Target Module'
          : 'Select Module to Jump to...'}
      </Button>

      <MenuButton
        data={selectedElement.data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <div
        className={
          'flex space-x-4 border-t pt-4 border-neutral-20 justify-between'
        }
      >
        <PreviousModuleButton />
        <Button
          size={'md'}
          color={'accent'}
          startIcon={<NavArrowUp fr={undefined} />}
          variant={'outline'}
          onClick={() => {
            setSelectedElementKey(currentModuleLink?.key ?? '');
          }}
          disabled={!currentModuleLink.data?.moduleItem}
        >
          Target Module
        </Button>
      </div>
    </>
  );
};
