/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useContext,
  lazy,
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
  Controls,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';
import dagre from 'dagre';

import { useQueries, useQueryClient } from 'react-query';

import { useLocalSelector } from 'app/data/local';
import { useUpdateCallflowPartial } from 'app/hooks/mutations/callflow';
import { useSelector } from 'react-redux';

import { Close as CloseIcon } from 'app/design/icons-material';

import { Box, Grid, ButtonGroup, Typography } from 'app/design';

import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';

import { difference, intersection, find, toInteger, cloneDeep } from 'lodash';
// import { KazooSDK } from '@KazooSDK';

import {
  createReducerContext,
  useEffectOnce,
  createStateContext,
  useHoverDirty,
} from 'react-use';
import { useImmer } from 'use-immer';
import { useToggleReducer } from '../../../utilities';
import { DetailsDialogCallflow } from '../../DetailsDialogCallflow';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'app/design-lib';
import { nodeTypes, nodeTypeAllowAfter } from './nodes';
import { InsertEdge } from './edges/Insert';

import ConvertCallflowToFlowElements from './convertCallflowToFlowElements';
import { CircularProgress } from 'app/design';

// import * as OptionComponents from '../../../Strategies/components';

// import { useSetupHook } from '../../SetupHook';

// import store from '../../../../../../store';
import EventEmitter from 'eventemitter3';

import copy from 'copy-to-clipboard';

import { setAtPath, getAtPath } from 'app/utilities';

import { useSharedFlow, IvrMenuEventEmitterContext } from '../';
import eventEmitter from '../eventEmitter';
// import { ToastQuick } from '@Util/toast';
// // TODO: this needs to be extended to handle dynamic/custom/PRESET types!
// const nodeTypesToOptionComponents = {
//   ContinueToCallflow: 'ContinueToCallflow',
//   ChooseDirectory: 'ChooseDirectory',
//   ConferenceRoom: 'ConferenceRoom',
//   Menu: 'MenuGreetingAndTargets',
//   PlayAudio: 'PlayAudio',
//   Ring: 'Ring',
//   Transfer: 'Transfer',
//   Schedule: 'TimeOfDayMenu',
//   Voicemail: 'VoicemailBox',
// };

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import { SettingsPageWrapper } from '../../../design-lib/components/SettingsPageWrapper';
import { IconButton } from '../../../design-lib';
import {
  ArrowLeft,
  Cancel,
  DeleteCircle,
  MoreHoriz,
  MoreVert,
  SaveActionFloppy,
  SaveFloppyDisk,
} from 'iconoir-react';
import { Emoji } from 'emoji-picker-react';
import { PhoneNumberDisplay } from '../../PhoneNumberDisplay';
import { Link, useHistory } from 'react-router-dom';
import { useBuilderContext } from '../IvrBuilder';
import walkStrategyModules from './walkStrategyModules';
import { MobileView } from '../../../pages/v2/settings/PipeEditCallRouting/MobileView/MobileView';
import { useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import IconButtonDropdown from '../../../design-lib/components/IconButtonDropdown/IconButtonDropdown';

const edgeTypes = {
  insert: InsertEdge,
};

const ReactJson = lazy(() => {
  return import('react-json-view');
});

const position = { x: 0, y: 0 };
const edgeType = 'smoothstep';

const nodeHasDimension = node => {
  if (!isNode(node)) {
    return true;
  }
  if (node.__rf?.width && node.__rf?.height) {
    return true;
  }
  return false;
};
const allNodesHaveDimension = nodes => {
  return nodes?.every(nodeHasDimension) ? true : false;
};

const getLayoutedElements = (elements, direction = 'TB') => {
  // other layout options:
  // - https://github.com/kieler/elkjs
  // - https://stackoverflow.com/questions/12152506/algorithm-for-automatic-placement-of-flowchart-shapes
  // - https://ialab.it.monash.edu/webcola/
  // - https://gojs.net/latest/index.html
  // - http://www.daviddurman.com/automatic-graph-layout-with-jointjs-and-dagre.html
  // - https://modeling-languages.com/javascript-drawing-libraries-diagrams/
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = false; //direction === 'LR';
  dagreGraph.setGraph({
    rankdir: direction,
    ranksep: 20,
    ranksepOLD: 25,
  }); // https://github.com/dagrejs/dagre/wiki#configuring-the-layout
  elements.forEach(el => {
    if (isNode(el)) {
      // width/height if pre-assigned
      dagreGraph.setNode(el.id, {
        width: el.width ?? 1, // || 150,
        height: el.height ?? 1, //50,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map(el => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';
      // unfortunately we need this little hack to pass a slightly different position
      // in order to notify react flow about the change
      // console.log(
      //   'reset nodeWithPosition.height:',
      //   el.type,
      //   nodeWithPosition.y,
      //   nodeWithPosition.height
      // );
      el.position = {
        // x: nodeWithPosition.x,
        x:
          nodeWithPosition.x -
          (nodeWithPosition.width ? nodeWithPosition.width / 2 : 0) +
          Math.random() / 1000,
        y:
          nodeWithPosition.y -
          (nodeWithPosition.height ? nodeWithPosition.height / 2 : 0),
      };
      // el.data.position = el.position;
    }
    return el;
  });
};

let nextId = 0;
const getNextId = () => {
  nextId++;
  return `el${nextId.toString()}`;
};

const addNoteNodeBefore = (
  rootData,
  prevNode,
  thisEl,
  modifyPath,
  insertAfterData,
  infoIdx,
) => {
  // cData = componentData
  // - more prone to failure?
  let thisEdge1, thisEdge2, noteNode, noteEdge;
  if (prevNode.type === 'NoteNode') {
    // edge from existing Note
    thisEdge1 = {
      id: getNextId(),
      source: prevNode.id,
      target: thisEl.id,
      // type: edgeType,
      type: 'smoothstep',
      animated: true,
      arrowHeadType: 'arrow',
    };
    rootData.elements.push(thisEl);
    rootData.elements.push(thisEdge1);
  } else {
    // AddNote node/edge
    // console.log('TYPE INSERT');
    noteNode = {
      id: getNextId(),
      type: 'NoteNode',
      data: {
        exists: false, // this will be semi-obvious to the NoteNode component because the componentData won't exist!
        insertAfterData,
        // callflow,
        // setCallflow,
        // modifyPath: `${modifyPath}.strategy.data.opts[${infoIdx}]`,
      },
      key: `${modifyPath}.strategy.config.components[${infoIdx}]__notenode`, // for focus after modify
      // height: 100,
      position,
    };
    thisEdge1 = {
      id: getNextId(),
      source: prevNode.id,
      target: noteNode.id,
      // type: edgeType,
      type: 'smoothstep',
      animated: true,
      // arrowHeadType: 'arrow',
    };
    thisEdge2 = {
      id: getNextId(),
      source: noteNode.id,
      target: thisEl.id,
      // type: edgeType,
      type: 'smoothstep',
      animated: true,
      arrowHeadType: 'arrow',
    };
    rootData.elements.push(noteNode);
    rootData.elements.push(thisEl);
    rootData.elements.push(thisEdge1);
    rootData.elements.push(thisEdge2);
  }
  return prevNode;
};

// const eventEmitter = new EventEmitter(); // should be inside IvrBuilder?

const convertCallflowToFlowElements =
  ConvertCallflowToFlowElements(eventEmitter);

const Flow = props => {
  const {
    rootCallflow,
    editingCallflow,
    setEditingCallflow,
    setEditingCallflowWrap,
    pipe,
  } = props;

  // const { buildAndSaveCallflow, sync, findOwnerByOwnerId } = useSetupHook();
  const { disableScroll, miniMap } = useBuilderContext();
  const { channels } = useLocalSelector();
  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const { cachePosition, cacheHistory, showJson } = sharedFlow;

  // const [flowElements, setFlowElements] = useState([]); // layoutedElements
  // const [rootElements, setRootElements] = useState([]);
  // const [shouldLayout, setShouldLayout] = useState(null);
  // const [shouldHide, setShouldHide] = useState(true);
  // const [loadedOnce, setLoadedOnce] = useState(false);

  const actions = useStoreActions(actions1 => actions1);
  const { setElements, updateNodeDimensions } = actions;

  // when callflow changes
  // -> rebuild flowElements and setFlowElements
  // -> get position of elements after render (useLayoutEffect) and re-render using new params

  // TODO: oftentimes the node.__rf.height/width values dont exist after the layout is done
  // - when are they created/assigned??

  const { hideWhileRebuilding, flowElements } = useBuildFlow({
    pipe,
    editingCallflow,
    setEditingCallflowWrap,
  });

  // const nn = flowElements.find((el) => el.id == 'el2');
  // // console.log('nn:', nn?.type, nn?.height, flowElements);
  // // console.log('flowElements:', flowElements);
  // console.log(
  //   'allNodesHaveDimension(nodes):',
  //   allNodesHaveDimension(nodes),
  //   nodes
  // );

  // console.log('shouldLayout', shouldHide);
  // if (window.__stop && shouldHide) {
  //   // debugger;
  // }
  // console.log('shouldHide:', shouldHide);

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  const activeState = useActiveState(sharedFlow);
  // console.log('activeState:', activeState);

  const scroll = !disableScroll;

  if (miniMap)
    return (
      <div
        className={`flex-1 bg-white flex-col overflow-hidden min-h-0 w-full  flex`}
      >
        <HeaderAndHistory
          pipe={pipe}
          rootCallflow={rootCallflow}
          editingCallflow={editingCallflow}
          sharedFlow={sharedFlow}
          setSharedFlow={setSharedFlow}
          setEditingCallflow={setEditingCallflow}
          onSave={props.onSave}
          onCancel={props.onCancel}
        />
        <MobileView
          pipe={pipe}
          rootCallflow={rootCallflow}
          editingCallflow={editingCallflow}
          sharedFlow={sharedFlow}
          setSharedFlow={setSharedFlow}
          setEditingCallflow={setEditingCallflow}
          onSave={props.onSave}
          onCancel={props.onCancel}
          elements={flowElements}
          scroll={scroll}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
        />
      </div>
    );

  return (
    <>
      {miniMap ? null : (
        <HeaderAndHistory
          pipe={pipe}
          rootCallflow={rootCallflow}
          editingCallflow={editingCallflow}
          sharedFlow={sharedFlow}
          setSharedFlow={setSharedFlow}
          setEditingCallflow={setEditingCallflow}
          onSave={props.onSave}
          onCancel={props.onCancel}
        />
      )}
      <Box
        // className={`bg-neutral-10/50`}
        sx={{
          height: '100%',
          opacity: hideWhileRebuilding ? 0 : 1,
          // transition: willHide
          //   ? 'opacity .25s ease-in-out'
          //   : 'opacity .25s ease-in-out',
          transition: 'opacity .25s ease-in-out, background-color .25s',
          // backgroundColor: 'red',
          '& .react-flow': {
            cursor: 'grab',
          },
          '& .react-flow__node': {
            cursor: 'auto',
          },
        }}
      >
        <ReactFlow
          maxZoom={1}
          key={isMobile ? 'mobile' : 'desktop'}
          elements={flowElements}
          // onConnect={onConnect}
          // onElementsRemove={onElementsRemove}
          connectionLineType="smoothstep" // smoothstep
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          zoomOnScroll={scroll}
          panOnScroll={scroll}
          preventScrolling={scroll}
          panOnScrollMode="free"
          nodesDraggable={false}
          elementsSelectable={true}
        />
        <Controls showInteractive={false} className={`bg-white mb-1`} />
        <FitViewHelper elements={flowElements} />
      </Box>
    </>
  );
};

const HeaderAndHistory = ({
  pipe,
  rootCallflow,
  editingCallflow,
  sharedFlow,
  setSharedFlow,
  setEditingCallflow,
  onSave,
  onCancel,
}) => {
  const router = useHistory();
  const [showDetailsDialog, toggleShowDetailsDialog] = useToggleReducer(false);
  // const [isSaving, setIsSaving] = useState(null);
  const {
    setInvalidModules,
    setOpenMiniMap,
    openMiniMap,
    setSelectedElementKey,
  } = useBuilderContext();
  const updateCallflowMutation = useUpdateCallflowPartial();
  const isSaving = updateCallflowMutation.isLoading;
  const ee = useContext(IvrMenuEventEmitterContext);

  const handleUndo = () => {
    const previousState = sharedFlow.cacheHistory.slice(
      sharedFlow.cachePosition - 1,
      sharedFlow.cachePosition,
    )[0];
    if (!previousState?.state) {
      return false;
    }
    // console.log('PREVIOUS:', previousState);
    // setCachePosition(sharedFlow.cachePosition - 1);
    // setCacheHistory((c) =>
    //   JSON.parse(JSON.stringify([...sharedFlow.cacheHistory]))
    // );

    setSharedFlow(s => ({
      ...s,
      cachePosition: sharedFlow.cachePosition - 1,
      cacheHistory: JSON.parse(JSON.stringify([...sharedFlow.cacheHistory])),
    }));
    setEditingCallflow({ ...previousState.state });
  };

  const handleRedo = () => {
    const nextState = sharedFlow.cacheHistory.slice(
      sharedFlow.cachePosition + 1,
      sharedFlow.cachePosition + 2,
    )[0];
    if (!nextState?.state) {
      return false;
    }
    // console.log('NEXT:', nextState);
    //  setCachePosition(sharedFlow.cachePosition + 1);
    // setCacheHistory((c) => [...sharedFlow.cacheHistory]);
    setSharedFlow(s => ({
      ...s,
      cachePosition: sharedFlow.cachePosition + 1,
      // cacheHistory: JSON.parse(JSON.stringify([...sharedFlow.cacheHistory])),
    }));
    setEditingCallflow({ ...nextState.state });
  };

  const handleSave = async () => {
    if (isSaving) {
      return;
    }

    let invalidModules = [];
    let elementKey;
    // check for empty/unassigned modules
    walkStrategyModules(
      editingCallflow,
      ({ module, callflow: rootCallflow, modifyPath }) => {
        switch (module.type) {
          case 'Ring':
            if (!module.data.data.id) {
              invalidModules.push(module.id);
              elementKey = elementKey ?? modifyPath;
            }
            break;
          case 'VoicemailBox':
          case 'PlayAudio':
          case 'GoToInFlow':
            if (!module.data.id) {
              invalidModules.push(module.id);
              elementKey = elementKey ?? modifyPath;
            }
            break;
          case 'Message':
            if (!module.data.text) {
              invalidModules.push(module.id);
              elementKey = elementKey ?? modifyPath;
            }
            break;
          case 'Transfer':
            if (!module.data.target) {
              invalidModules.push(module.id);
              elementKey = elementKey ?? modifyPath;
            }
            break;
          case 'Menu':
            if (!module.data.greeting_id) {
              invalidModules.push(module.id);
              elementKey = elementKey ?? modifyPath;
            }
            break;
        }
      },
    );

    if (invalidModules.length) {
      ee.emit('fit-view');
      setInvalidModules(invalidModules);
      setSelectedElementKey(elementKey);
      return;
    }

    try {
      // setIsSaving(true);
      // console.log('Saving!', editingCallflow);
      // editingCallflow.numbers = [...editingCallflow.numbers, '517'];

      // // simple already disabled?
      // // - this is for v1
      // if (editingCallflow.strategy.simple?.enabled) {
      //   var confirmResult = window.confirm(
      //     'Switch to Advanced Editing for this Call Route?',
      //   );
      //   if (!confirmResult) {
      //     return;
      //   }
      //   // mark simple as "enabled"
      //   setAtPath(editingCallflow, 'strategy.simple.enabled', false);
      //   // rename Callflow to name of Strategy (cuz we'll show it now!)
      //   setAtPath(
      //     editingCallflow,
      //     'name',
      //     getAtPath(editingCallflow, 'strategy.template.parent_callflow.name'),
      //   );
      //   // setAtPath(editingCallflow, 'strategy.template.parent_id', null);
      // }
      const result = await updateCallflowMutation.mutateAsync({
        ...editingCallflow,
        strategy: { ...editingCallflow.strategy, default: false },
      });
      // console.log('Save Result:', result);

      onSave && onSave(result);
      // alert('done saving');
      // if (editingCallflow.type === 'template') {
      //   let saveResponse = await KazooSDK.callflowUpdate(editingCallflow, {
      //     accept_charges: true,
      //   });
      //   const savedCallflow = saveResponse.data.data;
      //   sync(['callflows']);
      //   alert('Saved Template');
      // } else {
      //   const outputCallflow = await buildAndSaveCallflow(editingCallflow);
      //   sync(['callflows']);
      //   console.log('outputCallflow:', outputCallflow);
      //   setEditingCallflow({ ...outputCallflow });
      //   alert('Saved Callflow');
      // }
      router.push(`/settings/pipes/${pipe.id}`);
    } catch (err) {
      alert('Failed Saving');
      console.error('Failed saving:', err);
    }
    // setIsSaving(null);
  };

  // const handleChangeName = () => {
  //   let name = window.prompt('New Name:', editingCallflow.name);
  //   if (!name) {
  //     return false;
  //   }
  //   editingCallflow.name = name;
  //   setEditingCallflow(
  //     { ...editingCallflow, changed: true },
  //     { name: 'Updated Name' },
  //   );
  // };

  // const handleChangeDescription = () => {
  //   let description = window.prompt(
  //     'New Description:',
  //     editingCallflow.description,
  //   );
  //   if (!description) {
  //     return false;
  //   }
  //   editingCallflow.description = description;
  //   setEditingCallflow(
  //     { ...editingCallflow, changed: true },
  //     { name: 'Updated Description' },
  //   );
  // };

  const resetState = () => {
    setOpenMiniMap(false);
    setSharedFlow(s => ({
      ...s,
      state: null,
      data: null,
    }));
  };

  let ownerName = null;
  const ownerListItem = null; //findOwnerByOwnerId(editingCallflow?.owner_id);
  // console.log('rootCallflow', rootCallflow);
  switch (editingCallflow.owner_type) {
    case 'pipe':
    case 'line':
      ownerName = rootCallflow.Owner?.doc?.metadata?.name;
      break;
    case 'user':
      ownerName = rootCallflow.Owner?.extra.fullName;
      break;
    case 'group':
      ownerName = rootCallflow.Owner?.doc.name;
      break;
    case 'device':
      ownerName = rootCallflow.Owner?.doc.name;
      break;
    case 'vmbox':
      ownerName = rootCallflow.Owner?.doc.name;
      break;
    case 'account':
      // ownerName = rootCallflow.data.doc.name;
      break;
    default:
      break;
  }

  let typeDisplay = null,
    showName = false;
  // console.log('editingCallflow123', editingCallflow);
  switch (editingCallflow.type) {
    case 'template':
      typeDisplay = <span style={{ color: '#111' }}>Template</span>;
      break;
    case 'main':
      switch (ownerListItem?.type ?? editingCallflow?.owner_type) {
        case 'pipe':
          typeDisplay = <span> Call routing</span>;
          showName = false;
          break;
        case 'user':
          typeDisplay = (
            <span style={{ color: '#777' }}>User Call Routing</span>
          );
          break;
        case 'group':
          typeDisplay = (
            <span style={{ color: '#777' }}>Group Call Routing</span>
          );
          break;
        case 'device':
          typeDisplay = (
            <span style={{ color: '#777' }}>Device Call Routing</span>
          );
          break;
        case 'account':
          typeDisplay = (
            <span style={{ color: '#777' }}>Account Call Routing</span>
          );
          break;
        case 'vmbox':
          typeDisplay = (
            <span style={{ color: '#777' }}>Voicemail Box Call Routing</span>
          );
          break;
        default:
          typeDisplay = (
            <span style={{ color: '#777' }}>Unknown Call Routing</span>
          );
          break;
      }
      break;
    case 'ivrMain':
      typeDisplay = <span style={{ color: '#777' }}>IVR Extension</span>;
      break;
    case 'general':
      typeDisplay = <span style={{ color: '#777' }}>General Call Routing</span>;
      showName = true;
      break;
    default:
      showName = true;
      typeDisplay = <span style={{ color: '#777' }}>Unknown</span>;
      break;
  }

  const activeState = useActiveState(sharedFlow);

  const undoRedoRef = useRef(null);
  const isHoveringUndoRedo = useHoverDirty(undoRedoRef);

  const allowUndo = sharedFlow.cachePosition > 0;
  const allowRedo =
    sharedFlow.cachePosition < sharedFlow.cacheHistory.length - 1;
  // console.log('cacheHistory:', cacheHistory);
  const handleEditDetails = ({ name, description, owner_id }) => {
    toggleShowDetailsDialog();

    if (name) {
      editingCallflow.name = name;
      setEditingCallflow(
        { ...editingCallflow, changed: true },
        { name: 'Updated Name' },
      );
    }

    if (description) {
      editingCallflow.description = description;
      setEditingCallflow(
        { ...editingCallflow, changed: true },
        { name: 'Updated Description' },
      );
    }

    if (owner_id) {
      editingCallflow.owner_id = owner_id;
      setEditingCallflow(
        { ...editingCallflow, changed: true },
        { name: 'Updated Owner' },
      );
    }
  };

  const { miniMap } = useBuilderContext();
  const [confirmBack, setConfirmBack] = useState(false);

  const handleBack = () => {
    router.push(`/v2/settings/pipes/${pipe.id}`);
  };

  if (miniMap) {
    return (
      <>
        <Dialog open={confirmBack} onClose={() => setConfirmBack(false)}>
          <DialogHeader
            onClose={() => setConfirmBack(false)}
            title={`Unsaved Changes`}
          ></DialogHeader>
          <DialogContent>
            Are you sure you want to discard your unsaved changes?
          </DialogContent>
          <DialogActions className={`justify-between`}>
            <Button
              onClick={() => setConfirmBack(false)}
              variant={'ghost'}
              size={'md'}
              color={'neutral'}
            >
              Cancel
            </Button>
            <Button
              onClick={handleBack}
              size={'md'}
              variant={'ghost'}
              color={'attention'}
            >
              Discard Changes
            </Button>
          </DialogActions>
        </Dialog>
        <div
          className={`w-[100vw] -mx-2 py-3 px-3 border-b border-neutral-20 flex`}
        >
          <div className={'flex items-center space-x-2'}>
            <IconButton
              // component={Link}
              variant={'ghost'}
              size={'md'}
              color={'neutral'}
              className={'no-underline text-content-accent'}
              onClick={
                editingCallflow.changed
                  ? () => setConfirmBack(true)
                  : handleBack
              }
              // to={`/v2/settings/pipes/${pipe.id}`}
            >
              <Cancel fr={undefined} />
            </IconButton>
            <Emoji unified={pipe.metadata.emoji || '1f937'} size={24} />
            <div className={`flex`}>
              <div className={`flex flex-wrap items-center`}>
                <span className={`font-medium text-lg mr-2`}>
                  {pipe.metadata.name?.length ? pipe.metadata.name : 'Unnamed'}
                </span>
                <span className={'text-neutral-60 text-sm'}>
                  <PhoneNumberDisplay ptn={pipe.key} hideFlag />
                </span>
              </div>
            </div>
          </div>
          <div className={`flex flex-1 justify-end space-x-2`}>
            <Button
              startIcon={isSaving ? <SaveActionFloppy /> : <SaveFloppyDisk />}
              variant={'outline'}
              color="positive"
              size={'md'}
              // disabled={!editingCallflow.changed} TODO: add back but right now this doesn't track module name changes
              onClick={handleSave}
            >
              {isSaving ? 'Saving' : 'Save'}
            </Button>
            <IconButtonDropdown
              variant={'ghost'}
              color="neutral"
              size={'md'}
              title={'Options'}
              hideArrow
              menuItems={[
                {
                  label: 'Clear Route',
                  onClick: async e => {
                    try {
                      setEditingCallflow({
                        ...editingCallflow,
                        changed: true,
                        strategy: {
                          type: 'blank',
                          simple: {
                            enabled: false,
                          },
                        },
                      });
                      setTimeout(() => {
                        setSelectedElementKey(null);
                      }, 100);
                    } catch (err) {
                      toast.error(err);
                    }
                  },
                },
              ]}
            >
              <MoreVert />
            </IconButtonDropdown>
          </div>
        </div>
        {activeState ? (
          <div className="absolute bottom-4 z-50 right-4">
            <Button
              variant={'fill'}
              color="negative"
              size={'md'}
              // onClick={async e => {
              //   try {
              //     setEditingCallflow({
              //       ...editingCallflow,
              //       strategy: {
              //         type: 'blank',
              //         simple: {
              //           enabled: false,
              //         },
              //       },
              //     });
              //   } catch (err) {}
              // }}
              // variant={activeState.variant}
              // color={activeState.color}
              onClick={resetState}
              endIcon={<Cancel fr={undefined} />}
            >
              {activeState.name}
            </Button>
          </div>
        ) : openMiniMap ? (
          <div className="absolute bottom-4 z-50 right-4">
            <Button
              variant={'fill'}
              color="negative"
              size={'md'}
              // onClick={async e => {
              //   try {
              //     setEditingCallflow({
              //       ...editingCallflow,
              //       strategy: {
              //         type: 'blank',
              //         simple: {
              //           enabled: false,
              //         },
              //       },
              //     });
              //   } catch (err) {}
              // }}
              // variant={activeState.variant}
              // color={activeState.color}
              onClick={() => setOpenMiniMap(false)}
              endIcon={<Cancel fr={undefined} />}
            >
              Close Map
            </Button>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }

  return (
    <>
      <Dialog open={confirmBack} onClose={() => setConfirmBack(false)}>
        <DialogHeader title={`Unsaved Changes`}></DialogHeader>
        <DialogContent>
          Are you sure you want to discard your unsaved changes?
        </DialogContent>
        <DialogActions className={`justify-between`}>
          <Button
            onClick={() => setConfirmBack(false)}
            variant={'ghost'}
            size={'md'}
            color={'neutral'}
          >
            Cancel
          </Button>
          <Button
            onClick={handleBack}
            size={'md'}
            variant={'ghost'}
            color={'attention'}
          >
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>

      <div className={'sm:px-4 px-1 pt-4 w-full flex justify-between'}>
        <div className={'flex items-start space-x-2'}>
          <IconButton
            // component={Link}
            variant={'ghost'}
            size={'md'}
            color={'neutral'}
            className={'no-underline text-content-accent'}
            onClick={
              editingCallflow.changed ? () => setConfirmBack(true) : handleBack
            }
            // to={`/v2/settings/pipes/${pipe.id}`}
          >
            <ArrowLeft fr={undefined} />
          </IconButton>
          <Emoji unified={pipe.metadata.emoji || '1f937'} size={24} />
          <div className={`flex`}>
            <div className={`flex flex-wrap items-center`}>
              <span className={`font-medium text-lg mr-2`}>
                {pipe.metadata.name?.length ? pipe.metadata.name : 'Unnamed'}
              </span>
              <span className={'text-neutral-60 text-sm'}>
                <PhoneNumberDisplay ptn={pipe.key} hideFlag />
              </span>
            </div>
          </div>
        </div>
        <span className={`sm:block hidden text-md font-medium text-neutral-60`}>
          Editing call routing
        </span>
        <div
          className={`sm:flex-row flex-col flex flex-col-reverse space-y-reverse space-y-2 sm:space-y-0 items-end`}
        >
          <Button
            variant={'fill'}
            color="negative"
            size={'md'}
            onClick={async e => {
              try {
                setEditingCallflow({
                  ...editingCallflow,
                  changed: true,
                  strategy: {
                    type: 'blank',
                    simple: {
                      enabled: false,
                    },
                  },
                });
              } catch (err) {}
            }}
          >
            Clear
          </Button>
          <Button
            className={`ml-2`}
            variant={'fill'}
            color="positive"
            size={'md'}
            disabled={!editingCallflow.changed}
            onClick={handleSave}
          >
            {isSaving ? 'Saving Changes...' : 'Save Changes'}
          </Button>
        </div>
      </div>

      {activeState ? (
        <div className="absolute top-14 sm:top-12 z-10 p-2">
          <Button
            variant={'fill'}
            color="negative"
            size={'md'}
            // onClick={async e => {
            //   try {
            //     setEditingCallflow({
            //       ...editingCallflow,
            //       strategy: {
            //         type: 'blank',
            //         simple: {
            //           enabled: false,
            //         },
            //       },
            //     });
            //   } catch (err) {}
            // }}
            // variant={activeState.variant}
            // color={activeState.color}
            onClick={resetState}
            endIcon={<Cancel fr={undefined} />}
          >
            {activeState.name}
          </Button>
        </div>
      ) : (
        ''
      )}
    </>
  );

  return (
    <>
      {showDetailsDialog ? (
        <DetailsDialogCallflow
          callflowId={editingCallflow.id}
          onCancel={toggleShowDetailsDialog}
          onComplete={handleEditDetails}
          flowForm={editingCallflow}
          passForm
        />
      ) : null}
      <div style={{ position: 'absolute', top: 12, left: 12, zIndex: 100 }}>
        <Typography
          variant="h6"
          onClick={toggleShowDetailsDialog}
          sx={{ cursor: 'pointer' }}
        >
          {typeDisplay}
          {showName ? `: ${editingCallflow.name}` : null}
        </Typography>
        {ownerName && <Typography variant="h7">{ownerName}</Typography>}
        {/* {showName ? (
          <Typography
            variant="caption"
            onClick={toggleShowDetailsDialog}
            style={{
              maxWidth: 340,
              fontStyle: editingCallflow.description ? '' : 'italic',
            }}
            sx={{ cursor: 'pointer' }}
          >
            {editingCallflow.description || 'No description'}
          </Typography>
        ) : null} */}
      </div>

      {/* History */}
      <div
        className={'h-min'}
        style={{
          position: 'absolute',
          top: 12,
          right: 12,
          zIndex: 100,
          // height: 'calc(100% - 20px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Grid container spacing={1}>
          {/* State (duplicating, moving, etc) */}
          {activeState ? (
            <Grid item>
              <Button
                variant={activeState.variant}
                color={activeState.color}
                onClick={resetState}
                endIcon={<CloseIcon />}
              >
                {activeState.name}
              </Button>
            </Grid>
          ) : (
            ''
          )}
          <Grid item>
            <div>
              {/* Save Button */}
              <Button
                size="small"
                variant={editingCallflow.changed ? 'contained' : 'outlined'}
                color="error"
                onClick={onCancel}
                style={{ opacity: editingCallflow.changed ? 1.0 : 0.5 }}
              >
                Cancel
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div>
              {/* Save Button */}
              <Button
                size="small"
                variant={editingCallflow.changed ? 'contained' : 'outlined'}
                color="success"
                onClick={handleSave}
                style={{ opacity: editingCallflow.changed ? 1.0 : 0.5 }}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </Grid>
          <Grid item>
            <ButtonGroup
              variant="outlined"
              color="info"
              size="small"
              ref={undoRedoRef}
            >
              <Button
                variant={allowUndo ? 'contained' : 'outlined'}
                disabled={!allowUndo}
                onClick={handleUndo}
                style={{ opacity: allowUndo ? 1.0 : 0.5 }}
              >
                Undo
              </Button>
              <Button
                variant={allowRedo ? 'contained' : 'outlined'}
                disabled={!allowRedo}
                onClick={handleRedo}
                style={{ opacity: allowRedo ? 1.0 : 0.5 }}
              >
                Redo
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            {sharedFlow.showJson && (
              <Dialog
                open
                maxWidth="lg"
                fullWidth
                onClose={e =>
                  setSharedFlow(s => ({
                    ...s,
                    showJson: null,
                  }))
                }
              >
                <DialogContent>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <Button
                      variant="outlined"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1000,
                      }}
                      onClick={() => {
                        copy(JSON.stringify(editingCallflow.strategy));
                        // ToastQuick({
                        //   type: 'success',
                        //   title: 'Copied strategy JSON',
                        // });
                      }}
                    >
                      Copy Strategy
                    </Button>
                    <React.Suspense
                      fallback={
                        <div>
                          <CircularProgress size={40} />
                        </div>
                      }
                    >
                      <ReactJson src={editingCallflow} collapsed={3} />
                    </React.Suspense>
                  </div>
                </DialogContent>
              </Dialog>
            )}

            <ButtonDropdownMenu
              menuItems={[
                {
                  text: 'Paste Mode',
                  onClick: async e => {
                    // validate "copied" text
                    try {
                      const text = await navigator.clipboard.readText();
                      // console.log('pasted text:', text);
                      const val = JSON.parse(text);
                      if (val.type !== 'cio.v1') {
                        alert('Sorry, invalid text version in clipboard');
                        throw new Error('Invalid clipboard version');
                        // return;
                      }
                      setSharedFlow(s => ({
                        ...s,
                        state: 'paste-to',
                        data: {
                          pasteJson: val.data,
                        },
                      }));
                    } catch (err) {
                      alert('Sorry, invalid text in clipboard');
                      // ToastQuick({
                      //   type: 'error',
                      //   title: 'Invalid clipboard data for paste mode',
                      // });
                    }
                  },
                },
                {
                  text: 'Show JSON',
                  onClick: () => {
                    setSharedFlow(s => ({
                      ...s,
                      showJson: true,
                    }));
                  },
                },
                {
                  text: 'Reset to Blank',
                  onClick: async e => {
                    try {
                      setEditingCallflow({
                        ...editingCallflow,
                        strategy: {
                          type: 'blank',
                          simple: {
                            enabled: false,
                          },
                        },
                      });
                    } catch (err) {}
                  },
                },
                process.env.NODE_ENV === 'development'
                  ? {
                      text: 'Paste from Clipboard',
                      onClick: async e => {
                        // validate "copied" text
                        try {
                          let text = await navigator.clipboard.readText();
                          // console.log('pasted text:', text);
                          // console.log('TEXT:', text);
                          const val = JSON.parse(text);
                          if (!val.strategy) {
                            alert(
                              'Sorry, invalid strategy copied (should include an object with "strategy" as a key)',
                            );
                            // throw new Error('Invalid clipboard version');
                            return;
                          }
                          // setAtPath(editingCallflow, 'strategy', val);
                          setEditingCallflow({
                            ...val,
                            type: editingCallflow?.type,
                          });
                          // setSharedFlow(s => ({
                          //   ...s,
                          //   state: 'paste-to',
                          //   data: {
                          //     pasteJson: val.data,
                          //   },
                          // }));
                        } catch (err) {
                          alert('Sorry, invalid text in clipboard');
                          // ToastQuick({
                          //   type: 'error',
                          //   title: 'Invalid clipboard data for paste mode',
                          // });
                        }
                      },
                    }
                  : null,
                process.env.NODE_ENV === 'development'
                  ? {
                      text: 'Copy from Visual Editor',
                      onClick: async e => {
                        // const val = JSON.stringify({
                        //   id: editingCallflow.id,
                        //   name: editingCallflow.name,
                        //   limit: editingCallflow.limit,
                        //   strategy: editingCallflow.strategy,
                        // });
                        const val = JSON.stringify(
                          editingCallflow.strategy,
                          null,
                          2,
                        );
                        copy(val);
                      },
                    }
                  : null,
              ].filter(v => !!v)}
            />

            {/* <Button
              size="small"
              variant={'outlined'}
              color="info"
              onClick={(e) =>
                setSharedFlow((s) => ({
                  ...s,
                  showJson: true,
                }))
              }
            >
              Show JSON
            </Button>
            <Button
              size="small"
              variant={'outlined'}
              color="default"
              onClick={}
            >
              Paste
            </Button> */}
            {/* <Button
              size="small"
              variant={'outlined'}
              color="info"
              onClick={(e) =>
                setSharedFlow((s) => ({
                  ...s,
                  view: 'wizard',
                }))
              }
            >
              View Wizard
            </Button> */}
          </Grid>
        </Grid>
        {isHoveringUndoRedo ? (
          <div
            style={{
              width: 'auto',
              maxWidth: '240px',
              opacity: isHoveringUndoRedo ? 0.5 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <Typography
              variant="body2"
              noWrap
              style={{ textDecoration: 'underline' }}
            >
              History
            </Typography>
            {[...sharedFlow.cacheHistory].reverse().map((ch, i) => (
              <div key={ch.id}>
                <Typography variant="body2" noWrap>
                  {sharedFlow.cachePosition ===
                  sharedFlow.cacheHistory.length - 1 - i
                    ? '> '
                    : ''}
                  {ch.actionDetails.name}
                </Typography>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export const useActiveState = sharedFlow => {
  let activeState;
  switch (sharedFlow.state) {
    case 'select-module':
      activeState = {
        name: 'Select Module',
        color: 'info',
        variant: 'contained',
        background: 'rgba(100,100,100,0.1)',
      };
      break;
    case 'duplicate-to':
      activeState = {
        name: 'Duplicating',
        color: 'info',
        variant: 'contained',
        background: 'rgba(100,100,100,0.1)',
      };
      break;
    case 'move-to':
      activeState = {
        name: 'Moving',
        color: 'info',
        variant: 'contained',
        background: 'rgba(100,100,100,0.1)',
      };
      break;
    case 'paste-to':
      activeState = {
        name: 'Pasting',
        color: 'info',
        variant: 'contained',
        background: 'rgba(100,100,100,0.1)',
      };
      break;
    default:
      if (sharedFlow.state) {
        console.error('invalid sharedFlow.state');
      }
      break;
  }
  return activeState;
};

export const useBuildFlow = ({
  editingCallflow,
  setEditingCallflowWrap,
  pipe,
}) => {
  const ee = useContext(IvrMenuEventEmitterContext);

  const flowStore = useStore();
  const { nodes, edges } = flowStore.getState();

  const [flowElements, setFlowElements] = useState([]); // layoutedElements
  const [rootElements, setRootElements] = useState([]);
  const [shouldLayout, setShouldLayout] = useState(null);
  const [shouldHide, setShouldHide] = useState(true);
  const [loadedOnce, setLoadedOnce] = useState(false);

  const [referencedCallflowIds, setReferencedCallflowIds] = useState([]);
  const [relatedCallflows, setRelatedCallflows] = useState({});
  const queryClient = useQueryClient();

  const authState = useAuthSelector();

  const queries = referencedCallflowIds.map(id => {
    return {
      queryKey: callflowQueryKeys.byId(id),
      queryFn: () =>
        sdk.callflow.query.callflowById(
          { id },
          { authToken: authState.auth_token },
        ), // ...options here ie "enabled"};
    };
  });
  const referencedCallflowResults = useQueries(queries);

  // useEffect(() => {}, []);
  // build relatedCallflow
  useEffect(() => {
    const relatedCallflows = {};
    referencedCallflowResults.forEach((result, i) => {
      relatedCallflows[referencedCallflowIds[i]] = {
        result,
      };
    });
    setRelatedCallflows(relatedCallflows);
    // console.log('resetting relatedCallflows');
  }, [referencedCallflowResults.map(r => r.status).join(' ')]);

  useEffect(() => {
    // nextId = 0;
    const rootData = convertCallflowToFlowElements({
      pipe,
      callflow: editingCallflow,
      setCallflow: setEditingCallflowWrap,
      modifyPath: '', // root
      parentSourceNode: null, //initialArr[1],
      rootData: {
        callflowIds: [],
        elements: [],
        referencedCallflowIds: [],
        missingCallflowIds: [],
      },
      edgeData: null,
      relatedCallflows, // for schedules, templates, etc. (need to load remotely to get the follow-on results) (schedules MUST work this way!! ie CANNOT be like templates!)
      // optionalNodesEdges...
    });

    // TODO: processing referencedCallflowIds (load before rendering)
    const referencedCallflowIds = rootData.referencedCallflowIds;
    // console.log('referencedCallflowIds:', referencedCallflowIds);
    setReferencedCallflowIds(referencedCallflowIds);

    // not exists yet, loading, error, success

    // not exists yet
    if (
      difference(referencedCallflowIds, Object.keys(relatedCallflows)).length
    ) {
      // console.log('Unseen ids');
      setShouldHide(true);
      return;
    }

    // loading
    for (const cfId of Object.keys(relatedCallflows)) {
      const cf = relatedCallflows[cfId];
      if (cf.result.status === 'loading') {
        // console.log('Loading still');
        setShouldHide(true);
        return;
      }
    }

    // error, success (both do NOT block display!)

    // see if any referencedCallflowIds were missing/invalid
    // - dont display results yet, wait for loading everything
    if (rootData.missingCallflowIds.length) {
      // console.log(
      //   'Missing IDS!',
      //   rootData.missingCallflowIds,
      //   relatedCallflows,
      // );
    }

    // console.log('INITIAL Layout');
    const le = getLayoutedElements(rootData.elements);
    setFlowElements([...le]);
    setShouldHide(true);
    setTimeout(() => {
      // leaving the setTimeout out causes a broken re-render
      // - the view is sometimes still lost entirely! (shouldHide gets stuck on, cuz nodes dont render w/ height/width)
      setShouldLayout(true);
    }, 1);
  }, [editingCallflow, relatedCallflows]);

  useLayoutEffect(() => {
    if (shouldLayout && allNodesHaveDimension(nodes)) {
      // console.log('SECOND Layout');
      // recreate elements w/ x/y provided
      let newFlowElements = [...nodes, ...edges].map(node => {
        if (!isNode(node)) {
          delete node.position;
          delete node.__rf;
          return { ...node }; // edge
        }
        const r = {
          ...node,
          width: node.__rf.width,
          height: node.__rf.height,
          position: null,
          __rf: null,
        };
        delete r.position;
        delete r.__rf;
        return r;
      });

      const le = getLayoutedElements(newFlowElements);
      setFlowElements([...le]);
      setShouldHide(false);
      setShouldLayout(false);
      setLoadedOnce(true);
      if (!loadedOnce) {
        setTimeout(() => {
          ee.emit('fit-view');
        }, 100);
      }
    } else {
      // console.log(
      //   'No relayout',
      //   shouldLayout,
      //   allNodesHaveDimension(nodes),
      //   nodes
      // );
      // TODO: if shouldLayout, then force a reflow that rebuilds the dimensions
      if (shouldLayout) {
        // console.info(
        //   'should be doing a layout, but the damn nodes are missing height/width values'
        // );
        setTimeout(() => {
          setFlowElements([...flowElements]);
        }, 1);
        // trying to force a "calc all the node dimensions" but not sure how to do it (fit-view doesnt do it)
        // setTimeout(() => {
        //   ee.emit('fit-view');
        // }, 100);
      }
    }
  }, [shouldLayout, nodes]);

  const hideWhileRebuilding = shouldHide || !allNodesHaveDimension(nodes);

  return { hideWhileRebuilding, flowElements };
};

export const FitViewHelper = props => {
  const { elements } = props;
  const { zoomTo, setCenter, transform, fitView, initialized } =
    useZoomPanHelper();

  const flowStore = useStore();

  const focusNode = useCallback(
    filterData => {
      // console.log('focusNode data:', filterData);
      const { nodes } = flowStore.getState();
      // console.log('focusNodes:', nodes);
      if (nodes.length) {
        const node =
          filterData && Object.keys(filterData)
            ? find(nodes, filterData) || nodes[0]
            : nodes[0];
        const x = node.__rf.position.x + node.__rf.width / 2;
        const y = node.__rf.position.y + node.__rf.height / 2;
        // console.log('focus on:', x, y, node);
        const zoom = 1.0;
        setCenter(x, y, zoom);
      }
    },
    [setCenter],
  );

  // const shakeView = useCallback(() => {
  //   console.log('shake view');
  //   const { transform: flowTransform } = flowStore.getState();
  //   transform({
  //     x: flowTransform.x,
  //     y: flowTransform.y + 5,
  //     zoom: flowTransform.zoom,
  //   });
  // }, [transform]);

  const ee = useContext(IvrMenuEventEmitterContext);

  useEffect(() => {
    ee.on('focus-node', focusNode);
    ee.on('fit-view', fitView);
    // ee.on('shake-view', shakeView);
    return () => {
      ee.removeListener('focus-node', focusNode);
      ee.removeListener('fit-view', fitView);
      // ee.removeListener('shake-view', shakeView);
    };
  }, [focusNode]);

  const firstRunRef = useRef(false);
  useEffect(() => {
    if (firstRunRef.current) {
      return;
    }
    // console.log('Running focusOnFirst');
    if (initialized) {
      firstRunRef.current = true;
      // window.setTimeout(focusNode, 1);
      // fitView();
    }
  }, [initialized, focusNode, fitView]);

  return null;
};

export default Flow;
