import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import { Listbox, SquareProgress } from '../../../../../../design-lib';
import React from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath } from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import { NextModuleButton } from './NextModuleButton';

export const RingLinesUsersModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const onSelect = (id: string) => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }

    setAtPath(callflow, `${modifyPath}.data.data.id`, id);
    setCallflow({ ...callflow });
  };

  const pipeListQuery = usePipesQuery({
    // skip: queryPageIndex * queryPageSize,
    // take: queryPageSize,
    // orderBy: [['lastName', 'asc']],
    filters: {
      // searchInput: querySearchInput,
      NOT: {
        type: 'room',
      },
    },
  });
  const { pipes = [], totalCount = 0 } = pipeListQuery.data ?? {};

  if (pipeListQuery.isLoading)
    return (
      <div className={`w-full h-full grid place-items-center`}>
        <div className={`text-center`}>
          <SquareProgress />
          <span>Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <Listbox
        label={'Select Line'}
        // size={'md'}
        onChange={onSelect}
        emptyLabel={'No Lines'}
        placeholder="Select a Line"
        className={`w-full mt-1 self-center`}
        // onOpenChanged={setDisableScroll}
        options={pipes
          // .filter(pipe => pipe.type !== 'personal')
          .map(pipe => ({
            label: (
              <div className={'flex space-x-2  min-w-0 items-center'}>
                {pipe?.type === 'personal' ? (
                  <UserAvatar user_ext={pipe.key} size="sm" />
                ) : (
                  <Emoji unified={pipe?.metadata?.emoji || '1f937'} size={16} />
                )}
                <div className="text-left py-2">
                  {pipe?.type === 'personal' ? (
                    <div>
                      <UserName user_ext={pipe.key} />
                    </div>
                  ) : (
                    <div>{pipe?.metadata.name}</div>
                  )}
                  <div className={'truncate text-neutral-60 text-xs'}>
                    <PhoneNumberDisplay hideFlag ptn={pipe?.key} />
                  </div>
                </div>
              </div>
            ),
            value: pipe.id,
          }))}
        value={moduleItem?.data.data.id}
      />
      <MenuButton
        type={'ring'}
        current={moduleItem?.data?.ring_type}
        data={selectedElement.data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          'replace',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <NextModuleButton />
    </>
  );
};
