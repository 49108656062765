import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { CreateUsersBulkv2 } from 'app/components/CreateUsersBulk';
import { UserListOptions } from 'app/components/ListUsers/components/UserListOptions';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import { TIMEZONE_LIST } from 'app/components/TimezoneDialogAccount/TimezoneDialogAccount';
import {
  Badge,
  Button,
  Dialog,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { SettingsCard } from 'app/pages/admin/Dashboard/components/SettingsCard';
import { ResetOnboarding } from 'app/pages/v2/settings/Settings/Settings';
import { usePNContext } from 'app/utilities/pubnub';
import useToggleReducer from 'app/utilities/useToggleReducer';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { User } from 'types/user';
import useDevMode from 'app/utilities/useDevMode';
import { Plus as PlusIcon } from 'iconoir-react';
import copy from 'copy-to-clipboard';
import { Account } from 'types/account';
import { useMediaQuery } from '@mui/material';

function Users() {
  // useRouteMatch() // no argument, returns current match? (but that is the PARENT Route, not the Setting Route...)

  const {
    data: usersPage,
    isLoading: usersLoading,
    refetch,
  } = useListUsersQuery({
    take: 500,
    orderBy: [['ext', 'asc']],
    filters: {},
    options: {},
  });
  const users = usersPage?.users ?? [];
  const location = useLocation();

  const [openCreateDialog, toggleOpenCreateDialog] = useToggleReducer(false);
  const isLinkFocus = location.hash === `#add_teammate` ? true : false; // id matches hash
  const { data: account } = useAccountQuery();

  return (
    <>
      <AddUsersDialog
        open={openCreateDialog}
        // pipe={pipe}
        onCancel={() => {
          toggleOpenCreateDialog();
          refetch();
        }}
        onComplete={async () => {
          await refetch();
          toggleOpenCreateDialog();
        }}
        // onSend={toggleOpenSendDialog}
      />
      <SettingsPageWrapper
        isLoading={usersLoading}
        loadingLabel={'Loading teammates...'}
        label={'Team'}
        subLabel={'Manage teammates'}
        buttonProps={{
          children: 'Add teammates',
          onClick: toggleOpenCreateDialog,
          startIcon: <PlusIcon fr={undefined} />,
          className: isLinkFocus ? 'checklist-highlight' : '',
        }}
      >
        {users.length ? (
          <>
            <Table>
              {users.map((user, index) => (
                <UserRow
                  key={user.id}
                  showBorder={users.length > 1 && index < users.length - 1}
                  user={user}
                  refetch={refetch}
                  account={account}
                />
              ))}
            </Table>
          </>
        ) : (
          <span>no teammates</span>
        )}
      </SettingsPageWrapper>
    </>
  );

  // return (
  //   <>
  //     <AddUsersDialog
  //       open={openCreateDialog}
  //       // pipe={pipe}
  //       onCancel={() => {
  //         toggleOpenCreateDialog();
  //         refetch();
  //       }}
  //       // onSend={toggleOpenSendDialog}
  //     />
  //     <div
  //       className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //       data-tour-settings-users
  //     >
  //       <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
  //         <SimpleBar className={'h-full'} style={{ height: 'inherit' }}>
  //           <div className={'px-8 py-3 h-full'}>
  //             <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
  //               <div className={'flex  items-center space-x-2'}>
  //                 <div
  //                   className={
  //                     'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
  //                   }
  //                 >
  //                   <GroupsIcon
  //                     className={'fill-content-accent '}
  //                     sx={{ fontSize: 'inherit' }}
  //                   />
  //                 </div>
  //                 <div>
  //                   <div className="font-bold text-xl">Users</div>
  //                   <div className="text-base text-neutral-500">
  //                     Team members on your account
  //                   </div>
  //                 </div>
  //               </div>
  //               {/* <HelpModeIndicator
  //                 videoUrl={
  //                   'https://www.loom.com/embed/0a5696a0d6d24028a8d1e1b3f8e9be51?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'
  //                 }
  //               > */}
  //               <Button
  //                 data-tour-settings-lines-view-callrouting
  //                 color={'accent'}
  //                 size={'sm'}
  //                 variant={'outline'}
  //                 className={'h-min'}
  //                 onClick={toggleOpenCreateDialog}
  //                 startIcon={<PersonAddIcon />}
  //               >
  //                 Add to Team
  //               </Button>
  //               {/* </HelpModeIndicator> */}
  //             </div>
  //             <div className="p-3 h-full">
  //               <table className="w-full border-separate border-spacing-y-3">
  //                 <tbody className="space-y-4">
  //                   {users.map(user => (
  //                     <UserRow key={user.id} user={user} refetch={refetch} />
  //                   ))}
  //                 </tbody>
  //               </table>
  //               {usersLoading ? (
  //                 <div className={'pt-5 text-neutral-400 w-full text-center'}>
  //                   Loading Users...
  //                 </div>
  //               ) : null}
  //             </div>
  //           </div>
  //         </SimpleBar>
  //       </div>
  //     </div>
  //   </>
  // );
}

const UserRow = ({
  user,
  refetch,
  showBorder,
  account,
}: {
  user: User;
  showBorder: boolean;
  account?: Account;
  refetch: () => void;
}) => {
  // @ts-ignore
  const { onlineUsers } = usePNContext();
  const isOnline = onlineUsers?.[user.id] ? true : false;
  const { devMode } = useDevMode();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });
  const isTinyMobile = !useMediaQuery('(min-width:500px)', { noSsr: true });

  const isLinkFocus = location.hash === `#${user.id}` ? true : false; // id matches hash

  return (
    <TableRow
      showBorder={showBorder}
      className={`${isLinkFocus ? 'checklist-highlight' : 'bg-white'}`}
      key={user.id}
    >
      <TableCell flex>
        <div className={'flex-col'}>
          <div className="flex font-medium items-center space-x-2">
            <div>
              <UserAvatar user={user} enabled={isOnline} size={'sm'} />
            </div>
            <div className="">{user.extra.fullName}</div>
            {/*  <div className="text-neutral-400">{user.doc.username}</div>
            {devMode ? (
              <div className="text-neutral-300 text-xs">{user?.id}</div>
            ) : null}*/}
          </div>
          <span className={`text-xs text-neutral-60`}>
            {
              TIMEZONE_LIST.find(
                tz => tz.value === (user.doc.timezone ?? account?.doc.timezone),
              )?.label
            }
          </span>
        </div>
      </TableCell>
      <TableCell className={isMobile ? 'hidden' : 'w-20'} flex>
        <p className={`truncate`}>{user.doc.title}</p>
      </TableCell>
      <TableCell className={isTinyMobile ? 'hidden' : '!min-w-0 w-20'} flex>
        <p className={`truncate`}>
          <span
            className=""
            onClick={() => copy(user.doc.username)}
            onDoubleClick={() => copy(user.id)}
          >
            {user.doc.username}
          </span>
        </p>
      </TableCell>
      <TableCell className={'w-14'}>
        <Link
          className={`text-accent-60 hover:underline`}
          to={`/settings/pipes/${
            user.Pipes.find(p => p.key === user.doc.presence_id)?.id
          }`}
        >
          {user.doc.presence_id}
        </Link>
      </TableCell>
      <TableCell className={'w-20'}>
        {user.doc.priv_level === 'admin' ? (
          <Badge color={'accent'} variant={'fill'} reverse>
            Admin
          </Badge>
        ) : (
          <div></div>
        )}
      </TableCell>
      <TableCell>
        <div className="h-full flex items-center">
          {/* <button>Actions</button> */}
          <UserListOptions
            onDelete={() => {
              refetch();
            }}
            user={user}
            onComplete={() => {
              refetch();
            }}
            toggleRowSelected={() => {}}
            vertical={true}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const AddUsersDialog = ({ onCancel, onComplete, open }) => {
  return (
    <Dialog size={'lg'} open={open} onClose={onCancel}>
      <CreateUsersBulkv2 onComplete={onComplete} />
    </Dialog>
  );
};

export default Users;
