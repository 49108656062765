import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Sentry from '@sentry/react';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { userQueryKeys } from 'app/hooks/queries/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useUserQuery } from 'app/hooks/queries/user';
import PersonIcon from '@mui/icons-material/Person';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { UserPrivLevel } from 'types/user';
import {
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
} from 'app/design';
import { Button } from 'app/design-lib';
import { toast } from 'react-toastify';
import {
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
  LocalPolice as LocalPoliceIcon,
  Build as BuildIcon,
} from 'app/design/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { store } from 'store';

import { get } from 'lodash';

import SimpleBar from 'simplebar-react';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  useForm,
  useFormContext,
  useFieldArray,
  FormProvider,
  Controller,
} from 'react-hook-form';

import { useAuthSelector } from 'app/data/auth';
import ImgReducer from 'image-blob-reduce';
import constants from 'app/constants';

import USECASES_OBJ from './usecases.json';

import {
  useTioQuery,
  useTioMutation,
  useCampaignCreate,
  useQueryCampaignUsecasesBrand,
  useCampaignAttachmentUpload,
  useCampaignAttachmentDelete,
} from 'app/pages/v2/common/sdk';

// const schema = Joi.object({
//   stockSymbol: Joi.alternatives().conditional('entityType', {
//     is: 'PUBLIC_PROFIT',
//     then: Joi.number().required(),
//   }),
// });

let USECASES: any[] = [];
Object.keys(USECASES_OBJ).map(usecase =>
  USECASES.push({
    usecase,
    generic: USECASES_OBJ[usecase],

    // label: USECASES_OBJ[code].displayName,
    // info: USECASES_OBJ[code],
    // value: code,
  }),
);
const USECASES_STANDARD = USECASES.filter(
  uc => uc.generic.classification === 'STANDARD',
);
const USECASES_SPECIAL = USECASES.filter(
  uc => uc.generic.classification === 'SPECIAL',
);
const USECASES_SUB = USECASES.filter(uc => uc.generic.validSubUsecase);

const YES_NO_TRUE_FALSE = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const CampaignCreate = ({}) => {
  // @ts-ignore
  const { brand_id } = useParams();

  const { account_id, owner_id, auth_token } = useAuthSelector();
  // const createCampaign = useCampaignCreate();
  const createCampaign = useTioMutation();
  // const campaignUsecases = useQueryCampaignUsecasesBrand({ brand_id });
  const campaignUsecases = useTioQuery(
    `/messaging/brands/${brand_id}/campaigns/use_cases`,
  );
  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: {
      step: 'usecase', // details
      info: {
        brandId: brand_id,
        usecase: '',
        subUsecases: [],
        description: '',
        embeddedLink: null, // using embedded links ever?
        embeddedPhone: null, // using embedded phone number?
        affiliateMarketing: null, // used for affiliate marketing?
        termsAndConditions: null, // meets Terms and Conditions? (ie "No Affiliate Marketing")
        numberPool: null, // 50+ numbers for campaign?
        ageGated: null, // will include age-gated content as per Carrier/CTIA guidelines?
        directLending: null, // will include direct lending?
        subscriberOptin: null, // collecting and processing consumer opt-ins?
        subscriberOptout: null, // collecting and processing consumer opt-outs?
        subscriberHelp: null, // have implemented message reply providing customers on how to contact sender after they reply with HELP?
        // sample1: '',
        // sample2: '',
        // sample3: '',
        // sample4: '',
        // sample5: '',
        messageFlow: '',
        helpMessage: '',
        // mnoIds: [],
        // referenceId: '',
        autoRenewal: true, // set as default?
        tag: [],
        optinKeywords: 'START,YES',
        optoutKeywords: 'STOP',
        helpKeywords: 'HELP',
        optinMessage: '',
        optoutMessage: '',
      },
      sampleTexts: [{ id: 1, value: '' }],
      sampleFiles: [],
    },
    // resolver: joiResolver(schema),
  });

  const handleSetDefaults = () => {
    setValue(
      'info.helpMessage',
      'Thank you for your patience while we contact you.',
    );
    setValue(
      'info.messageFlow',
      'This is how customers contact us and how we contact them. It can be an extremely long description if we want',
    );
    setValue(
      'info.optinMessage',
      'Welcome to our service, this is the Opt-in message',
    );
    setValue(
      'info.optoutMessage',
      'Welcome to our service, this is the Opt-out message',
    );
    setValue(
      'info.description',
      'Mock description of campaign 1. must be along description of the campaign details',
    );
    setValue(
      'sampleTexts.0.value',
      'Sample mock text of what a customer would receive',
    );
  };

  const {
    watch,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  console.log('errors:', errors);

  const onSubmit = async () => {
    console.log('submitting');
    const form = getValues();
    const data = form.info;
    console.log('data:', data);

    // get Text and add to data
    for (let idx in form.sampleTexts) {
      data[`sample${parseInt(idx, 10) + 1}`] = form.sampleTexts[idx].value;
    }

    console.log('data:', data);

    // @ts-ignore
    const resp = await createCampaign.mutateAsync({
      method: 'PUT',
      url: `/messaging/brands/${brand_id}/campaigns`,
      data,
    });

    console.log('campaign create resp:', resp);

    if (resp?.campaignId) {
      // success!
      alert('Succeeded creating campaign!');
    } else {
      // expecting an array of failed values
      try {
        let generalErrors: any[] = [];
        for (let item of resp) {
          switch (item.code) {
            case 501:
              if (item.field?.length) {
                console.log('setError:', item.field, item.description);
                // @ts-ignore
                setError(`info.${item.field}`, {
                  type: 'custom',
                  message: item.description,
                });
              } else {
                // "general" error?
                // - ie "terms and conditions must be accepted" ?
                // - add to "general" errors? (push to general errors? )
                generalErrors.push({ description: item.description });
              }
              break;

            case 509:
              if (item.field?.length) {
                console.log('setError:', item.field, item.description);
                // @ts-ignore
                setError(`info.${item.field}`, {
                  type: 'custom',
                  message: item.description,
                });
              } else {
                // "general" error?
                // - ie "terms and conditions must be accepted"
                generalErrors.push({ description: item.description });
              }
              break;

            default:
              console.error('Invalid item error code:', item);
              generalErrors.push({ description: item.description });
              break;
          }
        }
        if (generalErrors.length) {
          // @ts-ignore
          setError(`generalErrors`, { type: 'custom', arr: generalErrors });
        }
      } catch (err) {
        // unknown error
        console.error('Failed campaign Create validation:', err);
      }
    }
    // console.log('resp:', resp);
  };

  // console.log('campaignUsecases:', campaignUsecases);
  // useEffect(() => {
  //   // clearErrors();
  // }, []);

  return (
    <>
      <div
        className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
      >
        <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
          <SimpleBar className={'h-full'} style={{ height: '100%' }}>
            <div className={'px-8 py-3 h-full'}>
              <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
                <div className={'flex  items-center space-x-2'}>
                  <div
                    className={
                      'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
                    }
                  >
                    <PersonIcon
                      className={'fill-content-accent '}
                      sx={{ fontSize: 'inherit' }}
                    />
                  </div>
                  <div>
                    <div
                      className="font-bold text-xl"
                      onClick={handleSetDefaults}
                    >
                      Campaign Creation
                    </div>
                    {/* <div className="text-base text-neutral-500">
                      
                    </div> */}
                  </div>
                </div>
              </div>
              {/* @ts-ignore */}
              {errors?.generalErrors?.arr ? (
                <div className="p-4 my-4 rounded bg-red-100/20">
                  <div className="font-bold text-lg">Errors</div>
                  {/* @ts-ignore */}
                  {errors?.generalErrors?.arr?.map((err, i) => (
                    <div key={i} className="text-base">
                      {err.description}
                    </div>
                  ))}
                </div>
              ) : null}
              <FormProvider {...methods}>
                {watch('step') === 'usecase' ? (
                  <div className="p-3 flex flex-col mt-4 h-full">
                    <div className="mb-6 text-lg font-bold">
                      Campaign Use Case
                    </div>
                    {watch('info.usecase')?.length ? (
                      <Button
                        variant={'outline'}
                        color={'positive'}
                        className="w-max mb-4"
                        onClick={() => setValue('step', 'details')}
                      >
                        Next
                      </Button>
                    ) : null}
                    {campaignUsecases.data ? (
                      <div className="space-y-6 ">
                        <div className="mb-6 text-xl font-bold">Standard</div>
                        {USECASES_STANDARD.map(uc => (
                          <Usecase
                            key={uc.value}
                            usecase={uc}
                            brandDetails={campaignUsecases.data?.find(
                              cuc => cuc.usecase === uc.usecase,
                            )}
                          />
                        ))}
                        <div className="mb-6 !mt-10 text-xl font-bold">
                          Special
                        </div>
                        {USECASES_STANDARD.map(uc => (
                          <Usecase
                            key={uc.value}
                            usecase={uc}
                            brandDetails={campaignUsecases.data?.find(
                              cuc => cuc.usecase === uc.usecase,
                            )}
                          />
                        ))}
                        {/* {campaignUsecases.data
                        ?.filter(uc => USE_CASES.hasOwnProperty(uc.usecase))
                        .map((uc, i) => {
                          const Component = USE_CASES[uc.usecase];
                          return <Component key={i} data={uc} />;
                        })} */}
                      </div>
                    ) : campaignUsecases.isLoading ? (
                      <div className="">Loading campaign use-cases</div>
                    ) : (
                      <div className="">No campaign use-cases</div>
                    )}
                  </div>
                ) : null}
                {watch('step') === 'details' ? (
                  <Details
                    campaignUsecases={campaignUsecases}
                    onSubmit={onSubmit}
                    createCampaign={createCampaign}
                  />
                ) : null}
              </FormProvider>
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  );
};

const Details = ({ campaignUsecases, createCampaign, onSubmit }) => {
  const {
    handleSubmit,
    clearErrors,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const usecaseCode = watch('info.usecase');
  const campaignUsecase = campaignUsecases.data.find(
    uc => uc.usecase === usecaseCode,
  );
  // // console.log('campaignUsecase:', campaignUsecase);
  // console.log('usecase:', usecase);
  // console.log('watch', watch('sampleTexts'));
  return (
    <div className="">
      <div className="p-3 flex flex-col mt-4 h-full">
        <div className="mb-6 text-lg font-bold">Details</div>
        <div className="space-y-6 ">
          <TextField field="info.description" title="Campaign Description" />
          <TextField
            field="info.messageFlow"
            title="Call-to-Action / Message Flow"
          />
          <TextField field="info.optinMessage" title="Opt-in Message" />
          <TextField field="info.optoutMessage" title="Opt-out Message" />
          <TextField
            field="info.helpMessage"
            title="Help Message"
            hint="When somebody messages HELP"
          />
          <Texts campaignUsecase={campaignUsecase} />
          <Multimedia campaignUsecase={campaignUsecase} />
          <Radio
            field="info.embeddedLink"
            title="Messages may contain links??"
            options={YES_NO_TRUE_FALSE}
            hint="Cannot contain public shorteners like bit.ly!"
          />
          <Radio
            field="info.embeddedPhone"
            title="Messages may contain phone number?"
            options={YES_NO_TRUE_FALSE}
          />
          <Radio
            field="info.affiliateMarketing"
            title="Uses Affiliate Marketing?"
            options={YES_NO_TRUE_FALSE}
          />
          <Radio
            field="info.ageGated"
            title="Contains Age-Gated Content?"
            options={YES_NO_TRUE_FALSE}
          />
          <Radio
            field="info.directLending"
            title="Contains Direct Lending?"
            options={YES_NO_TRUE_FALSE}
          />
          <Radio
            field="info.subscriberOptin"
            title="Collecting and processing consumer opt-ins?"
            options={YES_NO_TRUE_FALSE}
          />
          <Radio
            field="info.subscriberOptout"
            title="Collecting and processing consumer opt-outs?"
            options={YES_NO_TRUE_FALSE}
          />
          <Radio
            field="info.subscriberHelp"
            title='Implemented "HELP"?'
            options={YES_NO_TRUE_FALSE}
            hint="Auto-reply message providing customers on how to contact sender after they reply with HELP"
          />
          <Radio
            field="info.numberPool"
            title="Campaign will apply to more than 50 numbers?"
            options={YES_NO_TRUE_FALSE}
            hint="You will need to contact us for a separate provisioning process for T-Mobile"
          />
          <Radio
            field="info.termsAndConditions"
            title="Accept the Terms and Conditions?"
            options={YES_NO_TRUE_FALSE}
            hint="Will not be used for Affiliate Marketing"
          />

          <div className="mt-8">
            <Button
              variant={'outline'}
              color={'positive'}
              onClick={() => {
                clearErrors();
                handleSubmit(onSubmit)();
              }}
            >
              {createCampaign.isLoading ? 'please wait...' : 'Create Campaign'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Texts = ({ campaignUsecase }) => {
  // const {
  //   control,
  // } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      // control,
      name: 'sampleTexts',
    },
  );

  // console.log('campaignUsecase:', campaignUsecase);
  const mnoIds = Object.keys(campaignUsecase.mnoMetadata);
  // console.log('campaignUsecase:', mnoIds, campaignUsecase);

  let minimum = 0;
  mnoIds.map(id => {
    const metadata = campaignUsecase.mnoMetadata[id];
    minimum =
      metadata.minMsgSamples > minimum ? metadata.minMsgSamples : minimum;
  });

  return (
    <>
      <div className="">
        <div className="text-base">SMS Sample Texts</div>
        <div className="text-sm">Min: {minimum} / Max: 5</div>
      </div>
      <div className="">
        {fields.map((field, index) => (
          // <input
          //   key={field.id} // important to include key with field's id
          //   {...register(`test.${index}.value`)}
          // />
          <div key={field.id} className="flex items-end space-x-1 mt-2">
            <TextField
              field={`sampleTexts.${index}.value`}
              errorField={`info.sample${index + 1}`}
              title={`Sample ${index + 1}:`}
            />
            <div>
              <Button
                variant={'outline'}
                size="sm"
                color={'negative'}
                className="w-max"
                disabled={fields.length === 1}
                onClick={() => remove(index)}
              >
                -
              </Button>
            </div>
          </div>
        ))}
        <div className="mt-2">
          <Button
            variant={'outline'}
            size="sm"
            color={'positive'}
            className="w-max"
            disabled={fields.length > 4}
            onClick={() => append({ id: Date.now(), value: '' })}
          >
            + Add Sample
          </Button>
        </div>
      </div>
    </>
  );
};

const Multimedia = ({ campaignUsecase }) => {
  const uploadAttachment = useCampaignAttachmentUpload();
  const deleteAttachment = useCampaignAttachmentDelete();

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      name: 'sampleFiles',
    },
  );

  const handleChangeFile = async (file, e) => {
    // TODO:
    // - validate mms info, otherwise this may fail on the upload!
    // - verify file size, file type

    // TODO: show "uploading" status
    // console.log('file change:', file);
    const value = await getBase64(file);
    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot + 1);
    // console.log('value:', ext, file, value);
    // // @ts-ignore
    // const result = await uploadAttachment.mutateAsync({
    //   // campaign_id,
    //   base64: value,
    //   extension: ext,
    // });
    // console.log('result:', result, file);
    // // console.log('file:', file);
    append({
      id: Date.now(),
      value: file,
      // result
    });
    e.target.value = null;
  };

  const handleDeleteAttachment = async (index, field) => {
    // delete after uploading
    // // @ts-ignore
    // const result = await deleteAttachment.mutateAsync({
    //   attachment_id: field.value.uuid,
    // });
    remove(index);
    // console.log('result:', result, file);
    // // console.log('file:', file);
    // append({ id: Date.now(), file, value: result });
  };

  const handleRemove = async index => {
    // TODO: remove from server too!
    remove(index);
  };

  // console.log('campaignUsecase:', campaignUsecase);
  const mnoIds = Object.keys(campaignUsecase.mnoMetadata);
  // console.log('campaignUsecase:', mnoIds, campaignUsecase);

  let minimum = 0;
  mnoIds.map(id => {
    const metadata = campaignUsecase.mnoMetadata[id];
    minimum =
      metadata.minMsgSamples > minimum ? metadata.minMsgSamples : minimum;
  });

  return (
    <>
      <div className="">
        <div className="text-base">MMS Samples</div>
        <div className="text-sm">Min: {minimum} / Max: 5</div>
      </div>
      <div className="">
        {fields.map((field, index) => (
          // <input
          //   key={field.id} // important to include key with field's id
          //   {...register(`test.${index}.value`)}
          // />
          <div key={field.id} className="flex items-end space-x-1 mt-2">
            {/* @ts-ignore */}
            {field.value?.name}
            <div>
              <Button
                variant={'outline'}
                size="sm"
                color={'negative'}
                className="w-max"
                // disabled={fields.length === 1}
                onClick={e => handleDeleteAttachment(index, field)}
              >
                -
              </Button>
            </div>
          </div>
        ))}
        <div className="mt-2">
          <>
            <input
              id="icon-button-file"
              type="file"
              // accept=".txt"
              style={{ display: 'none' }}
              // @ts-ignore
              onChange={e => handleChangeFile(e.target.files[0], e)}
            />
            <label htmlFor="icon-button-file" style={{ cursor: 'pointer' }}>
              <div>
                Upload
                {/* {isUploading ? (
                  <Box
                    sx={{
                      width: '72px',
                      height: '72px',
                      borderRadius: '50%',
                      border: '1px solid #ededed',
                      display: 'grid',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <CircularProgress size={28} />
                  </Box>
                ) : (
                  <AvatarCell resource={user} size={72} />
                )} */}
              </div>
            </label>
          </>
        </div>
      </div>
    </>
  );
};

// data = info from TCR regarding mno info for brand/usecase (costs, restrictions)
// - TODO: dont show cost info here, if different from TCR?

const Usecase = ({ usecase, brandDetails }) => {
  // console.log('usecase:', usecase, brandDetails);

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className="flex justify-between">
        <div className="">
          <label
            className="text-base cursor-pointer underline hover:text-blue-600"
            onClick={() => {}}
          >
            <input
              type="checkbox"
              value={usecase.usecase}
              onChange={e => {
                setValue('info.usecase', usecase.usecase);
                setValue('info.subUsecases', []);
              }}
              checked={watch('info.usecase') === usecase.usecase}
            />
            {usecase.generic.displayName}
          </label>
          <div className="text-xs">{usecase.generic.description}</div>
        </div>
      </div>
      <div className="">
        <MnoTable data={brandDetails} />
      </div>
      {watch('info.usecase') === usecase.usecase ? (
        <>
          {usecase.generic.minSubUsecases > 0 ? (
            <>
              <div className="pl-4 mt-2 text-base">
                Please select sub-use-cases:
                <br />
                (Min: {usecase.generic.minSubUsecases}, Max:{' '}
                {usecase.generic.maxSubUsecases})
              </div>
              <div className="pl-4">
                {USECASES_SUB.map(sub => (
                  <Subusecase key={sub.usecase} usecase={sub} />
                ))}
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const Subusecase = ({ usecase }) => {
  // console.log('usecase:', usecase, brandDetails);

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className="flex justify-between">
        <label
          className="text-sm cursor-pointer underline hover:text-blue-600"
          onClick={() => {}}
        >
          <input
            type="checkbox"
            value={usecase.usecase}
            onChange={e => {
              let newSubUsecases = getValues('info.subUsecases') || [];
              if (newSubUsecases.includes(usecase.usecase)) {
                newSubUsecases = newSubUsecases.filter(
                  v => v !== usecase.usecase,
                );
              } else {
                newSubUsecases.push(usecase.usecase);
              }
              setValue('subUsecases', newSubUsecases);
            }}
            checked={watch('info.subUsecases', []).includes(usecase.usecase)}
          />
          {usecase.generic.displayName}
        </label>
      </div>
    </div>
  );
};

const MnoTable = ({ data }) => {
  // console.log('data:', data);
  const mnoIds = Object.keys(data.mnoMetadata);
  return (
    <table className="table-auto border-spacing-x-1">
      <thead>
        <th></th>
        <th>Supported</th>
        <th>Qualified</th>
        <th>ReqReview</th>
        <th>OptIn</th>
        <th>Tier</th>
        <th>DailyCap</th>
      </thead>
      <tbody>
        {mnoIds.map(id => {
          const metadata = data.mnoMetadata[id];
          return (
            <tr className="text-sm">
              <td>{metadata.mno}</td>
              <td>
                <YesNo value={metadata.mnoSupport} />
              </td>
              <td>
                <YesNo value={metadata.qualify} />
              </td>
              <td>
                <AlertOrNot value={metadata.mnoReview} />
              </td>
              <td>
                <AlertOrNot value={metadata.reqSubscriberOptin} />
              </td>
              <td>{metadata.brandTier || 'n/a'}</td>
              <td>{metadata.brandDailyCap || 'n/a'}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const YesNo = ({ value }) => {
  return value ? (
    <DoneIcon className="text-green-500" style={{ width: 16, height: 16 }} />
  ) : (
    <NotInterestedIcon
      className="text-red-500"
      style={{ width: 16, height: 16 }}
    />
  );
};

const AlertOrNot = ({ value }) => {
  return value ? (
    <WarningAmberIcon
      className="text-orange-500"
      style={{ width: 16, height: 16 }}
    />
  ) : (
    <>n/a</>
  );
};

const Qualified = ({ data }) => {
  // console.log('data:', data);
  const mnoIds = Object.keys(data.mnoMetadata);
  return (
    <div className="grid">
      {mnoIds.map(id => {
        return (
          <div className="text-sm">
            {data.mnoMetadata[id].mno} -{' '}
            {data.mnoMetadata[id].qualify ? (
              <DoneIcon
                className="text-green-500"
                style={{ width: 16, height: 16 }}
              />
            ) : (
              <NotInterestedIcon
                className="text-red-500"
                style={{ width: 16, height: 16 }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
const ReviewRequired = ({ data }) => {
  // console.log('data:', data);
  const mnoIds = Object.keys(data.mnoMetadata);
  return (
    <div className="">
      {mnoIds.map(id => {
        return (
          <div className="text-sm">
            {data.mnoMetadata[id].mno} -{' '}
            {data.mnoMetadata[id].reviewRequired ? (
              <DoneIcon
                className="text-green-500"
                style={{ width: 16, height: 16 }}
              />
            ) : (
              <NotInterestedIcon
                className="text-red-500"
                style={{ width: 16, height: 16 }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

interface SelectProps {
  field: string;
  title: any;
  // hint: any;
  options?: any;
  placeholder?: any;
  disabled?: boolean;
}
const Select = ({
  field,
  title,
  placeholder,
  options,
  disabled = false,
}: SelectProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="">
      <div className={'text-sm font-bold'}>{title}</div>
      <select disabled={disabled} {...register(field)}>
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      {get(errors, field) && (
        <div className="text-red-500">{get(errors, field).message}</div>
      )}
    </div>
  );
};

interface TextFieldProps {
  field: string;
  errorField?: string;
  title: any;
  hint?: any;
  placeholder?: any;
  disabled?: boolean;
}
const TextField = ({
  field,
  errorField,
  title,
  hint = null,
  placeholder = '',
  disabled = false,
}: TextFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // console.log('errorField:', errorField);
  // console.log('ERRORS', field, { errors });
  return (
    <div className="">
      <div className={'text-sm font-bold'}>{title}</div>
      <input
        className={
          'rounded text-base border-solid border border-border-neutral py-1.5 px-3 font-sans'
        }
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        {...register(field)}
      />
      {hint !== null ? (
        <div className="text-sm text-neutral-600">{hint}</div>
      ) : null}
      {get(errors, errorField || field) && (
        <div className="text-red-500">
          {get(errors, errorField || field).message}
        </div>
      )}
    </div>
  );
};

interface CheckboxProps {
  field: string;
  title: any;
  hint?: any;
  disabled?: boolean;
}
const Checkbox = ({
  field,
  title,
  hint = null,
  disabled = false,
}: CheckboxProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="">
      <label>
        <input
          {...register(field)}
          className={
            'rounded text-base border-solid border border-border-neutral py-1.5 px-3 font-sans'
          }
          type="checkbox"
          disabled={disabled}
        />
        <div className={'text-sm font-bold'}>{title}</div>
      </label>
      {hint !== null ? (
        <div className="text-sm text-neutral-600">{hint}</div>
      ) : null}
      {get(errors, field) && (
        <div className="text-red-500">{get(errors, field).message}</div>
      )}
    </div>
  );
};

interface RadioProps {
  field: string;
  title: any;
  options?: any;
  hint?: any;
  disabled?: boolean;
}
const Radio = ({
  field,
  title,
  options = [],
  hint = null,
  disabled = false,
}: RadioProps) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="">
      <div className="">
        <div className={'text-base font-bold pb-1'}>{title}</div>

        <div className="">
          {options.map((opt, i) => (
            <Controller
              control={control}
              name={field}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                  <label key={i} className="mr-4 cursor-pointer">
                    <input
                      // {...register(field)}
                      className={
                        'rounded text-base border-solid border border-border-neutral pt-1.5 px-3 font-sans'
                      }
                      type="radio"
                      disabled={disabled}
                      // value={opt.value}
                      checked={value === opt.value}
                      onChange={() => onChange(opt.value)}
                    />
                    {opt.label}
                  </label>
                </>
              )}
            />
          ))}
        </div>
      </div>
      {hint !== null ? (
        <div className="pl-2 text-sm text-neutral-600">{hint}</div>
      ) : null}
      {get(errors, field) && (
        <div className="text-red-500">{get(errors, field).message}</div>
      )}
    </div>
  );
};

const getBase64 = async input => {
  return new Promise((resolve, reject) => {
    console.log('reading..');
    const reader = new FileReader();

    reader.onloadend = async function (evt) {
      console.log('reading3..');
      const file = evt.target?.result;
      resolve(file);
    };

    reader.onerror = reject;
    console.log('2..');
    reader.readAsDataURL(input);
  });
};

export default CampaignCreate;
