import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { Button } from 'app/design-lib';
import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import EmojiPicker, { Emoji } from 'emoji-picker-react';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { Popper } from 'app/design';
import { RichTooltip } from 'app/components/RichTooltip';

import {
  useTioMutation,
  usePipeUpdate,
  useMessagingSet,
} from 'app/pages/v2/common/sdk';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { PftDialog } from 'app/components/PftDialog';

function Messaging({ pipe }) {
  // shows the users for the user_ids of the pipe

  const numberQuery = usePhoneNumberQuery({ id: pipe.key });
  const number = numberQuery.data;

  const [showPft, showPftSet] = React.useState(false);
  const pipeUpdate = usePipeUpdate();
  const messagingSet = useTioMutation();

  const handleMessagingSet = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/messaging`,
      data: {
        enable: true,
        campaign_id: 'unchanged',
      },
    });
    alert(
      'Order submitted, please try receiving! You should not need to press this button again.',
    );
  };

  const handleMessagingSetOff = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/messaging`,
      data: {
        enable: false,
        // campaign_id: 'off',
      },
    });
    alert(
      'Order submitted, please try receiving! You should not need to press this button again.',
    );
  };

  const handleMessagingSetNoSending = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/messaging`,
      data: {
        enable: true,
        campaign_id: 'delete',
      },
    });
    alert('Order submitted.');
  };

  const handleSyncNumber = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/sync`, // could also sync ALL numbers?
      data: {},
    });
    // await numberQuery.refetch();
    // alert('Synced!');
  };

  const handleRefetchNumbers = async () => {
    await numberQuery.refetch();
    // alert('Synced!');
  };

  // console.log('numberQuery:', numberQuery);

  return (
    <div data-tour-settings-lines-view-voicemail>
      <div className="text-2xl mb-2">
        <div className="text-md font-medium">Messaging</div>
      </div>
      <PftDialog
        onComplete={() => showPftSet(false)}
        onCancel={() => showPftSet(false)}
        number={pipe.key}
        open={showPft}
      />
      <div className=" flex-wrap  flex">
        <Button
          color={'accent'}
          size={'sm'}
          variant={'outline'}
          className={'mt-2 mr-2'}
          onClick={() => showPftSet(true)}
          // startIcon={<PersonAddIcon />}
        >
          Join PFT
        </Button>

        <Button
          color={'accent'}
          size={'sm'}
          variant={'outline'}
          className={'mt-2 mr-2'}
          onClick={handleSyncNumber}
          // startIcon={<PersonAddIcon />}
        >
          Sync Number (to kazoo)
        </Button>

        <Button
          color={'accent'}
          size={'sm'}
          variant={'outline'}
          className={'mt-2 mr-2'}
          onClick={handleRefetchNumbers}
          // startIcon={<PersonAddIcon />}
        >
          Refetch Numbers (look in console)
        </Button>

        <Button
          color={'accent'}
          size={'sm'}
          variant={'outline'}
          className={'mt-2 mr-2'}
          onClick={handleMessagingSet}
          // startIcon={<PersonAddIcon />}
        >
          Enable Messaging
        </Button>

        <Button
          color={'accent'}
          size={'sm'}
          variant={'outline'}
          className={'mt-2 mr-2'}
          onClick={handleMessagingSetOff}
          // startIcon={<PersonAddIcon />}
        >
          Disable Messaging
        </Button>

        <Button
          color={'accent'}
          size={'sm'}
          variant={'outline'}
          className={'mt-2 mr-2'}
          onClick={handleMessagingSetNoSending}
          // startIcon={<PersonAddIcon />}
          disabled={!number?.doc?.trunkingio?.messaging_campaign_id?.length}
        >
          Remove Campaign
        </Button>
      </div>
    </div>
  );
}

export default Messaging;
