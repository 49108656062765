import { sdk } from 'app/sdk';
import React from 'react';
import constants from 'app/constants';
import { gql, request } from 'graphql-request';
import { store } from 'store';

const setupPipes = async ({
  auth_token,
  user,
  users = [],
  ptn,
  phoneNumbers,
  pipeNew,
}) => {
  // // create pipe for this user
  // // - add this user to the pipe
  let usersObj = {};
  if (user) {
    // @ts-ignore
    usersObj[user.id] = { enabled: true };
  }

  // in case onboarding failed in process, don't halt if pipe already exists
  const allowUniqueConstraintError = (e: any) => {
    if (!e?.response?.errors?.[0]?.message?.includes('Unique constraint'))
      throw e;
  };

  try {
    const pipe1 = await pipeNew.mutateAsync({
      name: 'Personal',
      ext: user?.doc?.presence_id,
      emoji: '1f4e8', // envelope incoming
      type: 'personal',
      users: usersObj,
    });
  } catch (e) {
    allowUniqueConstraintError(e);
  }

  try {
    if (ptn) {
      const pipe2 = await pipeNew.mutateAsync({
        name: 'Primary',
        emoji: '1f354', // hamburger
        ext: ptn,
        type: 'external', // personal/external?
        users: usersObj,
      });
    }
  } catch (e) {
    allowUniqueConstraintError(e);
  }

  // try {
  //   const room1 = await pipeNew.mutateAsync({
  //     name: 'general',
  //     emoji: '',
  //     ext: 'general',
  //     type: 'room',
  //     users: usersObj,
  //   });
  // } catch (e) {
  //   allowUniqueConstraintError(e);
  // }

  // create pipes for each number on account
  // - (keeping numbers when reset!)
  console.log('phoneNumbers:', phoneNumbers);
  for (const num of phoneNumbers || []) {
    if (num.id !== ptn) {
      console.log('PTN:', ptn);
      try {
        const pipe3 = await pipeNew.mutateAsync({
          name: 'Alternate',
          emoji: '1f384', // xmas tree
          ext: num.id,
          type: 'external',
          users: usersObj,
        });
      } catch (e) {
        allowUniqueConstraintError(e);
      }
    }
  }

  // create personal pipes for each user on account
  // - (keeping numbers when reset!)
  console.log('Personal Pipes:', users);
  for (const aUser of users) {
    // @ts-ignore
    if (aUser.id !== user.id) {
      try {
        const pipePersonal = await pipeNew.mutateAsync({
          name: 'Personal',
          emoji: '1f4e8', // envelope incoming
          // @ts-ignore
          ext: aUser.doc.presence_id,
          type: 'personal',
          users: {
            // @ts-ignore
            [aUser.id]: {
              enabled: true,
            },
          },
        });
      } catch (e) {
        allowUniqueConstraintError(e);
      }
    }
  }

  // Create example SMS message to Primary
  console.log('exampleIncomingSms');
  const other_number = '+16282035547'; // 15102969536
  if (ptn) {
    await exampleIncomingSms({
      to: ptn,
      from: other_number,
      text: 'Welcome to CallingIO! Wishing you the best while running your business!',
    });
  }

  // mark onboarding complete
  await completeOnboarding();
};

const exampleIncomingSms = async ({ to, from, text }) => {
  const data = {
    time: '2020-06-25T18:42:36.979Z',
    type: `message-received`,
    to: to, // the number on the account
    description: 'Message received',
    message: {
      id: Date.now().toString(), //message.message.id, //'1593110555875xo7watq5px6rbe5d',
      owner: to,
      time: '2020-06-25T18:42:35.876Z',
      segmentCount: 1,
      direction: 'in',
      to: [to],
      from: from,
      text: text,
      media: [], //'https://dev.bandwidth.com/images/bandwidth-logo.png'],
      // tag: 'v2 lab',
    },
  };

  const resp = await fetch(
    `${constants.env.REACT_APP_CIO_API_SERVER}/api/v1/webhooks/messaging`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // looks like messaging format changed after latest api?
      body: JSON.stringify({ data }),
    },
  );
  // const j = await resp.text();
  // debugger;

  // get the new message (should be the ONLY pipeMessage)
  // - TODO: add it as unresolved, and add an emoji, and some text to the thread
  const msgs = await pipeMessageList({});
  console.log('msgs:', msgs);
  if (msgs?.pipeMessages?.length > 0) {
    const msg = msgs.pipeMessages[0];

    // // add "unresolved" to message
    // await pipeMessageUpdateById({
    //   id: msg.id,
    //   metadata: {
    //     unresolved: true,
    //   },
    // });
  } else {
    console.error('Unexpectedly first pipeMessage did NOT exist', msgs);
  }
};

const pipeMessageList = async ({ take = 10, orderBy = [], filters = {} }) => {
  const queryResponse = await request(
    `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
    gql`
      query pipeMessages(
        $skip: Int
        $take: Int
        $orderBy: JSON
        $filters: JSON
        $mask: String
      ) {
        pipeMessages(
          skip: $skip
          take: $take
          orderBy: $orderBy
          filters: $filters
          mask: $mask
        ) {
          totalCount
          skip
          take
          pipeMessages {
            id
            account_id
            pipe_id
            key
            convo_key
            type
            data
            metadata
            items
            updatedAt
            createdAt
          }
        }
      }
    `,
    {
      skip: 0,
      take, // : 100,
      orderBy, //: [['createdAt', 'asc']],
      filters,
      mask: '',
    },
    // @ts-ignore
    { authorization: store.getState().auth.auth_token },
  );
  return queryResponse.pipeMessages;
};

const completeOnboarding = async () => {
  const mutationResponse = await request(
    `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
    gql`
      mutation onboardingComplete($data: JSON) {
        onboardingComplete(data: $data) {
          success
          message
          data
        }
      }
    `,
    { data: {} },
    // @ts-ignore
    { authorization: store.getState().auth.auth_token },
  );
};

const pipeMessageUpdateById = async data => {
  const mutationResponse = await request(
    `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
    gql`
      mutation pipeMessageUpdateById($data: JSON) {
        pipeMessageUpdateById(data: $data) {
          success
          message
          data
        }
      }
    `,
    {
      data,
    },
    // @ts-ignore
    { authorization: store.getState().auth.auth_token },
  );
};

export default setupPipes;
