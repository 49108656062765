import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import {
  Alert,
  Button,
  Listbox,
  SquareProgress,
} from '../../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath, useToggleReducer } from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import {
  useListVmboxesQuery,
  useVmboxQuery,
} from '../../../../../../hooks/queries/vmbox';
import { useUpdateVmboxPartial } from '../../../../../../hooks/mutations/vmbox';
import { useHoverDirty } from 'react-use';
import { AudioPlayer } from '../../../../../../components/AudioPlayer';
import { WarningTriangle } from 'iconoir-react';
import { GreetingVmbox } from '../../../../../admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { EditLineGreeting } from '../../../PipeView/components/Voicemail/Voicemail';
import { SettingsPanelLoader } from '../../../../../../design-lib/components/SettingsPanelLoader.tsx';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import { PreviousModuleButton } from './PreviousModuleButton';
import { CallTerminatesButton } from './CallTerminatesButton';

export const HangupModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  return (
    <>
      <MenuButton
        type={'termination'}
        current={'Hangup'}
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          'replace',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <div
        className={
          'flex space-x-4 border-t pt-4 border-neutral-20 justify-between'
        }
      >
        <PreviousModuleButton />
        <CallTerminatesButton />
      </div>
    </>
  );
};
