import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import {
  Alert,
  Button,
  Listbox,
  SquareProgress,
} from '../../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath, useToggleReducer } from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import {
  useListVmboxesQuery,
  useVmboxQuery,
} from '../../../../../../hooks/queries/vmbox';
import { useUpdateVmboxPartial } from '../../../../../../hooks/mutations/vmbox';
import { useHoverDirty } from 'react-use';
import { AudioPlayer } from '../../../../../../components/AudioPlayer';
import { WarningTriangle } from 'iconoir-react';
import { GreetingVmbox } from '../../../../../admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { EditLineGreeting } from '../../../PipeView/components/Voicemail/Voicemail';
import { SettingsPanelLoader } from '../../../../../../design-lib/components/SettingsPanelLoader.tsx';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import AudioEditor from '../../../../../../components/AudioEditor/AudioEditor';
import { NextModuleButton } from './NextModuleButton';

export const PlayAudioModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;
  const mediaId = moduleItem?.data?.id;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);
  const [edit, toggleEdit] = useToggleReducer();

  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${modifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  // if (pipeListQuery.isLoading || vmboxesLoading)
  //   return (
  //     <div className={`w-full h-full grid place-items-center`}>
  //       <div className={`text-center`}>
  //         <SquareProgress />
  //         <span>Loading...</span>
  //       </div>
  //     </div>
  //   );

  return (
    <>
      <div className={`flex my-4 items-center justify-between`}>
        <label className={' text-md font-medium text-content'}>Audio</label>
        <Button
          variant={'ghost'}
          color={'accent'}
          size={'md'}
          onClick={toggleEdit}
        >
          {mediaId ? 'Change Media' : 'Add Media'}
        </Button>
      </div>
      {edit ? (
        <AudioEditor
          hideCancel
          mediaName={`play_audio_${Date.now()}`}
          mediaId={mediaId}
          onComplete={async mediaId => {
            if (invalidModules.includes(moduleItem.id)) {
              setInvalidModules(prev =>
                prev.filter(id => id !== moduleItem.id),
              );
            }
            setAtPath(callflow, `${modifyPath}.data.id`, mediaId);
            setCallflow({ ...callflow });
            toggleEdit();
          }}
          subType={'play-audio'}
          onClose={toggleEdit}
          hasPlatformDefault={false}
        />
      ) : mediaId ? (
        <AudioPlayer mediaId={mediaId} />
      ) : (
        <Alert
          reverse
          icon={<WarningTriangle fr={undefined} />}
          color={'attention'}
          label={'No Audio Selected'}
          body={
            <div className={`flex flex-col space-y-2 pr-2`}>
              <span>Select media to play for this module.</span>
            </div>
          }
          // actionProps={{
          //   onClick: toggleEdit,
          //   children: 'Customize greeting',
          // }}
        />
      )}

      <MenuButton
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <NextModuleButton />
    </>
  );
};
