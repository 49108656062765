import { useBuilderContext } from '../../../../../components/IvrBuilder/IvrBuilder';
import { useCallRouteEditor } from './CallflowContext';
import { getElementProps } from './utils/getElementProps';
import { Button, IconButton } from '../../../../../design-lib';
import React from 'react';
import { AddModuleButton } from './AddModuleButton';
import { SidebarSeparator } from './SidebarSeparator';

export const ModuleButton = ({ module, expand }) => {
  const { selectedElementKey, setSelectedElementKey, invalidModules } =
    useBuilderContext();
  const { selectedElement, elements } = useCallRouteEditor();
  const element = elements.find(
    elem => elem.data?.moduleItem?.id === module.id,
  );
  const connectingElement = elements.find(elem => elem?.source === element?.id);
  const nextElement = elements.find(
    elem => elem?.id === connectingElement?.target,
  );

  const onClick = () => {
    setSelectedElementKey(element?.key ?? '');
  };

  // console.log('select', selectedElement);
  const moduleItemId = selectedElement?.data?.moduleItem?.id;
  const { icon, name, nodeType } = getElementProps(element);
  const isInvalid = invalidModules.includes(module.id);

  const isActive = moduleItemId === module.id;

  return (
    <>
      <SidebarButton
        expand={expand}
        icon={icon}
        name={name}
        onClick={onClick}
        isActive={isActive}
        isInvalid={isInvalid}
      />
      {!nextElement ? null : expand ? (
        <>
          {nextElement?.type === 'InsertNode' ? (
            <>
              <SidebarSeparator />
              <AddModuleButton expanded data={nextElement.data} />
            </>
          ) : null}
        </>
      ) : (
        <>
          {nextElement?.type === 'InsertNode' ? (
            <>
              <SidebarSeparator />
              <AddModuleButton data={nextElement.data} />
            </>
          ) : null}
        </>
      )}
    </>
  );

  // return expand ? (
  //   <>
  //     <Button
  //       startIcon={icon}
  //       onClick={onClick}
  //       size={'md'}
  //       variant={isActive ? 'fill' : 'ghost'}
  //       color={isInvalid ? 'negative' : isActive ? 'accent' : 'neutral'}
  //       className={`w-full !justify-start`}
  //     >
  //       {name}
  //     </Button>
  //     {module}
  //     {nextElement?.type === 'InsertNode' ? (
  //       <AddModuleButton expanded data={nextElement.data} />
  //     ) : null}
  //   </>
  // ) : (
  //   <>
  //     <IconButton
  //       onClick={onClick}
  //       size={'md'}
  //       variant={isActive ? 'fill' : 'ghost'}
  //       color={isInvalid ? 'negative' : isActive ? 'accent' : 'neutral'}
  //     >
  //       {icon}
  //     </IconButton>
  //     {nextElement?.type === 'InsertNode' ? (
  //       <AddModuleButton data={nextElement.data} />
  //     ) : null}
  //   </>
  // );
};

export const SidebarButton = ({
  expand,
  isActive,
  isInvalid = false,
  onClick,
  name,
  icon,
}) => {
  return expand ? (
    <>
      <SidebarSeparator />

      <Button
        startIcon={icon}
        onClick={onClick}
        size={'md'}
        variant={isActive ? 'fill' : 'outline'}
        color={isInvalid ? 'negative' : isActive ? 'accent' : 'neutral'}
        className={`w-full `}
      >
        <div className={`max-w-[7rem]`}>
          <p className={`truncate`}>{name}</p>
        </div>
      </Button>
      {/*{module}*/}
    </>
  ) : (
    <>
      <SidebarSeparator />

      <IconButton
        onClick={onClick}
        size={'md'}
        variant={isActive ? 'fill' : 'outline'}
        color={isInvalid ? 'negative' : isActive ? 'accent' : 'neutral'}
      >
        {icon}
      </IconButton>
      {/*{nextElement?.type === 'InsertNode' ? (*/}
      {/*  <AddModuleButton data={nextElement.data} />*/}
      {/*) : null}*/}
    </>
  );
};
