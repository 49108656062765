import { UserAvatar } from '../../../../../../components/Sidebar2/Sidebar2';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../../components/PhoneNumberDisplay';
import {
  Alert,
  Button,
  Listbox,
  SquareProgress,
  TextField,
} from '../../../../../../design-lib';
import React, { useEffect, useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath, useToggleReducer } from '../../../../../../utilities';
import { usePipesQuery } from '../../../../common/sdk';
import useEnsureModuleItemId from '../../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import {
  useListVmboxesQuery,
  useVmboxQuery,
} from '../../../../../../hooks/queries/vmbox';
import { useUpdateVmboxPartial } from '../../../../../../hooks/mutations/vmbox';
import { useDebounce, useHoverDirty } from 'react-use';
import { AudioPlayer } from '../../../../../../components/AudioPlayer';
import { WarningTriangle } from 'iconoir-react';
import { GreetingVmbox } from '../../../../../admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { EditLineGreeting } from '../../../PipeView/components/Voicemail/Voicemail';
import { SettingsPanelLoader } from '../../../../../../design-lib/components/SettingsPanelLoader.tsx';
import { MenuButton } from '../../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import AudioEditor from '../../../../../../components/AudioEditor/AudioEditor';
import { NextModuleButton } from './NextModuleButton';

export const SendMessageModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;
  const mediaId = moduleItem?.data?.id;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);
  const [edit, toggleEdit] = useToggleReducer();

  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${modifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  const handleChangeText = tmpText => {};
  const [text, setText] = useState(moduleItem?.data?.text ?? '');
  const [debouncedInput, setDebouncedInput] = useState(text);
  useDebounce(() => setDebouncedInput(text), 250, [text]);

  useEffect(() => {
    if (debouncedInput === moduleItem?.data?.text) return;

    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }

    setAtPath(callflow, `${modifyPath}.data.text`, debouncedInput.trim());

    setCallflow({ ...callflow });
    // setShowModifyDialogWrap(null);
  }, [debouncedInput]);

  return (
    <>
      <TextField
        label={'Message Content'}
        value={text}
        multiline
        onChange={e => setText(e.target.value)}
      />

      <MenuButton
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <NextModuleButton />
    </>
  );
};
