import { useTour } from '@reactour/tour';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { ScheduleWidget } from 'app/components/ScheduleWidget';
import { DoNotDisturbSettings } from 'app/components/Sidebar2/components/DoNotDisturbSettings';
import { Loading } from 'app/components/Sidebar2/components/Loading';
import { RouteMatchComponent } from 'app/components/Sidebar2/Sidebar2';
import { SquareProgress, Switch, Tooltip } from 'app/design-lib';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import {
  usePipeConvosQuery,
  usePipeConvoUsersQuery,
  usePipeMessagesQuery,
  usePipesQuery,
} from 'app/pages/v2/common/sdk';
import { usePubNubSub } from 'app/utilities/pubnub';
import { Emoji } from 'emoji-picker-react';
import { sum } from 'lodash';
import * as React from 'react';
import {
  Settings,
  Plus,
  Cart,
  AddHexagon,
  SoundHigh,
  SoundOff,
} from 'iconoir-react';
import SimpleBar from 'simplebar-react';
import { store } from 'store';
import { User } from 'types/user';
import { Link } from 'react-router-dom';
import { ButtonDropdown } from '../../../../design-lib/components/ButtonDropdown';
import { LinesHeader } from 'app/components/Sidebar2/components/LinesDisplay/components/LinesHeader';
interface LinesDisplayProps {}

const LinesDisplay = ({}: LinesDisplayProps) => {
  return (
    <div
      className={
        'flex-[3] text-md w-full flex flex-col justify-between items-center'
      }
    >
      <div className={'flex-grow w-full overflow-visible'}>
        <LinesHeader />
        <Lines />
      </div>
    </div>
  );
};

const Lines = () => {
  const { data: accountData } = useAccountQuery();
  // const { data: authenticatedUser } = useAuthenticatedUserQuery();

  const schedulesQuery = useListSchedulesQuery({
    skip: 0, //skip
    take: 100, // take
    orderBy: undefined, // order by
    filters: {}, // filters
  });
  const { callflows: schedules } = schedulesQuery.data ?? {};

  const pipesQuery = usePipesQuery({
    filters: {
      users: {
        path: [store.getState().auth.owner_id, 'enabled'],
        equals: true,
      },
      NOT: {
        type: 'room',
      },
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];

  if (pipesQuery.isLoading)
    return (
      <div className={`w-full mt-4 h-full grid place-items-center`}>
        <SquareProgress size={'sm'} />
      </div>
    );

  if (!pipes?.length) {
    return (
      <div className={'h-full grid place-items-center'}>
        <span className={'font-medium opacity-50'}>No Lines</span>
      </div>
    );
  }

  // console.log('authenticatedUser?.doc.id:', authenticatedUser?.doc.id);
  return (
    <div className={'h-full w-full'} data-tour-sidebar-lines>
      {/* <div className="font-bold text-md mt-4 mb-1 pl-2">
        <div className={'flex space-x-1 items-center '}>
          <NumbersIcon
            className={'fill-content-accent -mt-0.5'}
            sx={{ fontSize: 'inherit' }}
          />
          <span>Lines</span>
        </div>
      </div> */}

      {/* {pipes
          .filter(pipe => pipe.type === 'personal')
          .map(pipe => (
            <PersonalPipe
              key={pipe.id}
              pipe={pipe}
              // @ts-ignore
              schedule={schedules?.find(s => s.doc?.pipe_id === pipe.id)}
            />
          ))} */}
      <div>
        {pipes
          .filter(pipe => pipe.type === 'external')
          .map(pipe => (
            <Line
              key={pipe.id}
              pipe={pipe}
              // @ts-ignore
              schedule={schedules?.find(s => s.doc?.pipe_id === pipe.id)}
            />
          ))}
        {pipes
          .filter(pipe => pipe.type === 'shared')
          .map(pipe => (
            <Line
              key={pipe.id}
              pipe={pipe}
              // @ts-ignore
              schedule={schedules?.find(s => s.doc?.pipe_id === pipe.id)}
            />
          ))}
      </div>
    </div>
  );
};

const Line = ({ pipe, schedule }) => {
  const pipe_key = pipe.key;
  const pipeConvosQuery = usePipeConvosQuery({
    filters: {
      pipe_id: pipe.id,
    },
    // key: pipe_key,
  });
  const pipeConvos = pipeConvosQuery.data?.pipeConvos ?? [];

  const pipeConvoUsersQuery = usePipeConvoUsersQuery({
    pipe_id: pipe.id,
    user_id: store.getState().auth.owner_id,
  });
  const pipeConvoUsers = pipeConvoUsersQuery.data?.pipeConvoUsers ?? [];

  const pipeMessagesQuery = usePipeMessagesQuery({
    filters: {
      pipe_id: pipe.id,
      // convo_key: pc.key,
      AND: [
        { metadata: { path: ['unresolved'], equals: true } },
        // { NOT: { type: 'internal' } },
        // { NOT: { type: 'internal-activity' } },
      ].filter(v => !!v),
    },
  });
  const pipeMessagesUnresolved = (pipeMessagesQuery.data?.pipeMessages ?? [])
    .length;

  const handleMessage = event => {
    const message = event.message;
    switch (message?.type) {
      case 'added':
      case 'convo:added':
      case 'convo_updated':
        // console.log('pubnub sidebar event triggered2', pipe.id, message.type);
        pipeConvosQuery.refetch();
        pipeConvoUsersQuery.refetch();
        pipeMessagesQuery.refetch();
        break;
      case 'message:updated':
        // pipeMessage updated in existing list
        // - don't care about this here
        break;
    }
  };

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour();

  usePubNubSub(handleMessage, pipe ? [`pipe:${pipe.id}`] : []); // UNCOMMENT!!

  const unread_convos = sum(pipeConvos.map(pc => (pc.metadata.unread ? 1 : 0)));
  const unread_internal = sum(
    pipeConvoUsers.map(pc => (pc.metadata.unread_internal ? 1 : 0)),
  );

  return (
    <RouteMatchComponent to={`/pipe/${pipe.id}`}>
      {({ active, nav, href }) => (
        <div
          className={`h-14 cursor-pointer justify-between flex py-2 px-4 w-full hover:bg-gray-10 ${
            active ? 'bg-gray-20' : null
          }`}
          {...(pipe.type === 'personal'
            ? { 'data-tour-sidebar-line-personal': true }
            : pipe.type === 'external'
            ? { 'data-tour-sidebar-line-external': true }
            : {})}
          onClick={e => {
            nav(`${href}?auto_focus=1`);
            // nextStep();
            if (isOpen) {
              setCurrentStep(v => v + 1);
            }
          }}
        >
          <LineDisplay pipe={pipe} />
          {/*<div className={'flex space-x-2'}>
            <Emoji unified={pipe.metadata?.emoji || '1f937'} size={20} />
            <div className={'flex flex-col space-y-0.5'}>
              <span className={'text-md font-medium'}>
                {pipe.metadata?.name}
              </span>
              <span className={'text-sm'}>
                <PhoneNumberDisplay ptn={pipe.key} hideFlag />
              </span>
            </div>
          </div>*/}
          <div className="space-y-1 flex h-full  justify-center flex-col">
            {/* {pipeMessagesUnresolved ? (
              <span className="bg-background-attention-secondary-hover text-content-attention text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                {pipeMessagesUnresolved}
              </span>
            ) : null}*/}
            {unread_convos ? (
              <UnreadBadge
                color={'green'}
                isActive={active}
                count={unread_convos}
              />
            ) : null}
            {/*{unread_internal ? (*/}
            {/*  <UnreadBadge color={'green'} count={unread_internal} />*/}
            {/*) : null}*/}
            {/*{unread_internal ? (
              <span className="bg-purple-100 text-purple-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                {unread_internal}
              </span>
            ) : null}*/}
          </div>
        </div>
      )}
    </RouteMatchComponent>
  );

  // return (
  //   // @ts-ignore
  //   <RouteMatchComponent to={`/v2/pipe/${pipe.id}`}>
  //     {({ active, nav, href }) => (
  //       <div
  //         className={`p-1 mb-1 rounded hover:bg-background-accent-secondary-hover/30 ${
  //           active ? 'bg-background-accent-secondary-active/30' : ''
  //         }`}
  //         {...(pipe.type === 'personal'
  //           ? { 'data-tour-sidebar-line-personal': true }
  //           : pipe.type === 'external'
  //           ? { 'data-tour-sidebar-line-external': true }
  //           : {})}
  //       >
  //         <div
  //           className="p-1 flex space-x-1 items-center cursor-pointer"
  //           onClick={e => {
  //             nav();
  //             // nextStep();
  //             if (isOpen) {
  //               setCurrentStep(v => v + 1);
  //             }
  //           }}
  //         >
  //           <div className="px-1">
  //             <Emoji unified={pipe.metadata?.emoji || '1f937'} size={25} />
  //           </div>
  //           <div className="flex-auto min-w-0 pr-1">
  //             <div className="truncate">
  //               <span className="font-bold">{pipe.metadata?.name}</span>
  //             </div>
  //             <div>
  //               <PhoneNumberDisplay ptn={pipe.key} />
  //             </div>
  //             {schedule ? (
  //               <div>
  //                 <ScheduleWidget callflow={schedule.doc} />
  //               </div>
  //             ) : null}
  //           </div>
  //           <div className="space-y-1 flex flex-col">
  //             {pipeMessagesUnresolved ? (
  //               <span className="bg-background-attention-secondary-hover text-content-attention text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
  //                 {pipeMessagesUnresolved}
  //               </span>
  //             ) : null}
  //             {unread_convos ? (
  //               <span className="bg-background-accent-secondary-hover text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
  //                 {unread_convos}
  //               </span>
  //             ) : null}
  //             {unread_internal ? (
  //               <span className="bg-purple-100 text-purple-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
  //                 {unread_internal}
  //               </span>
  //             ) : null}
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </RouteMatchComponent>
  // );
};

export const LineDisplay = ({ pipe }) => {
  return (
    <div className={'flex space-x-2'}>
      <Emoji unified={pipe.metadata?.emoji || '1f937'} size={20} />
      <div className={'flex flex-col space-y-0.5'}>
        <span className={'text-md font-medium'}>
          {pipe.type === 'personal'
            ? 'Direct Messages'
            : pipe.metadata.name?.length
            ? pipe.metadata.name
            : 'Unnamed'}
        </span>
        <span className={'text-sm'}>
          <PhoneNumberDisplay ptn={pipe.key} hideFlag />
        </span>
      </div>
    </div>
  );
};

export const UnreadBadge = ({
  count,
  color = 'green',
  word = 'conversation',
  isActive,
}) => {
  return (
    <Tooltip
      content={
        <span className={`whitespace-nowrap`}>
          {count} unread {word}
          {count > 1 ? 's' : ''}
        </span>
      }
    >
      <div
        className={`p-[1px] grid place-items-center aspect-square w-5 h-5 rounded-full bg-${color}-${
          isActive ? '80' : '60'
        } text-white text-sm font-medium`}
      >
        <span>{count}</span>
      </div>
    </Tooltip>
  );
};

export default LinesDisplay;
